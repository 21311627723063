import React from 'react';

interface Props {
    numberOfSeats: number;
}

export const Explanation: React.FC<Props> = (props: Props) => {
    return (
        <>
            <p>
                {props.numberOfSeats === 1 && (
                    <>
                        Klicke für die Reservation einfach auf einen freien Stuhl &nbsp;
                        <i className="fa fa-chair seat free" />
                        &nbsp;.
                        <br />
                        {/* Wegen unseres Covid-19 Sicherheitskonzeptes kannst du nur die gelb gefärbten Stühle wählen. */}
                        Sobald du den gewünschten Sitz ausgewählt hast, klicke auf den Button &quot;Weiter&quot;.
                        <br />
                    </>
                )}
                {props.numberOfSeats > 1 && (
                    <>
                        Klicke für die Reservation einfach auf {props.numberOfSeats} Stühle &nbsp;
                        <i className="fa fa-chair seat free" />
                        &nbsp;.
                        <br />
                        {/* Wegen unseres Covid-19 Sicherheitskonzeptes kannst du nur die gelb gefärbten Stühle und nur alle <span style={{fontWeight: "bold"}}>{props.numberOfSeats} Sitze</span>  nebeneinander wählen. */}
                        Sobald du die gewünschten{' '}
                        <span style={{ fontWeight: 'bold' }}>{props.numberOfSeats} Sitze</span> ausgewählt hast, klicke
                        auf den Button &quot;Weiter&quot;.
                        <br />
                    </>
                )}
            </p>
            <p>
                <b>Legende:</b>
                <br />
                <i className="fa fa-chair seat free" />
                &nbsp; freier Sitzplatz
                <br />
                <i className="fa fa-chair seat blocked" />
                &nbsp; blockierter Sitzplatz
                <br />
                <i className="fa fa-chair seat desired" />
                &nbsp; von dir zur Reservation ausgewählt
                <br />
                <i className="fa fa-chair seat reserved" />
                &nbsp; bereits von anderen reserviert
                <br />
            </p>
            <hr />
        </>
    );
};
