import { correctPic } from '../../helpers/correct-pic';

export const Picture = (props: { url: string; title?: string; comment?: string }) => (
    <>
        {props?.url && (
            <div style={{ marginBottom: 20, marginTop: 20 }}>
                <a href={props.url} rel="prettyPhoto" title={props.title}>
                    <img
                        className="image_border"
                        src={correctPic(props.url)}
                        style={{ width: 670 }}
                        alt={props.title}
                    />
                </a>
                {props.comment && <div>{props.comment}</div>}
            </div>
        )}
    </>
);
