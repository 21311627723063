import React, { useState } from 'react';
import { FormControl, InputLabel, Select, Button, Alert, SelectChangeEvent } from '@mui/material';
import { IConcertDto, IConcertSeriesDto } from '../../../clients/services';
import { DetailedConcertInfo } from '../../../components/ConcertPreview/DetailedConcertInfo';
import { actionContainerStyle, formControlStyle } from '../styles';
import { Link } from 'react-router-dom';

type Props = {
    numberOfSeats: number;
    maxNrOfSeats: number;
    possibleNrOfSeats: number;
    hasRestrictions?: boolean;
    concert: IConcertDto;
    concertSeries?: IConcertSeriesDto;
    onChange: (numberOfSeats: number) => void;
    continue: (numberOfSeats: number) => void;
};

export const SelectNumberOfSeats: React.FC<Props> = (props) => {
    const [seatsChanged, setSeatsChanged] = useState<boolean>(false);

    const nrOfSeats = props.maxNrOfSeats > props.possibleNrOfSeats ? props.possibleNrOfSeats : props.maxNrOfSeats;

    const renderNrOfSeats = () => {
        const reservationOptions = [];
        reservationOptions.push(
            <option value="0" key={0}>
                Klicke hier um die gewünschte Anzahl Sitze anzugeben...
            </option>,
        );
        for (let i = 1; i <= nrOfSeats; i++) {
            reservationOptions.push(
                <option value={i} key={i}>
                    {i}
                </option>,
            );
        }
        return <>{reservationOptions}</>;
    };

    const onChange = (event: SelectChangeEvent<number>) => {
        const numberOfSeats = parseInt(event.target.value as string);
        setSeatsChanged(true);
        props.onChange(numberOfSeats);
    };

    if (nrOfSeats === 0) {
        return (
            <>
                <DetailedConcertInfo concert={props.concert} concertSeries={props.concertSeries}></DetailedConcertInfo>
                <Alert severity="error" style={{ marginTop: '20px' }}>
                    Es können leider keine Tickets mehr gebucht werden. Das Konzert ist bereits ausverkauft.
                </Alert>
            </>
        );
    }

    return (
        <>
            <DetailedConcertInfo concert={props.concert} concertSeries={props.concertSeries}></DetailedConcertInfo>
            <>
                <div style={actionContainerStyle}>
                    <FormControl style={formControlStyle} fullWidth>
                        <InputLabel id="nrOfSeats-selection">Anzahl gewünschte Sitzplätze</InputLabel>
                        <Select
                            native
                            value={props.numberOfSeats}
                            onChange={onChange}
                            labelId="nrOfSeats-selection"
                            id="nrOfSeats-native-simple"
                            label="Anzahl gewünschte Sitzplätze"
                            fullWidth
                        >
                            <option aria-label="Anzahl Sitze" value="" />
                            {renderNrOfSeats()}
                        </Select>
                    </FormControl>
                    <div style={{ margin: '10px' }}>
                        <div style={{ display: 'inline' }}>
                            <Link to="/tickets">
                                <Button variant="contained" color="inherit" style={{ width: '200px' }}>
                                    Zurück
                                </Button>
                            </Link>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => props.continue(props.numberOfSeats)}
                                style={{ marginLeft: '20px', width: '200px' }}
                                disabled={props.numberOfSeats < 1}
                            >
                                Weiter
                            </Button>
                            {seatsChanged && props.numberOfSeats < 1 && (
                                <Alert severity="error" style={{ marginTop: '20px' }}>
                                    Bitte wähle über die obenstehende Selectbox die gewünschte Anzahl Sitze aus!
                                </Alert>
                            )}
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};
