export const Archive2014 = () => {
    return (
        <>
            {' '}
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_kb_01.jpg"
                            alt="Krueger Brothers"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2013_kb_02.png"
                            alt="Krueger Brothers"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Krüger Brothers
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>KR&Uuml;GER BROTHERS</h2>
                    <div className="frame_1">
                        <p>
                            <strong>
                                <span className="color1">DATUM:</span> Sonntag, 2. November &amp; Montag, 3. November
                                2014
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> jeweils 19.30 Uhr (T&uuml;r&ouml;ffnung
                                18.30 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 52.-
                            </strong>
                            <br />
                            <br />
                        </p>
                        <p>
                            <a href="http://www.krugerbrothers.com" target="_blank" rel="noreferrer">
                                www.krugerbrothers.com
                            </a>
                        </p>
                    </div>
                    <h3>Einfach genial!</h3>
                    <p>
                        Die in der Schweiz geborenen und seit vielen Jahren in den USA lebenden Jens und{' '}
                        <strong>Uwe Kr&uuml;ger</strong> am Banjo und an der Gitarre entwickeln seit Jahren zusammen mit
                        ihrem Bassisten <strong>Joel Landsberg</strong> die traditionelle Folk und Bluegrass Musik
                        Amerikas in eine eindr&uuml;ckliche neue Richtung.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Im traditionellen Sinne spielen sie mit unglaublichem und atemberaubendem Tempo. In ihren
                        aktuellen Kompositionen verschmelzen die <strong>Kr&uuml;ger Brothers</strong> einerseits
                        Virtuosit&auml;t, Originalit&auml;t und Wertsch&auml;tzung f&uuml;r die Sch&ouml;nheit der Musik
                        und Kultur der Appalachen, und andererseits bezeugen sie auch ihre Liebe zur klassischen Musik
                        Europas. Jeder einzelne ihrer Songs ist ein Meisterwerk. Umh&uuml;llt von ihrer genialen
                        Musikalit&auml;t, ihrem brillianten Zusammenspiel und vom Gesang mit den klaren ausdrucksstarken
                        Stimmen sind sie in ihrer Form einzigartig.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die <strong>Kr&uuml;ger Brothers</strong> werden uns mit ihrer unb&auml;ndigen Spielfreude,
                        ihrer beruhigenden Genialit&auml;t und ihrer spontanen Art wiederum begeistern ! Lasst Euch das
                        nicht entgehen !{' '}
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Intense and tranquil, classic, classy and classical, jazzy and grassy &ndash; all at the
                            same time. The Kruger Brothers are just about as fine a band as I&rsquo;ve ever played with.
                            <span className="color3 quelle">
                                <br />
                                &ndash; Doc Watson
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            I used to think the banjo was somewhat limited to certain styles, until I heard Jens Kruger.
                            Jens has played some of the most beautiful and expressive banjo I&rsquo;ve ever heard..
                            <span className="color3 quelle">
                                <br />
                                &ndash; Ron Block of Alison Krauss and Union Station
                            </span>
                        </p>
                    </blockquote>

                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_cp-je_01.jpg"
                            alt="CP-JE"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_cp-je_02.jpg"
                            alt="CP-JE"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2014_cp-je_03.png"
                            alt="CP-JE"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Courtney Patton &amp; <br />
                        Jason Eady
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>COURTNEY PATTON &amp; JASON EADY</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 19. Oktober 2014
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19.30 Uhr (T&uuml;r&ouml;ffnung 18.30
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.courtneypatton.com" target="_blank" rel="noreferrer">
                                www.courtneypatton.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.jasoneady.com" target="_blank" rel="noreferrer">
                                www.jasoneady.com/
                            </a>
                        </p>
                        <p>
                            facebook:{' '}
                            <a
                                href="https://www.facebook.com/pages/Courtney-Patton/13622975750?ref=hl"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Courtney Patton
                            </a>
                        </p>
                        <p>
                            facebook:{' '}
                            <a href="https://www.facebook.com/jasoneadymusic" target="_blank" rel="noreferrer">
                                Jason Eady
                            </a>
                        </p>
                    </div>
                    <h3>Courtney Patton die starke Frau an Jason Eady&apos;s Seite</h3>
                    <p>
                        Auch wenn der Name <strong>Courtney Patton</strong> noch nicht zu bekanntesten der Texas Music
                        Scene gehört, könnte sich dies relativ rasch ändern. Für Insider zählt sie zu den besten
                        unabhängigen Künstlerinnen in Texas. Ihre Musik auf ihrem ersten Album &quot;Triggering A
                        Flood&quot; führt nämlich so richtig ein ! Sehr intelligente und bodenständige Texte, eine
                        twangy-Stimme und ansteckende Melodien. <strong>Courtney&apos;s</strong> Musik vermittelt
                        harmonische und melodische Glückseligkeit. <strong>Courtney Patton</strong> sagt: &quot;Das
                        Liederschreiben ist für mich wie eine Therapie!&quot;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Courtney Patton</strong> hat das Talent und die Fähigkeiten, aber auch die nötige
                        Einstellung, eine grosse Musikerin zu werden. Ihr Motto lautet : &quot;Auf unserem Lebensweg
                        sind wir alle mit Herausforderungen konfrontiert. Ein starker Wille und ein dicke Haut helfen
                        uns häufig, die grössten Hindernisse aus dem Weg zu schaffen. Wir alle haben die Fähigkeiten,
                        noch besser zu werden in allem was wir tun. Und wir alle sollten unseren Traum leben und stets
                        nach dem Besten streben und nie stehenbleiben, und unseren Weg gehen bis sich der Erfolg
                        einstellt.&quot;
                    </p>
                    <h3>Jason Eady - Countrypoet zwischen Tag und Nacht</h3>
                    <p>
                        Der begnadete <strong>Jason Eady</strong> ist ein Künstler, der die Countrymusik ohne Allüren
                        und kommerzielle Ambitionen auf ihren grundehrlichen Kern zurückführt. Statt laut und upbeat
                        sind es eher die sanften Schwingungen, die seinen Stil dominieren.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Auf seinem neuesten Album &quot;Daylight And Dark&quot; bietet <strong>Jason Eady</strong> einen
                        Liederzyklus rund um die Liebe und das Leben, in dem auch der Alkohol eine wichtige Rolle
                        spielt. Dabei geht es ihm weniger um die Freuden des Trinkgelages als um die Ursachen und
                        Konsequenzen des flüssigen Seelentrösters.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Festzuhalten ist, dass <strong>Jason Eady</strong> mit der textlichen Sensibilität eines Merle
                        Haggard und der musikalischen Emotionalität eines Don Williams tief im Herzen des Honkytonk
                        gelandet ist und ein Werk von zeitloser Schönheit geschaffen hat.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Jason Eady&apos;s</strong> Musik ist an alle gerichtet, die sich vor dem Hintergrund des
                        aktuellen New-Countrytrends eher der traditionellen Seite des Genres zugehörig fühlen. Auch wenn
                        ein Grossteil des Publikums den Interpretationen von <strong>Jason Eady</strong> nicht folgen
                        wird, ist sein Werk ein Beweis für die Ueberlebenskraft guter, stilechter Countrymusik. Auf
                        &quot;Daylight And Dark&quot; wird <strong>Jason Eady</strong> u.a. begleitet von seiner Ehefrau
                        <strong>Courtney Patton</strong>.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Ich freue mich, Euch diese beiden aussergewöhnlichen Singer-Songwriter gemeinsam zu
                        präsentieren. Traditional Country Musik is still alive. Es lebe die Poesie !
                    </p>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_cp-je_03.jpg"
                            alt="CPJE"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165, marginTop: 5 }}
                            src="/images/konzerte/konzert_2014_cp-je_05.jpg"
                            alt="CPJE"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165, marginTop: 5 }}
                            src="/images/konzerte/konzert_2014_cp-je_04.jpg"
                            alt="CPJE"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2014_cp-je_03.png"
                            alt="RL"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-R&Uuml;CKBLICK
                        <br />
                        COURTNEY PATTON &amp; JASON EADY
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Sonntag, 19. Oktober 2014
                                <br />
                                <span className="color1">BERICHT IM COUNTRY STYLE:</span> Ausgabe 56, November 2014
                                <br />
                                <span className="color1">TEXT: </span>Thomas Kobler
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.countrystyle.ch" title="Country Style" target="_blank" rel="noreferrer">
                                www.countrystyle.ch
                            </a>
                            <br />
                            Konzert Details:{' '}
                            <a href="konzerte.html#courtneypattonjasoneady">Courtney Patton & Jason Eady</a>
                        </p>
                    </div>
                    <h3>Die Chemie stimmte</h3>
                    <p>
                        <strong>
                            Als ob es dieser Bestätigung noch bedurft hätte im Raum Basel, bewies das texanische
                            Singer/Songwriter-Ehepaar Jason Eady und Courtney Patton dem Publikum im Kulturzentrum
                            &quot;Alts Schlachthuus&quot; in Laufen, wie vielfältig die Reaktionen sein können, wenn die
                            richtigen Elemente im Spiel sind.
                        </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Zwei Gitarren verursachten kein grosses Aufheben, folglich machte sich Jason Eady gleich daran,
                        das Publikum in Versuchung zu führen. Mit <em>Temptation</em> von seinem neuen Album
                        &quot;Daylight & Dark&quot; gelang das auf Anhieb, weil alles passte: die kluge, metaphorische
                        Adaption eines Bibelverses, seine unaufgeregte Stimme, die für Country-Musik wie gemacht
                        scheint, sowie der sorgfältige Background-Gesang von Courtney Patton. Das zweite Lied gehörte
                        ihr:
                        <em>Twisted</em>
                        sind die Worte, die man sich an den Kopf wirft, bevor man aus der Tür stürmt und sie einen
                        Moment später richtig knallen lässt. Der Refrain &quot;Crying eyes are overrated&quot; - Tränen
                        sind überbewertet - lädt förmlich dazu ein, richtig laut mitgesungen zu werden, wenn man im Auto
                        mit gepackten Sachen davonbraust. Ziemlich befreiend. Im Gegensatz zur wuchtigen Produktion des
                        Liedes auf Platte, die den Refrain schier unwiderstehlich macht, sorgten die reduzierte
                        Instrumentierung und die leiseren Zwischentöne im Text für fast andächtige Stille im
                        Konzertsaal. Der Vorwurf, dass der männliche Protagonist im Song es fertigbrachte, aus einem
                        glänzenden Juwel einen stumpfen Kiesel zu machen, verfehlte seine Wirkung nicht bei den
                        Zuhörern. Nach diesem Auftakt wollte man nur noch eines: mehr davon!
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Und die beiden lieferten: Jason Eady liess mit dem Titellied seines vierten Albums &quot;AM
                        Country Heaven&quot;, das von den US-Genre-Kommentatoren in 2012 hoch eingestuft wurde, keinen
                        Zweifel darüber, was er von der Musik hält, die heute als Country im Radio gespielt wird. Seine
                        Frau bewies mit <em>Someday Soon</em>, jenem Rodeo-Song-Klassiker, der von Suzy Bogguss einst
                        perfekt gesungen wurde, dass ihre Stimme ein sehr schönes Klanginstrument ist. Weil die Chemie
                        auch zwischen der temperamentvollen Courtney Patton und ihrem ruhigeren Ehemann stimmt, fallen
                        den beiden Duette leicht. In <em>Man On A Mountain</em>, einer flotten Bluegrass-Nummer, wird
                        die Mittelstation als ziemlich perfekter Ort besungen, wo sich Kerle vom Berg oben und Mädels
                        aus dem Tal treffen sollten. Sollte dieser Kompromiss nicht funktionieren, dann kann man sich
                        das Ticket künftig gleich sparen.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die vier Zugaben am Schluss waren der abschliessende Höhepunkt einer Setliste von 28 Liedern.
                        Der Titel von <em>Cane River Blues</em> liess bezüglich Stil keine zwei Meinungen zu.
                        Bluegrass-Ikone Ralph Stanleys <em>All I Ever Loved Was You</em> sangen sie mit abgestelltem
                        Strom vorne am Bühnenrand. Und damit die fehlende, aber eigentlich unabdingbare, Steel-Guitar in
                        Eadys <em>One Too Many</em> doch erklingen konnte, &quot;miaute&quot; seine Frau auf
                        unnachahmliche, wie kreative, Weise den Ton des Instruments an den nötigen Stellen nach. Chemie
                        liefert manchmal auch ganz unerwartete Resultate. Die Nebenwirkung war eine Standing Ovation .
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_bcrl_01.jpg"
                            alt="RL"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2014_bcrl_02.png"
                            alt="RL"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Bill Chambers &amp; <br />
                        Robyn Ludwick
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>BILL CHAMBERS &amp; ROBYN LUDWICK</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 8. Juni 2014
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.billchambersmusic.com" target="_blank" rel="noreferrer">
                                www.billchambersmusic.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.robynludwick.com" target="_blank" rel="noreferrer">
                                www.robynludwick.com
                            </a>
                        </p>
                        <p>
                            Bill auf facebook:{' '}
                            <a href="https://www.facebook.com/billchambersmusic" target="_blank" rel="noreferrer">
                                www.facebook.com/billchambersmusic
                            </a>
                        </p>
                        <p>
                            Robyn auf facebook:{' '}
                            <a href="https://www.facebook.com/RobynLudwick" target="_blank" rel="noreferrer">
                                www.facebook.com/RobynLudwick
                            </a>
                        </p>
                    </div>
                    <h3>Zwei musikalische Kraftwerke</h3>
                    <p>
                        Bill Chambers und Robyn Ludwick stammen aus zwei der einflussreichsten Familien der Roots und
                        Americana Musik. Die Texanerin Ludwick ist die j&uuml;ngere Schwester von Bruce und Charlie
                        Robison und die Schw&auml;gerin von Kelly Willis. Der Australier Chambers ist der Vater von
                        Kasey Chambers.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Robyn&rsquo;s Lieder &uuml;berzeugen durch kluge Texte und emotionale Tiefe und ernten sowohl
                        bei Kritikern als auch Fans jeweils viel Lob. Bill&rsquo;s rauhe Stimme erinnert etwa an Bob
                        Dylan und John Prine. Bill meint, das sei wohl das Ergebnis der vielen Jahre, die er mit seiner
                        Familie im australischen Outback verbracht habe. Bill gilt als der angesehenste Alt-Country
                        Gitarrist Australiens. Ein Meister am Dobro oder an der Lap Steel, ein Teufelskerl an der Slide
                        Gitarre.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Zusammen erschliessen sich die beiden neue Wege als S&auml;nger und Liederschreiber. Bill und
                        Robyn empfinden eine starke Seelenverwandtschaft f&uuml;reinander, die stark auf ihrem
                        &auml;hnlichen musikalischen Hintergrund beruht. So ist es verst&auml;ndlich, dass Bill und
                        Robyn anfingen, zusammen Lieder zu schreiben. Schliesslich reifte der Entschluss, diese
                        australisch-texanische Zusammenarbeit mit einem gemeinsamen Duett-Album zu kr&ouml;nen, das
                        einerseits in Australien und andererseits in Texas eingespielt wird.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        {' '}
                        &ldquo;Die Lieder, die wir miteinander schreiben, verschmelzen unsere Identit&auml;ten und
                        Kulturen auf einzigartige Weise, die schon fast an Magie erinnert,&rdquo; stellt Ludwick fest.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Bill und Robyn gehen dieses Jahr miteinander in Australien, in den USA und Europa auf Tournee
                        und promoten so ihr Album, das im sp&auml;teren Verlaufe des Jahres erscheinen wird.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_bcrl_03.jpg"
                            alt="RL"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2014_bcrl_02.png"
                            alt="RL"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-R&Uuml;CKBLICK
                        <br />
                        ROBYN LUDWICK &amp; BILL CHAMBERS
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Donnerstag, 8. Juni 2014
                                <br />
                                <span className="color1">BERICHT IM COUNTRY STYLE:</span> Ausgabe 52, August 2014
                                <br />
                                <span className="color1">TEXT: </span>Thomas Kobler
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.countrystyle.ch" title="Country Style" target="_blank" rel="noreferrer">
                                www.countrystyle.ch
                            </a>
                            <br />
                            Konzert Details:{' '}
                            <a href="konzerte.html#billchambersrobynludwick">Robyn Ludwick & Bill Chambers</a>
                            <br />
                        </p>
                    </div>
                    <h3>Reihenweise ausgeschlachtete Gef&uuml;hle</h3>
                    <p>
                        <strong>
                            Der Auftritt von Robyn Ludwick und Bill Chambers verwandelte das Geb&auml;ude, das einmal
                            ein Schlachthaus war, bevor es ein h&uuml;bsches Kulturzentrum wurde, wieder zur&uuml;ck in
                            seine ehemalige Funktion.
                        </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die beiden Singer/Songwriter, die Martin Meier aus Texas nach Laufen geholt hatte, wetzten die
                        Instrumente, dann gingen sie in zwei grossartigen Sets daran, einen ganzen Abend lang
                        Gef&uuml;hle freizulegen. Eigene, fremde &ndash; wer wollte das schon so genau wissen. Dazu
                        sangen und spielten sie ihre Melodien so gekonnt, dass man die Welt f&uuml;r rund zwei Stunden
                        draussen im Vorhof lassen konnte und sie nicht einen Moment lang vermisste.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Robyn Ludwicks Mr. Saturday Night er&ouml;ffnete den Abend. Hinter einer h&uuml;bschen Melodie
                        lauerte verzweifelte Hoffnung, dass die n&auml;chste Samstagabendbekanntschaft l&auml;nger als
                        nur die Nacht &uuml;berdauern w&uuml;rde, auch wenn die Vergangenheit gelehrt hatte, dass es
                        kaum lohnt, sich den richtigen Namen der Kerle zu merken. Folgerichtig landete man anschliessend
                        bei Killin&lsquo; The Blues, wo Leute Bill Chambers dabei gesehen haben wollten, wir er die Welt
                        im grossen Bogen um sich herumgeschwungen hatte oder &uuml;ber weisse Cumuluswolken gesprungen
                        war, um sich etwas von der j&auml;hen Einsamkeit abzulenken. In Something Good verlangte
                        daraufhin Robyn Ludwick, dass ihr endlich jemand etwas Gutes &uuml;ber die Liebe erz&auml;hlen
                        soll: &bdquo;&hellip;give me Rock `n Roll till I die &ndash; lieb mich bis zum Umfallen.&ldquo;
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Die Intensit&auml;t, wie es aus den f&uuml;nf dort auf der B&uuml;hne herausbrach, war deutlich
                        sp&uuml;rbar im Saal, hatte Bodenst&auml;ndig- und Eindr&uuml;cklichkeit. Gemeinsam sangen sie
                        die Songs des jeweils andern in wechselnder Folge. Ludwicks akustische Gitarre sorgte f&uuml;r
                        feinere Zwischent&ouml;ne, ihre Mundharmonika f&uuml;r dramatischere Momente. Bill Chambers
                        Lead-Gitarre &ouml;ffnete der Pein den Raum und f&uuml;hrte darin herum. Aus dem Hintergrund zog
                        Maurizio Fassinos E-Gitarre einen Boden aus sauberen Akkorden ein. John Ludwick am Bass und
                        Stefano Bertolotti am Schlagzeug gaben den Takt vor. Bereits nach den ersten f&uuml;nf Songs
                        schwelgten nicht wenige im Gl&uuml;ck &uuml;ber das h&ouml;rbare Ungl&uuml;ck der andern. Es war
                        ein Musikerlebnis, dessen Dramaturgie genau so gut ins Opernhaus wie ins alte Laufener
                        Schlachthaus gepasst h&auml;tte. Andererseits, ist nicht ein Schlachthaus der perfekte Ort zum
                        Ausbeinen von Gef&uuml;hlen?
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Diesen Herbst soll die erste gemeinsame Platte herauskommen, und man darf, glaube ich, mit Fug
                        und Recht auf ein besonderes Album der beiden hoffen. Bill Chambers, dessen Stimme von vielen in
                        der N&auml;he von Bob Dylans angesiedelt wird &ndash; was nicht unbedingt ein Kompliment sein
                        muss &ndash; bringt viel australische Abenteuerlust ins Spiel, w&auml;hrend Robyn Ludwick
                        furchtlos in Abgr&uuml;nde blicken kann und daraus faszinierende Lieder macht. Wenn sie etwa in
                        Boulevard singt &bdquo;&hellip;we ain&rsquo;t looking for love, we&rsquo;re just trying to get
                        by &ndash; wir suchen keine Liebe, wir versuchen bloss &uuml;ber die Runden zu
                        kommen&hellip;&ldquo;, dann platzen Tr&auml;ume von &bdquo;Pretty Women&ldquo; auf dem Strich
                        und Illusionen von Freiern gleichermassen. Und was k&ouml;nnte abenteuerlicher sein als
                        Regenb&ouml;gen nachzujagen, wie es der Australier auf seinem Album &bdquo;Frozen Ground&ldquo;
                        auch schon probiert hat.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_boh_01.jpg"
                            alt="BOH"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2014_boh_02.png"
                            alt="BOH"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br /> Band of Heathens
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>BAND OF HEATHENS aus Austin</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Donnerstag, 8. Mai 2014
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.bandofheathens.com"
                                title="Band of Heathens"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.bandofheathens.com
                            </a>
                            <br />
                            <a
                                href="http://www.bandofheathens.de"
                                title="Band of Heathens"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.bandofheathens.de
                            </a>
                        </p>
                        <p>
                            auf facebook:{' '}
                            <a href="https://www.facebook.com/thebandofheathens" target="_blank" rel="noreferrer">
                                www.facebook.com/thebandofheathens
                            </a>
                        </p>
                    </div>
                    <h3>DAS Kronjuwel der Roots-Musik</h3>
                    <p>
                        Was die Band Of Heathens so unwiderstehlich macht, sind die Qualitäten, die der Band seit ihrer
                        Gründung im Jahr 2005 innewohnen. Damals schlossen sich Ed Jurdi, Gordy Quist und Colin Brooks,
                        die allesamt schon Soloalben veröffentlicht hatten und als eigenständige Singer-Songwriter in
                        Austin arbeiteten, nach einer Reihe von losen Jams im Austiner Club Momos&apos;s fest zusammen.
                        Die geistesverwandten Musiker formierten sich zu einer packenden Rock&apos;n&apos;Roll-Band, ja
                        vielleicht zur herausragendsten Roots Music Band Amerikas, die gleich mit zwei Livealben ihre
                        Bandkarriere startete. Und es sind diese packenden und energiegeladenen Live-Shows, die die
                        Konzertbesucher sowohl in Amerika als auch in Europa begeistern und die Fangemeinde immer
                        grösser werden lässt.
                        <br />
                        <br />
                        Verönderungen gehören zum Leben. Auch Band Of Heathens wurden von einschneidenden Veränderungen
                        nicht verschont. So verliess Gründungsmitglied Brooks Ende 2011 die Band, bald darauf folgten
                        Bassist Seth Whitney und Drummer John Chipman. Doch nicht nur die Bandbesetzung, sondern auch
                        die Lebensumstände änderten. Doch Quist und Jurdi gingen unbeirrbar ihren Weg, ersetzten die
                        abgesprungenen Musiker mit dem Keyboarder Trevor Nealon und dem Schlagzeuger Richard Millsap,
                        gründeten Familien und Jurdi zog sogar nach Carolina um.
                        <br />
                        <br />
                        All diese Umstände und Veränderungen wurden in das neue Album &quot;Sunday Morning Record&quot;
                        eingearbeitet. Ein Album, das den Weg von Quist und Jurdi durch eine sehr schwierige Zeit in
                        vielen tiefgründigen Liedern dokumentiert. Das Werk enthült Songs, die so stark und eingängig
                        sind, wie man es von den Heathens kennt. Es betont aber die akustischen Elemente stärker als je
                        zuvor, ebenso sind mehr Klavier bzw. Keyboards-Klänge zu hören. &quot;Es sind die persönlichsten
                        Lieder, die je von den Heathens veröffentlicht wurden. Das Album ist entspannter, ruhiger, hat
                        aber sowohl textlich als auch musikalisch schärfere Konturen, ohne die Roots-Orientierung zu
                        verlieren&quot;, so die beiden Gründungsmitglieder. Nach wie vor sind Anlehnungen an Little Feat
                        oder The Band da, aber vermehrt sind auch Einflüsse der grossen Singer-Songwriter aus den 70er
                        Jahren wie Jackson Browne, Billy Joel oder Paul McCartney hörbar.
                        <br />
                        <br />
                        Die grössere Intimität von &quot;Sunday Morning Record&quot; zeigt sich schon aus einer Zeile
                        des Songs &quot;Records In Bed&quot; mit dem Hinweis auf die Notwendigkeit, die Eile und dem
                        Lärm des Alltags zu entfliehen, um sich Musik und Kunst wirklich widmen zu können. &quot;Es
                        scheint, als falle es den Menschen immer schwerer, sich dem ständigen Strom an Informationen und
                        Ablenkung zu entziehen, um sich in Kunst zu verlieren&quot;, meint Quist. &quot;Wir sind
                        jederzeit mit der ganzen Welt verbunden und das bereichert unser Leben. Aber ich glaube, dass
                        wir manchmal verpassen, wie bereichernd es sein kann, das hier und jetzt und unsere unmittelbare
                        Umgebung zu erleben. Ich hoffe, dass dieses Album die Menschen dazu bewegt, sich mal
                        auszuklinken und mithilfe der Musik zu sich selbst zu finden und sich seinen Freunden zu
                        widmen&quot;.
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Ich bin gespannt, wie diese Songs bei den Live-Konzerten ankommen. Vielleicht werden die
                            Leute die Band mit anderen Augen sehen.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Jurdi
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <p>
                        In all dem Chaos, das uns umgeben hat, war die Musik unser Zufluchtspunkt. Wir haben unsere
                        Reise durch eine seltsame, ungewohnte, anstrengende Zeit in Musik umgesetzt. Ihr k&ouml;nnt
                        alles h&ouml;ren: die Freude, den Herzschmerz, die Entt&auml;uschung, die Sehnsucht und
                        letztlich die Entschlossenheit dieser Band, weiter zusammen Musik zu machen und auf Tour zu
                        gehe.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_boh_01.jpg"
                            alt="BOH"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2014_boh_02.png"
                            alt="BOH"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br /> Band of Heathens
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-R&Uuml;CKBLICK
                        <br />
                        BAND OF HEATHENS
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Donnerstag, 8. Mai 2014
                                <br />
                                <span className="color1">BERICHT IM COUNTRY STYLE:</span> Ausgabe 50, Juni 2014
                                <br />
                                <span className="color1">TEXT: </span>Thomas Kobler
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.countrystyle.ch" title="Country Style" target="_blank" rel="noreferrer">
                                www.countrystyle.ch
                            </a>
                            <br />
                            Konzert Details: <a href="konzerte.html#bandofheathens">Band of Heathens</a>
                            <br />
                        </p>
                    </div>
                    <h3>Unter Heiden</h3>
                    <p>
                        <strong>
                            Ein altes Schlachthaus, das mittlerweile Kult(ur)st&auml;tte ist &ndash; einen besseren Ort
                            f&uuml;r heidnische Kl&auml;nge aus Texas kann man sich kaum vorstellen.
                        </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Der positiv-verr&uuml;ckte Martin Meier, dem es immer wieder gelingt, hervorragende
                        K&uuml;nstler und Musik aus Texas ins Laufener Kulturzentrum &bdquo;alts Schlachthuus&ldquo; zu
                        holen, landete einen weiteren Volltreffer. Die Roots-Rockband The Band of Heathens aus Austin
                        vermittelte dort im Saal eine Idee, wo der Gott des erdigen, texanischen Gitarren-Rocks hocken
                        k&ouml;nnte. Kurz nach Acht fackelten Ed Jurdi, Gordi Quist, Trevor Nealon (Keyboards), Scott
                        Davis (Bass), Richard Millsap (Drums) nicht lange und r&uuml;ttelten mit dem dampfwalzenartigen
                        <em>Shake The Foundation</em> schon mal geh&ouml;rig am Fundament des Kulturtempels. Das hatte
                        Kraft, das hatte Saft.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Neu formiert und nach einer kreativen Pause, aus der ihr neues Album &bdquo;Sunday Morning
                        Record&ldquo; hervorging, machten sie live klar, dass YouTube-Clips nicht immer die volle
                        Wirklichkeit sind. Vielleicht war es der Ort, der perfekt zu den F&uuml;nf passte, vielleicht
                        das Publikum, das wie ich gespannt war auf die hochgelobten Texaner. Schwer zu sagen. Aber die
                        Gitarren von Jurdi und Quist sowie Trevor Nealons Keyboard woben zusammen an einem Klangteppich,
                        den Richard Millsap kr&auml;ftig durchklopfte und wo Scott Davis&lsquo; Bass zus&auml;tzlich
                        noch dr&uuml;berstampfte. Das Publikum lauschte beinahe and&auml;chtig den zwei Sets und den
                        Zugaben.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Ihr <em>Hold On</em> k&ouml;nnte man als texanischen Reggae bezeichnen. <em>Maple Tears</em>
                        t&ouml;nt nach Lagerfeuer, bloss elektrisch, L<em>ook At Miss Ohio</em> ist eine coole
                        Interpretation von Gilian Welchs Original. <em>Miss My Life</em> ist im weitesten Sinne eine
                        tex-heidnische Version von Polo Hofers (der auch am Konzert war) <em>Kiosk</em> und
                        <em>Shotgun</em>, vom neuen Album, einfach ein klasse Lied.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Der Kulturgrossraum Basel war am 8. Mai jedenfalls einen Abend lang um eine Attraktion reicher.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2014;
