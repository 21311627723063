import '../App.scss';
import { useAuth } from '../auth/auth-provider';

export const AdminMenu = () => {
    const { profile, logout, hasRole } = useAuth();

    return (
        <div id="login">
            {(profile?.id ?? 0) > 0 && (
                <>
                    {hasRole(profile, 'admin') && (
                        <a href="/admin" style={{ color: 'orange', marginRight: '15px' }}>
                            <i className="fa fa-laptop" aria-hidden="true"></i>
                        </a>
                    )}
                    <a href="/tickets" onClick={logout} style={{ color: 'orange' }}>
                        <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                    </a>
                </>
            )}
            {(profile?.id ?? 0) === 0 && (
                <a href="/login">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                </a>
            )}
        </div>
    );
};
