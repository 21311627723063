import { MainContent } from '../components';

export const LegalNotices = () => {
    return (
        <MainContent>
            <div id="mainwide">
                <h1>Rechtliche Hinweise</h1>
                <div>
                    <h3>Impressum</h3>
                    <p>Verantwortlich f&uuml;r die Inhalte dieser Webseite ist SwissTexMusic.</p>
                    <h3>Inhalte, Fotos &amp; Bilder</h3>
                    <p>
                        Die Inhalte, Fotos und Bilder dieser Webseite unterliegen dem Copyright und d&uuml;rfen ohne
                        ausdr&uuml;ckliche Genehmigung nicht kopiert werden.
                    </p>
                    <h3>Haftungsbeschr&auml;nkung</h3>
                    <p>
                        {' '}
                        SwissTexMusic &uuml;bernimmt keinerlei Gew&auml;hr bez&uuml;glich Aktualit&auml;t, Korrektheit,
                        Vollst&auml;ndigkeit und Qualit&auml;t der bereitgestellten Informationen.
                        Haftungsanspr&uuml;che gegen SwissTexMusic, die sich auf Sch&auml;den materieller oder ideeller
                        Art beziehen, welche durch die Nutzung oder Nichtnutzung der angebotenen Informationen
                        verursacht wurden, sind ausdr&uuml;cklich ausgeschlossen.
                    </p>
                    <h3>Verweise und Links</h3>
                    <p></p>
                    <p>
                        Bei direkten oder indirekten Verweisen auf fremde Internetseiten (&quot;Links&quot;), die
                        ausserhalb des Verantwortungsbereiches von SwissTexMusic liegen, w&uuml;rde eine
                        Haftungsverpflichtung ausschliesslich in dem Fall in Kraft treten, in dem SwissTexMusic von den
                        Inhalten Kenntnis hat und es technisch m&ouml;glich und zumutbar w&auml;re, die Nutzung im Falle
                        rechtswidriger Inhalte zu verhindern.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        SwissTexMusic erkl&auml;rt daher ausdr&uuml;cklich, dass zum Zeitpunkt der Linksetzung die
                        entsprechenden gelinkten Seiten frei von illegalen Inhalten waren. SwissTexMusic hat keinerlei
                        Einfluss auf die aktuelle und zuk&uuml;nftige Gestaltung und auf die Inhalte der gelinkten
                        Seiten. SwissTexMusic distanziert sich hiermit ausdr&uuml;cklich von allen Inhalten aller
                        gelinkten Seiten, die nach der Linksetzung ver&auml;ndert wurden. SwissTexMusic lehnt jegliche
                        Gew&auml;hrleistung und Haftung f&uuml;r die Richtigkeit des Inhaltes der verlinkten Webseiten
                        ab. F&uuml;r den Inhalt der verlinkten Webseiten und insbesondere f&uuml;r Sch&auml;den, die aus
                        der Nutzung oder Nichtnutzung der verlinkten Webseiten entstehen, haftet allein der Anbieter der
                        Seite, auf die verwiesen wurde; nicht derjenige, der &uuml;ber Links auf die jeweilige
                        Ver&ouml;ffentlichung lediglich verweist. Es wird davon ausgegangen, dass alle auf dieser
                        Webseite enthaltenen Links nur auf Webseiten ohne rechtswidrigen Inhalte verweisen. Sollten die
                        Links auf Webseiten mit rechtswidrigem, strafrechtlich relevanten Material f&uuml;hren
                        (pornographisches oder rassistisches Material, illegale Kopien von Software zum Herunterladen
                        etc.) bitten wir unsere Besucher, uns dies mitzuteilen, damit der entsprechende Link
                        gel&ouml;scht werden kann.
                    </p>
                    <p>&nbsp;</p>
                </div>
            </div>
        </MainContent>
    );
};

export default LegalNotices;
