import { useEffect, useState } from 'react';
import preval from 'preval.macro';
import { Tooltip } from '@mui/material';
import clientFactory from '../services/client-factory';
import { AppControllerClient, EnvironmentDto } from '../clients/services';
import { blue, green, red } from '@mui/material/colors';
const buildTime = preval`module.exports = new Date();`;
const client = clientFactory.createClient(AppControllerClient);

const getColor = (clientEnv?: string, serverUrl?: string, hasError?: boolean, serverEnv?: EnvironmentDto) => {
    if (hasError || !clientEnv || !serverUrl) {
        return red.A200;
    }

    if (
        serverEnv &&
        (serverEnv.configKey.toLowerCase() !== clientEnv.toLowerCase() || serverEnv.apiUrl !== serverUrl)
    ) {
        return red.A200;
    }

    if (clientEnv === 'development') {
        return green[700];
    }

    if (clientEnv === 'staging') {
        return blue[900];
    }

    return 'none';
};

const getProblem = (clientEnv?: string, serverUrl?: string, hasError?: boolean, serverEnv?: EnvironmentDto) => {
    if (hasError || !clientEnv || !serverUrl) {
        if (!serverUrl) {
            return 'server url not known';
        }

        if (hasError) {
            return 'server not reachable';
        }

        return 'no client env';
    }

    if (serverEnv && (serverEnv.configKey !== clientEnv || serverEnv.apiUrl !== serverUrl)) {
        if (serverEnv.configKey.toLowerCase().trim() !== clientEnv.toLowerCase().trim()) {
            return `the client and server envs are different '${serverEnv.configKey}' vs '${clientEnv}'`;
        }

        if (serverEnv.apiUrl !== serverUrl) {
            return `the api urls are not equally configured '${serverEnv.apiUrl}' vs '${serverUrl}'`;
        }
    }

    return '';
};

export const EnvironmentIndicator = () => {
    const [serverEnvironment, setServerEnvironment] = useState<EnvironmentDto | null>(null);
    const reactAppEnv = process.env.REACT_APP_ENV;
    const apiUrl = process.env.REACT_APP_SERVICE_URL;

    const [problem, setProblem] = useState<string>('');

    const [color, setColor] = useState<string>(getColor(reactAppEnv, process.env.REACT_APP_SERVICE_URL, false));
    const [hadError, setHadError] = useState<boolean>(false);

    const loadServerEnvironment = async () => {
        if (reactAppEnv !== 'production') {
            setHadError(false);
            try {
                const env = await client.getEnvironment();
                setHadError(false);
                setServerEnvironment(env);
                setProblem(getProblem(reactAppEnv, apiUrl, false, env));
                setColor(getColor(reactAppEnv, apiUrl, false, env));
            } catch {
                setHadError(true);
                setProblem('server returned an error');
            }
        }
    };

    useEffect(() => {
        loadServerEnvironment();
    }, []);

    return (
        <>
            {reactAppEnv !== 'production' && (
                <>
                    <Tooltip
                        title={
                            <>
                                Client-Environment: {reactAppEnv}
                                <br />
                                Client-Build: {buildTime.toLocaleString()}
                                <br />
                                API-URL as known from client:
                                <br /> {process.env.REACT_APP_SERVICE_URL}
                                <br />
                                <br />
                                {serverEnvironment ? (
                                    <>
                                        API-Environment: {serverEnvironment.processEnvironment}
                                        <br />
                                        API-ConfigEnvironment: {serverEnvironment.configEnvironment}
                                        <br />
                                        API-ConfigKey: {serverEnvironment.configKey}
                                        <br />
                                        API-Version: {serverEnvironment.version}
                                        <br />
                                        APP-URL: {serverEnvironment.baseUrl}
                                        <br />
                                        API-URL: {serverEnvironment.apiUrl}
                                        <br />
                                    </>
                                ) : (
                                    <>Server environment loading...</>
                                )}
                                {problem && (
                                    <b>
                                        <br /> <br />
                                        {problem}
                                    </b>
                                )}
                            </>
                        }
                    >
                        <div
                            className="environmentMarker"
                            style={{ backgroundColor: hadError ? 'red' : color, textAlign: 'center' }}
                        >
                            <div style={{ marginTop: 5 }}>
                                {reactAppEnv?.toUpperCase()} <br />
                                API: {process.env.REACT_APP_SERVICE_URL}
                                <br />
                                {hadError ? (
                                    <>API not reachable!</>
                                ) : (
                                    <>{serverEnvironment?.configKey?.toUpperCase()}</>
                                )}
                            </div>
                        </div>
                    </Tooltip>
                </>
            )}
        </>
    );
};
