import { IClientFactory } from './client-factory';

export class FileDownloader {
    constructor(private clientFactory: IClientFactory) {}

    async downloadFile<T1>(
        ctor: new (baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) => T1,
        execute: (client: T1) => Promise<void>,
        fileName: string,
        secured: boolean,
    ): Promise<void> {
        const handleResponse = async (response: Response): Promise<Response> => {
            const anchor = document.createElement('a');
            document.body.appendChild(anchor);
            const blob = await response.clone().blob();
            const objectUrl = window.URL.createObjectURL(blob);
            anchor.href = objectUrl;
            anchor.download = fileName;
            anchor.click();
            window.URL.revokeObjectURL(objectUrl);
            return new Response('{}');
        };

        const client = this.clientFactory.createSpecialResponseClient<T1>(ctor, handleResponse, secured);
        await execute(client);
    }
}
