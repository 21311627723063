export const Archive2012 = () => {
    return (
        <>
            {' '}
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2013_kb_01.jpg"
                            alt="Krueger Brothers"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Kr&uuml;ger Brothers
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>KR&Uuml;GER BROTHERS</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 11. November 2012
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19 Uhr (T&uuml;r&ouml;ffnung 18 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.krugerbrothers.com" target="_blank" rel="noreferrer">
                                www.krugerbrothers.com
                            </a>
                        </p>
                    </div>
                    <h3>Einfach genial</h3>
                    <p>
                        Mit <strong>Jens Kr&uuml;ger</strong> (Banjo), <strong>Uwe Kr&uuml;ger</strong> (Gitarre,
                        Leadgesang), <strong>Joel Landsberg</strong> (Bass). Musikalisch geh&ouml;ren die Kr&uuml;ger
                        Brothers mit ihrer gelungenen Mischung aus Bluegrass, Country, Folk und Klassik zweifellos zum
                        Besten, was man &uuml;berhaupt zu h&ouml;ren bekommt.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2012_ag_01.jpg"
                            alt="ALBERT AND GAGE"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>ALBERT &amp; GAGE</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Montag, 22. Oktober 2012
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.albertandgage.com" target="_blank" rel="noreferrer">
                                www.albertandgage.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.myspace.com/albertandgage" target="_blank" rel="noreferrer">
                                www.myspace.com/albertandgage
                            </a>
                        </p>
                    </div>
                    <h3>Exquisite Musik aus Austin, TX</h3>
                    <p>
                        Die Musik von Albert &amp; Gage einzuordnen ist sehr schwierig. Das Duo besticht stets mit einem
                        unglaublich abwechslungsreichen Programm. F&uuml;r mich spielen sie vor allem &quot;music made
                        in Texas&quot;, und diese Musik umfasst nebst Country und Folk auch Blues, Soul, Swing, Jazz und
                        sogar Chansons. Mit ihren perfekt harmonierenden Stimmen und ihrem instrumentalen K&ouml;nnen
                        entfalten Albert &amp; Gage eine erstaunliche Klangf&uuml;lle. Akzentuiert wird ihre Musik im
                        besonderen von Chris Gage&apos;s leidenschaftlichem Klavierspiel sowie seinen virtuosen und
                        melodi&ouml;sen Gitarrensoli. Gage kitzelt dabei das Klavier genau so souver&auml;n wie seine
                        Gitarre. Christine Albert hingegen ist mit ihrer Gitarre f&uuml;r den Rhythmus und die Harmonie
                        zust&auml;ndig.
                    </p>
                    <p>&nbsp; </p>
                    <p>
                        Albert &amp; Gage ernteten bei den beiden bisherigen Auftritten in Laufen jeweils frenetischen
                        Applaus und stehende Ovationen ohne Ende. Wer das Duo schon einmal live erlebt hat, wird auch
                        das n&auml;chste Konzert nicht verpassen wollen. Albert &amp; Gage werden von{' '}
                        <strong>David Carroll</strong> am Contrabass begleitet, der mal f&uuml;r eine dezente, mal
                        f&uuml;r eine fetzige rhythmische Unterlage sorgen wird.
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Um diese ... so herrlich unkompliziert, aufreizend elektrisierend und dann auch wieder bis
                            in die tiefste Seele gef&uuml;hlvoll gemachte Musik zu erleben, ... lohnt sich jeder weite
                            Weg.
                            <span className="color3 quelle">
                                <br />
                                &ndash; Rudolf Wesner
                            </span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2012_cw_01.jpg"
                            alt="CAROLYN WONDERLAND"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>CAROLYN WONDERLAND</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Donnerstag, 11. Oktober 2012
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.carolynwonderland.com" target="_blank" rel="noreferrer">
                                www.carolynwonderland.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.myspace.com/carolynwonderland" target="_blank" rel="noreferrer">
                                www.myspace.com/carolynwonderland
                            </a>
                        </p>
                    </div>
                    <h3>A real treasure back in Laufen</h3>
                    <p>
                        Carolyn Wonderland&rsquo;s Markenzeichen sind einerseits ihre &uuml;berragende Musikalit&auml;t
                        und andererseits ihre genre&uuml;bergreifende Stimme. Sie gilt zu recht als begnadete
                        Blues-Gitarristin, doch bringt sie nicht nur alle m&ouml;glichen Saiteninstrumente zum Klingen,
                        sondern sie bl&auml;st auch manchmal noch die Trompete.
                    </p>
                    <p>&nbsp; </p>
                    <p>
                        Carolyn Wonderland f&uuml;hlt sich in allen Roots-Spielarten zuhause. Ob
                        gem&uuml;tliche-countrym&auml;ssige Passagen, ob ruhige und jazzige T&ouml;ne, ob aggressive und
                        reibeisenartige Tonlagen, oder bluesige Kl&auml;nge, sie singt stets glaubw&uuml;rdig und
                        ungek&uuml;nstelt. Aber das hie&szlig;e, die vielen anderen Talente einer der
                        meistbesch&auml;ftigten Powerfrauen in Texas zu verkennen. Carolyn Wonderland ist n&auml;mlich
                        ebenso eine hochkar&auml;tige Songschreiberin, die den Gro&szlig;teil ihres Repertoires selber
                        komponiert, als auch eine tolle Entertainerin, die auf der B&uuml;hne stets voll aus sich
                        herausgeht.
                    </p>
                    <p>&nbsp; </p>
                    <p>
                        Wer Carolyn Wonderland schon im Juli 2010 in Laufen erlebt hat, wird das Konzert dieser
                        beeindruckenden K&uuml;nstlerin bestimmt nicht verpassen wollen und gleich noch ein paar Freunde
                        und Bekannte mitnehmen. Begleitet wird sie wie schon vor 2 Jahren von{' '}
                        <strong>Cole El-Saleh</strong> (Keyboards, Bass) und <strong>Rob Hooper</strong> (Schlagzeug).
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Carolyn Wonderland&apos;s live shows are just outright phenomenal. Top notch, five-stars,
                            off the meter - whatever the clich., Carolyn and her band meet it...Her voice can be as
                            beautiful as an angel and as powerful as a Class 5 hurricane all within the drop of a
                            hat...One thing&apos;s for certain, some forces - even those barely five feet tall - cannot
                            be contained. <br />
                            <span className="color3 quelle">&ndash; Dante Dominick, Rockzillaworld Magazine</span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Carolyn Wonderland is the real deal! She&apos;s an amazing guitar player. She whistled a
                            solo. She even played the trumpet! And damn, can she sing.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Los Angeles Times
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            A dollop of Janis Joplin, a slice of Stevie Ray Vaughan, and a big load of soulful
                            individuality... that&apos;s Wonderland, a seething-hot Texas singer-guitarist. And she can
                            write, too! Produced by Asleep at the Wheel&apos;s Ray Benson, Miss Understood focuses on
                            tough yet vulnerable blues, but also captures the melodic soul of classic American song...
                            No wonder Dylan is an avowed fan.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Daniel Gewertz, Boston Herald
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            When she blisters the guitar and cocks her head fetchingly to sing her songs like
                            &quot;I&apos;m Innocent&quot; she stands in the good company of Sue Foley, Debbie Davies,
                            and Bonnie Raitt. But when she whistles, as she does with disarming ease on another of her
                            compositions &quot;I&apos;m the Man,&quot; or picks up the trumpet, she&apos;s one of a
                            kind.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Margaret Moser, Austin Chronicle
                            </span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2012_dc_01.jpg"
                            alt="DEL CASTILLO"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>DEL CASTILLO</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Montag, 20. August 2012
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://delcastillomusic.com" target="_blank" rel="noreferrer">
                                delcastillomusic.com
                            </a>
                        </p>
                    </div>
                    <h3>Brilliance on nylon-classical guitars</h3>
                    <p>
                        DEL CASTILLO nennen ihren Stil &ldquo;Nuevo Americano&rdquo; und &uuml;berraschen ihre
                        Zuh&ouml;rer mit einem sehr abwechslungsreichen Repertoire. Die sechsk&ouml;pfige Band um die
                        beiden namensgebenden Br&uuml;der Rick &amp; Mark del Castillo spielt eine furiose Mischung aus
                        elektrifiziertem Flamenco, mexikanischer Folklore, Latin, Rock und Blues. Die Band wurde im Jahr
                        2000 von den beiden Br&uuml;der gegr&uuml;ndet, beide gypsyinspierte brilliante Gitarristen.
                        Weitere Mitglieder sind der charismatische Frontmann und S&auml;nger Alex Ruiz, Albert Besteiro,
                        der am Bass f&uuml;r das Rhythmusger&uuml;st sorgt, Mike Zeoli am Schlagzeug sowie Carmelo Torre
                        an der Perkussion.
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        DEL CASTILLO ist seit Jahren ein fester Bestandteil der bekannt hochwertigen in und um Austin.
                        Die Band wurde bereits mit 18 Music Awards in unterschiedlichen Kategorien ausgezeichnet, war
                        &ldquo;Band of the Year&rdquo; und &ldquo;Best World Music Band&rdquo; und hat Songs f&uuml;r
                        diverse Filme geliefert. Mit ihren Beitr&auml;gen zu den Soundtracks von Filmen wie &bdquo;Kill
                        Bill Vol.II&ldquo;, &ldquo;Once Upon a Time in Mexico&rdquo; und &ldquo;Sin City hat die Band
                        bereits ein Millionenpublikum begeistert. Quentin Tarantino hat sogar das Ende von &bdquo;Kill
                        Bill Vol.II&ldquo; erneut gedreht, damit er es mit einem Song von DEL CASTILLO unterlegen
                        konnte.
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Das Temperament, die Leidenschaft und die schier unglaubliche Virtuosit&auml;t der grandiosen
                        Band &uuml;bertragen sich sofort auf die Zuschauer und verwandeln jeden Konzertsaal in einen
                        brodelnden Hexenkessel!
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2012_jl_01.jpg"
                            alt="JAMES LANN"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>JAMES LANN</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Mittwoch, 8. August 2012
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRIT T:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.jameslann.com" target="_blank" rel="noreferrer">
                                www.jameslann.com
                            </a>
                        </p>
                    </div>
                    <h3>Ein echter Cowboy</h3>
                    <p>
                        Viele Musiker aus Nashville und Texas tragen Cowboyh&uuml;te, aber George Strait und James Lann
                        sind zwei von ganz wenigen Countrys&auml;ngern, die auch wirkliche Cowboys sind.
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Seine Familie betreibt in der 5. Generation Rinderfarmen. Aufgewachsen mit K&uuml;hen und
                        Rindern tauschte er mit 18 das Pferd und das Lasso mit der Gitarre. In Texas war er Leadsinger
                        in verschiedenen Country Music Bands und tourte um Houston herum. Dort gr&uuml;ndete er auch
                        seine eigene Band &ndash; The James Lann Band.
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Bevor James Lann nach Nashville zog, entwickelte er zuerst seinen eigenen Sound und baute seine
                        Fanbasis in Texas auf. Musikalisch bietet James Lann kr&auml;ftige und eingehende,
                        gitarren-getriebene Melodien mit einer geschmeidigen und souver&auml;nen Baritonstimme. Sein
                        Markenzeichen ist der Honky Tonk Twang, melodi&ouml;se und tanzbare Lieder.{' '}
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Sein erstes Album &bdquo;Ford&ldquo; (gleichzeitig seine erste Single) erntete grosse Beachtung.
                        Die zweite Single &bdquo;You&rsquo;re from the country too&ldquo; war sein erster Top 10 Hit in
                        den Texas Charts. Mit seiner dritten Single &bdquo;Honky Top Two Step Queen&ldquo; erreichte er
                        seine erste No. 1. Seither kamen drei weitere Nr. 1 Hits und sein zweites Album &bdquo;Honky
                        Tonk Kung-Fu&ldquo; dazu. Es ist eine Sammlung von Liedern, die daherkommen wie eine Fahrt auf
                        einer Achterbahn. Wie im richtigen Leben geht es mal rauf, mal runter.
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Ueberzeugen wir uns selbst von James Lann und seiner Band. Im Rahmen seiner ausgedehnten
                        Europatournee erfolgt die Schweizer Premiere am 8. August 2012 in Laufen. This Cowboy is keeping
                        it Country!
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2012_eg_01.jpg"
                            alt="ELIZA GILKYSON"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>ELIZA GILKYSON</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Mittwoch, 2. Mai 2012
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.elizagilkyson.com" target="_blank" rel="noreferrer">
                                www.elizagilkyson.com
                            </a>
                        </p>
                    </div>
                    <h3>F&uuml;r Liebhaber erstklassiger Liederkunst</h3>
                    <p>
                        Eliza Gilkyson ist nicht nur eine politisch gesinnte und poetisch begabte Liederschreiberin,
                        sondern auch eine sehr gute S&auml;ngerin. Sie ist eine der K&uuml;nstlerinnen, die es gut
                        verstehen, ausdrucksvolle Bilder in einfachen und wunderbaren Melodien zu verpacken.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Als Tochter des erfolgreichen Liederschreibers &amp; Folks&auml;ngers Terry Gilkyson (u.a. The
                        bare necessities f&uuml;r Disneys Dschungelbuch) wusste Eliza schon fr&uuml;h, dass Musik im
                        Zentrum ihres Lebens stehen w&uuml;rde. Heute ist sie die best&auml;ndigste K&uuml;nstlerin auf
                        der Folk und Roots-Szene von Austin und eine der am meisten respektierten K&uuml;nstlerinnen des
                        Genres.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Das aktuelle Weltgeschehen inspiriert Eliza immer wieder zu eindringlichen Liedern. Anspieltipps
                        dazu sind etwa: Highway 9 (Irak-Krieg), Man of God (bissiger Anti-Bush Song), Requiem (Ode an
                        die Tsunami-Opfer), Runaway Train oder Great Correction (Ende des ungez&uuml;gelten
                        Kapitalismus), Ballad Of Yvonne Johnson (Vergewaltigungsopfer), Vaya con Norte (Leben der
                        illegalen Einwanderer), Tender Mercies (beunruhigende Geschichte &uuml;ber M&uuml;tter in der
                        dritten Welt), Looking For A Place (Ausbeutung der Ressourcen) oder 2153 (religi&ouml;se
                        Engstirnigkeit).
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Es sind aber oft auch pers&ouml;nliche, intelligente Lieder &uuml;ber Hoffnungen und
                        Entt&auml;uschungen, &uuml;ber Tr&auml;ume und Lebensrealit&auml;t. Oft geht es um das stets
                        spannende Wechselspiel vom Wegfahren und Ankommen, um den Aufbruch zu Neuem und der Suche nach
                        Heimat.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Eliza Gilkyson schm&uuml;ckt ihre Musik mit ihrer Stimme, feinen Melodien, exquisiten
                        Arrangements sowie aufw&uuml;hlenden Texten, die in eindr&uuml;cklichen Bildern ihr ganzes Werk
                        abrunden. Mit ihren Alben hat sie schon h&auml;ufig bewiesen, dass sie zu den Grossen ihres
                        Genres z&auml;hlt. Kritiker sind jeweils des Lobes voll.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Auch als S&auml;ngerin macht Eliza Gilkyson eine eindrucksvolle Figur. Locker wechselt sie immer
                        wieder mal in unterschiedliche Stimmschattierungen. Da passt alles und &uuml;berzeugt restlos,
                        ob Folk, Blues, Country oder Conjunto.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Eliza Gilkyson tritt exklusiv am 2. Mai 2012 in Laufen im Kulturzentrum Alts Schlachthuus auf.
                        F&uuml;r Liebhaber hochklassiger Liederkunst ein absolutes Muss!
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Fresh, confident and full of passion.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; All Music
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Gilkyson doesn&apos;t pull any punches. She graces the music with her lush and passionate
                            voice; a dark and lonely sound, hope and satisfaction, and edgy lyrics with piercing imagery
                            round out the whole.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; NY Times
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            A glorious voice...{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Sing Out
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            She packs a political punch that is as entertaining and blue-collar as the work of Woody
                            Guthrie.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Houston Press
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Her songwriting has a Dylanesque quality as her tunes burst with edgy lyrics and poetic
                            imagination. With a knowing eye on the past, Eliza Gilkyson is making folk music for the
                            21st Century, and it&apos;s a delightful thing to hear.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Austin Chronicle
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            An exquisite weaver of story-songs that strike at the heart of personal experience.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; No Depression
                            </span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Masterfully structured, startlingly intimate songs in a beautifully lived in voice.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Boston Globe
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Intimate, delicate-voiced and given to musical introspection...her honest voice and
                            understated, life affirming lyrics remain the core of her songs.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; People Magazine
                            </span>
                        </p>
                    </blockquote>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2012_dm_01.jpg"
                            alt="DEADMAN"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>DEADMAN</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Dienstag, 1. Mai 2012
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.deadmanonline.com" target="_blank" rel="noreferrer">
                                www.deadmanonline.com
                            </a>
                        </p>
                    </div>
                    <h3>
                        Die neue Live-Sensation aus Austin
                        <br />
                        Schweizer Premiere und Exklusiv-Konzert
                    </h3>
                    <p>
                        Deadman ist die Kultband aus Austin, die sich mit zahlreichen Live Shows im Saxon Pub und
                        anderswo einen gro&szlig;en Namen unter ihren zahlreichen Anh&auml;ngern und Fachleuten erspielt
                        hat. Aehnlich wie die befreundete Band Of Heathens haben Deadman als erstes Werk ein Live-Album
                        ver&ouml;ffentlicht. &apos;Live At The Saxon Pub&apos; ist ein stimmungsvolles Werk, das das
                        riesige Potenzial dieser tollen Formation aufzeigt, die so leichtg&auml;ngig Texas Folk,
                        Southern Rock und Country Rock verkn&uuml;pft. Assoziationen zu The Band oder Little Feat sind
                        offenkundig.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Steven Collins</strong> ist Leads&auml;nger, Songschreiber und Rhythmusgitarrist des
                        Sextetts, zu dem auch Akustikgitarrist <strong>Kevin McCollough</strong>, Drummer{' '}
                        <strong>Kyle Schneider,</strong> Hammond B3-Organist <strong>Matthew Mollica,</strong> der
                        exzellente Leadgitarrist <strong>Jacob Hildebrand</strong> und der Bassist L
                        <strong>onnie Trevino Jr. g</strong>eh&ouml;ren.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Schon das erste Lied auf der Live-CD, &apos;Brother John&apos; zeichnet die Stimmung vor: Die
                        Orgel schwillt an, die Rhythm Section ger&auml;t in einen rollenden Groove und die drei Gitarren
                        rocken herrlich abgestuft mit- und nebeneinander. Steven Collins intoniert hier seine
                        klassischen Shouter Vocals in der Manier eines Van Morrison und die anderen Musiker stimmen ein
                        in diesen bis zu f&uuml;nfk&ouml;pfigen, fast gospel-artigen Harmoniechor, ein echtes
                        Markenzeichen von Deadman. Ebenso eindrucksvoll und unverzichtbar er f&uuml;r den Gesamtsound
                        der Band ist nat&uuml;rlich die Orgel. Gewisse Lieder erinnern an Los Lobos oder Calexico, aber
                        eben auch an Band Of Heathens. Der zwingenden Melodie von &quot;When The Music&apos;s Not
                        Forgotten&quot; kann man sich einfach nicht entziehen. Das country-rockige, immer wieder von
                        Acapella-Stops unterbrochene &quot;Mankind&quot; beschliesst das so enorm stimmungsvolle
                        Live-Album.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die Schweizer Premiere dieses hoch talentierten Sextetts erfolgt am 1. Mai 2012 in Laufen, im
                        Kulturzentrum Alts Schlachthuus.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2012_mm_01.jpg"
                            alt="MICKY AND THE MOTORCARS"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>MICKY &amp; THE MOTORCARS</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Dienstag, 23. Januar 2012
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.mickyandthemotorcars.com" target="_blank" rel="noreferrer">
                                www.mickyandthemotorcars.com
                            </a>
                        </p>
                    </div>
                    <h3> Schweizer Premiere und Exklusiv-Konzert</h3>
                    <p>
                        Seit fast 10 Jahren begeistern Micky &amp; The Motorcars ihr Publikum in Texas und dar&uuml;ber
                        hinaus mit kernigem, zeitlosem Red Dirt/Country Rock. Micky und Gary Braun haben ihre Band
                        &quot;down home&quot; angelegt und sich mit gr&ouml;sster Leidenschaft dem urtexanischen Country
                        Rock verschrieben. Micky &amp; The Motorcars sind eine typische Live Band. Sehr direkte,
                        schn&ouml;rkellose, aus einem Guss, eben richtig live daherkommende Musik. Ein einziger Fluss
                        von Country Rock im mittleren bis offensiven Tempo von eing&auml;ngigen Rockern mit elektrischen
                        Gitarren und dominanten Hooklines bis zu s&auml;migen, melodiestarken Balladen mit hymnischen
                        Refrains, die allemal zum Mitsingen einladen.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Micky Braun</strong>: Gitarre, Lead-Gesang
                    </p>
                    <p>
                        <strong>Gary Braun</strong>: Rhythmus Gitarre, BV
                    </p>
                    <p>
                        <strong>Kris Farrow</strong>: Lead Gitarre
                    </p>
                    <p>
                        <strong>Mark McCoy</strong>: Bass
                    </p>
                    <p>
                        <strong>Shane Vannerson</strong>: Schlagzeug
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2012;
