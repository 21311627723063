import { Stage, Mixer, Entrance, Block } from '.';
import { Corridor } from './Corridor';
import {
    AreaDto,
    ISeatReservationDto,
    IPlanDto,
    AreaDtoVerticalAlign,
    AreaDtoHorizontalAlign,
} from '../../../clients/services';
import { ISeatDisplayInfo, IBlockDisplayInfo } from '../services/plan-info';

interface PlanProps {
    nrOfDesiredSeats: number;
    plan: IPlanDto;
    blockInfos: IBlockDisplayInfo[] | undefined;
    reservations: ISeatReservationDto[];
    ownReservations: ISeatReservationDto[];
    onClickSeat: (seat: ISeatDisplayInfo) => void;
    devMode: boolean;
}

export const Plan = (props: PlanProps) => {
    const getRowStart = (s: AreaDto | undefined) => {
        if (s && s.row && s.row > 1) {
            return s.row;
        }
        return 1;
    };

    const getRowSpan = (s: AreaDto | undefined) => {
        if (s && s.rowSpan && s.rowSpan > 1) {
            return s.rowSpan;
        }
        return 1;
    };

    const getRowEnd = (s: AreaDto | undefined) => {
        return getRowStart(s) + getRowSpan(s) - 1;
    };

    const getColumnStart = (s: AreaDto | undefined) => {
        if (s && s.column && s.column > 1) {
            return s.column;
        }
        return 1;
    };

    const getColumnSpan = (s: AreaDto | undefined) => {
        if (s && s.colSpan && s.colSpan > 1) {
            return s.colSpan;
        }
        return 1;
    };

    const getColumnEnd = (s: AreaDto | undefined) => {
        return getColumnStart(s) + getColumnSpan(s) - 1;
    };

    const getNrOfRows = () => {
        const plan = props.plan;
        let nrRows = getRowEnd(plan.stage);
        plan.entrances?.map((e) => {
            const entranceEnd = getRowStart(e);
            if (entranceEnd > nrRows) {
                nrRows = entranceEnd;
            }
            return true;
        });
        plan.corridors?.map((c) => {
            const corridorEnd = getRowEnd(c);
            if (corridorEnd > nrRows) {
                nrRows = corridorEnd;
            }
            return true;
        });
        plan.blocks?.map((b) => {
            const blockEnd = getRowEnd(b.area);
            if (blockEnd > nrRows) {
                nrRows = blockEnd;
            }
            return true;
        });
        const mixerEnd = getRowEnd(plan.mixer);
        if (mixerEnd > nrRows) {
            nrRows = mixerEnd;
        }
        return nrRows;
    };

    const getNrOfColumns = () => {
        const plan = props.plan;
        let nrColumns = getColumnEnd(plan.stage);
        plan.entrances?.map((e) => {
            const entranceEnd = getColumnStart(e);
            if (entranceEnd > nrColumns) {
                nrColumns = entranceEnd;
            }
            return true;
        });
        plan.corridors?.map((c) => {
            const corridorEnd = getColumnEnd(c);
            if (corridorEnd > nrColumns) {
                nrColumns = corridorEnd;
            }
            return true;
        });
        plan.blocks?.map((b) => {
            const blockEnd = getColumnEnd(b.area);
            if (blockEnd > nrColumns) {
                nrColumns = blockEnd;
            }
            return true;
        });
        const mixerEnd = getColumnEnd(plan.mixer);
        if (mixerEnd > nrColumns) {
            nrColumns = mixerEnd;
        }

        return nrColumns;
    };

    const createRows = () => {
        const numberOfRows = getNrOfRows();
        const numberOfColumns = getNrOfColumns();
        const rows: JSX.Element[] = [];
        for (let rowNr = 1; rowNr <= numberOfRows; rowNr++) {
            const row: JSX.Element[] = [];
            for (let columnNr = 1; columnNr <= numberOfColumns; columnNr++) {
                const block = createBlock(rowNr, columnNr);
                if (block) {
                    row.push(block);
                }
            }
            rows.push(
                <tr className="plan-grid-row" key={'row-' + rowNr}>
                    {row}
                </tr>,
            );
        }
        return rows;
    };

    const startsAt = (s: AreaDto | undefined, rowNr: number, columnNr: number) => {
        return getRowStart(s) === rowNr && getColumnStart(s) === columnNr;
    };

    const getHorizontalAlign = (area: AreaDto | undefined): 'left' | 'right' | 'center' => {
        if (!area) {
            return 'center';
        }
        switch (area.horizontalAlign) {
            case AreaDtoHorizontalAlign.Left:
                return 'left';
            case AreaDtoHorizontalAlign.Right:
                return 'right';
            case AreaDtoHorizontalAlign.Center:
                return 'center';
            default:
                return 'center';
        }
    };

    const getVerticalAlign = (area: AreaDto | undefined): 'top' | 'bottom' | 'middle' => {
        if (!area) {
            return 'middle';
        }
        switch (area.verticalAlign) {
            case AreaDtoVerticalAlign.Top:
                return 'top';
            case AreaDtoVerticalAlign.Bottom:
                return 'bottom';
            case AreaDtoVerticalAlign.Middle:
                return 'middle';
            default:
                return 'middle';
        }
    };

    const getHorizontalDivAlign = (area: AreaDto | undefined): 'left' | 'right' | 'none' => {
        if (!area) {
            return 'none';
        }
        switch (area.horizontalAlign) {
            case AreaDtoHorizontalAlign.Left:
                return 'left';
            case AreaDtoHorizontalAlign.Right:
                return 'right';
            default:
                return 'none';
        }
    };

    const getStyle = (area: AreaDto | undefined) => {
        return {
            textAlign: getHorizontalAlign(area),
            verticalAlign: getVerticalAlign(area),
        };
    };

    const createBlockAreaNr = (area: AreaDto): JSX.Element | undefined => {
        return (
            <div>
                R{area.row}/C{area.column} - RS{getColumnSpan(area)}/CS{getRowSpan(area)}
            </div>
        );
    };

    const createBlock = (rowNr: number, columnNr: number): JSX.Element | undefined => {
        const plan = props.plan;
        if (!plan.stage && rowNr === 1 && columnNr === 1) {
            return (
                <td key={'block:row-' + rowNr + '/column-' + columnNr} className="plan-grid-block stage">
                    <Stage></Stage>
                </td>
            );
        }

        if (plan.stage && startsAt(plan.stage, rowNr, columnNr)) {
            const colSpan = getColumnSpan(plan.stage);
            const rowSpan = getRowSpan(plan.stage);
            return (
                <td
                    key={'block:row-' + rowNr + '/column-' + columnNr}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    className="plan-grid-block stage"
                    style={getStyle(plan.stage)}
                >
                    {props.devMode && createBlockAreaNr(plan.stage)}
                    <Stage width={plan.stage.contentWidth ?? '100%'} align={getHorizontalDivAlign(plan.stage)}></Stage>
                </td>
            );
        }
        if (plan.stage && isAt(plan.stage, rowNr, columnNr)) {
            return <></>;
        }

        if (plan.mixer && startsAt(plan.mixer, rowNr, columnNr)) {
            const colSpan = getColumnSpan(plan.mixer);
            const rowSpan = getRowSpan(plan.mixer);
            return (
                <td
                    key={'block:row-' + rowNr + '/column-' + columnNr}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    className="plan-grid-block mixer"
                    style={getStyle(plan.mixer)}
                >
                    {props.devMode && createBlockAreaNr(plan.mixer)}
                    <Mixer width={plan.mixer.contentWidth} align={getHorizontalDivAlign(plan.mixer)}></Mixer>
                </td>
            );
        }
        if (plan.mixer && isAt(plan.mixer, rowNr, columnNr)) {
            return undefined;
        }

        const entrance = plan.entrances?.find((e) => isAt(e, rowNr, columnNr));
        if (entrance) {
            return (
                <td
                    key={'block:row-' + rowNr + '/column-' + columnNr}
                    className="plan-grid-block entrance"
                    style={getStyle(entrance)}
                >
                    {props.devMode && createBlockAreaNr(entrance)}
                    <Entrance label={entrance.comment}></Entrance>
                </td>
            );
        }

        if (plan.blocks.filter((c) => startsAt(c.area, rowNr, columnNr)).length > 0) {
            const block = plan.blocks.filter((c) => startsAt(c.area, rowNr, columnNr))[0];
            const blockInfo = props.blockInfos?.find((b) => b.blockId === block.id);
            if (!blockInfo) {
                return <></>;
            }
            const colSpan = getColumnSpan(block.area);
            const rowSpan = getRowSpan(block.area);
            return (
                <td
                    key={'block:row-' + rowNr + '/column-' + columnNr}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    className="plan-grid-block seats"
                    style={getStyle(block.area)}
                >
                    <div style={{ float: getHorizontalDivAlign(block.area) }}>
                        {props.devMode && block.area && createBlockAreaNr(block.area)}
                        <Block
                            block={block}
                            blockInfo={blockInfo}
                            reservations={props.reservations}
                            ownReservations={props.ownReservations}
                            onClickSeat={props.onClickSeat}
                            devMode={props.devMode}
                        ></Block>
                    </div>
                </td>
            );
        }
        if (plan.blocks.filter((b) => isAt(b.area, rowNr, columnNr)).length > 0) {
            return undefined;
        }

        if (plan.corridors && plan.corridors?.filter((c) => isAt(c, rowNr, columnNr)).length > 0) {
            const devStrings = [];
            if (props.devMode) {
                for (const corridor of plan.corridors.filter((c) => isAt(c, rowNr, columnNr))) {
                    devStrings.push(createBlockAreaNr(corridor));
                }
            }
            return (
                <td key={'block:row-' + rowNr + '/column-' + columnNr} className="plan-grid-block corridor">
                    {props.devMode && <>{devStrings}</>}
                    <Corridor></Corridor>
                </td>
            );
        }
        return <td key={'block:row-' + rowNr + '/column-' + columnNr} className="plan-grid-block empty"></td>;
    };

    const isAt = (s: AreaDto | undefined, rowNr: number, columnNr: number) => {
        return (
            getRowStart(s) <= rowNr &&
            getRowEnd(s) >= rowNr &&
            getColumnStart(s) <= columnNr &&
            getColumnEnd(s) >= columnNr
        );
    };

    const rows = createRows();
    return (
        <div className={'plan-container' + (props.devMode ? ' dev' : '')} style={{ marginTop: '40px' }}>
            <table className={'plan-grid'}>
                <tbody>{rows}</tbody>
            </table>
        </div>
    );
};
