import { IAmountReductionDto, IConcertDto, ISaleOptionDto, SaleOptionDto } from '../clients/services';

interface IReservation {
    seat: number;
    row: number;
    blockId: number;
}

export class OptionsService {
    constructor(private readonly concert: IConcertDto) {}
    sortByNrOfTickets(a: IAmountReductionDto, b: IAmountReductionDto) {
        if (a.numberOfTickets < b.numberOfTickets) {
            return -1;
        }
        if (a.numberOfTickets > b.numberOfTickets) {
            return 1;
        }
        return 0;
    }

    getFullPrice(reservations: { blockId: number; row: number; seat: number; options?: SaleOptionDto[] }[]) {
        return reservations
            .map((r) => this.getTicketPrice(r, reservations.length))
            .reduce((partialSum, a) => partialSum + a, 0);
    }

    getTicketPrice(
        seatReservation: { blockId: number; row: number; seat: number; options?: SaleOptionDto[] },
        numberOfTickets: number,
    ) {
        if (seatReservation.options && seatReservation.options?.length > 0) {
            return seatReservation.options.reduce((partialSum, o) => partialSum + o.price, 0);
        }

        const defaultOption = this.getDefaultOptionForSeat(seatReservation, numberOfTickets);
        return defaultOption.price;
    }

    getReduction(nrOfSeats: number) {
        if (this.concert.saleOptions?.amountReductions && this.concert.saleOptions.amountReductions.length > 0) {
            return this.concert.saleOptions?.amountReductions
                .filter((r) => r.numberOfTickets <= nrOfSeats)
                .sort(this.sortByNrOfTickets)[0];
        }
        return null;
    }

    getRecalculatedOptions(
        seatReservation: {
            blockId: number;
            row: number;
            seat: number;
            options?: SaleOptionDto[];
        },
        numberOfReservations: number,
    ): SaleOptionDto[] {
        const options =
            seatReservation.options && seatReservation.options?.length > 0
                ? this.getSelectableOptions(seatReservation, numberOfReservations).filter(
                      (o) => seatReservation.options && seatReservation.options.some((ro) => ro.id === o.id),
                  )
                : [this.getDefaultOptionForSeat(seatReservation, numberOfReservations)];

        return options;
    }

    recalculateOption(option: ISaleOptionDto, numberOfReservations: number): SaleOptionDto {
        if (option.isReducable) {
            const reduction = this.getReduction(numberOfReservations);
            if (reduction?.reduction && reduction.reduction !== 0) {
                return new SaleOptionDto({
                    ...option,
                    price: option.price - Math.round(option.price * reduction.reduction),
                    reduction,
                });
            }
        }

        return new SaleOptionDto(option);
    }

    getSelectableOptions(
        seatReservation: { blockId: number; row: number; seat: number },
        numberOfReservations: number,
    ): SaleOptionDto[] {
        if (!this.concert.saleOptions) {
            const defaultOption = this.recalculateOption(
                OptionsService.createDefaultOption(this.concert.price ?? 50),
                numberOfReservations,
            );
            return [defaultOption];
        }

        const options: SaleOptionDto[] =
            this.concert.saleOptions?.options?.filter((o) => this.matchesDependency(seatReservation, o)) ?? [];
        if (options.filter((o) => !o.isAdditional).length > 0) {
            return options.map((o) => this.recalculateOption(o, numberOfReservations));
        }

        const defaultOption = this.getDefaultOptionForSeat(seatReservation, numberOfReservations);
        options.push(this.recalculateOption(defaultOption, numberOfReservations));
        return options;
    }

    matchesDependency(reservation: IReservation, option: ISaleOptionDto) {
        if (!option.dependencies) {
            return true;
        }

        const blockDeps = option.dependencies.filter((d) => d.block === reservation.blockId);
        if (blockDeps.length === 0) {
            return false;
        }

        if (blockDeps.some((d) => !d.rows || d.rows.length === 0)) {
            return true;
        }

        const rowDeps = blockDeps
            .filter((d) => !!d.rows)
            .flatMap((d) => d.rows!)
            .filter((r) => r.row === reservation.row);
        if (rowDeps.length === 0) {
            return false;
        }

        if (rowDeps.some((r) => !r.seats || r.seats.length === 0)) {
            return true;
        }

        const seatDeps = rowDeps
            .filter((r) => !!r.seats)
            .flatMap((r) => r.seats!)
            .filter((s) => s === reservation.seat);
        return seatDeps.length > 0;
    }

    getDefaultOptionForSeat(reservation: IReservation, numberOfSeats: number) {
        const defaultOption = this._getDefaultOptionForSeat(reservation);
        return this.recalculateOption(defaultOption, numberOfSeats);
    }

    private _getDefaultOptionForSeat(reservation: IReservation) {
        const dependentOptions =
            this.concert.saleOptions?.options?.filter(
                (o) => !o.isAdditional && this.matchesDependency(reservation, o),
            ) ?? [];

        if (dependentOptions.length > 0) {
            const withSeat = dependentOptions.filter((s) =>
                s.dependencies?.some(
                    (d) =>
                        d.block === reservation.blockId &&
                        d.rows &&
                        d.rows.some(
                            (r) => r.row === reservation.row && r.seats && r.seats.some((s) => s === reservation.seat),
                        ),
                ),
            );
            if (withSeat.length > 0) {
                return withSeat.length === 1 ? withSeat[0] : withSeat.find((w) => w.isDefault) ?? withSeat[0];
            }

            const withRow = dependentOptions.filter((s) =>
                s.dependencies?.some(
                    (d) =>
                        d.block === reservation.blockId &&
                        d.rows &&
                        d.rows.some((r) => r.row === reservation.row && (!r.seats || r.seats.length === 0)),
                ),
            );
            if (withRow.length > 0) {
                return withRow.length === 1 ? withRow[0] : withRow.find((w) => w.isDefault) ?? withRow[0];
            }

            const withBlock = dependentOptions.filter((s) =>
                s.dependencies?.some((d) => d.block === reservation.blockId && (!d.rows || d.rows.length === 0)),
            );

            if (withBlock.length > 0) {
                return withBlock.length === 1 ? withBlock[0] : withBlock.find((w) => w.isDefault) ?? withBlock[0];
            }
        }

        if (this.concert.saleOptions?.options?.some((o) => o.isDefault)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const defaultOption = this.concert.saleOptions.options.find((o) => o.isDefault)!;
            return defaultOption;
        }

        const selectableMainOptions =
            this.concert.saleOptions?.options?.filter(
                (o) => !o.isAdditional && (!o.dependencies || o.dependencies.length === 0),
            ) ?? [];
        if (selectableMainOptions.length > 0) {
            return selectableMainOptions[0];
        }

        const defaultOption = new SaleOptionDto();
        defaultOption.price = this.concert.price ?? 50;
        return defaultOption;
    }

    private static createDefaultOption(price: number) {
        const defaultOption = new SaleOptionDto();
        defaultOption.isAdditional = false;
        defaultOption.isDefault = true;
        defaultOption.isReducable = true;
        defaultOption.text = 'Eintritt';
        defaultOption.id = 0;
        defaultOption.price = price;
        return defaultOption;
    }
}
