import React from 'react';
import { IConcertDto, IConcertSeriesDto } from '../../clients/services';

type Props = {
    concert: IConcertDto;
    concertSeries?: IConcertSeriesDto;
};

export const Links: React.FC<Props> = (props) => {
    const seriesLinks = props.concertSeries?.links ?? [];
    const artistLinks = props.concert.artists?.flatMap((a) => a.links ?? []) ?? [];
    const locationLinks = props.concert.location?.links ?? [];
    const links = [...seriesLinks, ...artistLinks, ...locationLinks];
    const unique = [...new Set(links.map((item) => item.url))];
    const filteredLinks = [];
    for (const link of unique) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filteredLinks.push(links.find((l) => l.url === link)!);
    }
    return (
        <>
            {filteredLinks.length > 0 && (
                <ul
                    style={{
                        marginTop: '15px',
                        marginBottom: '10px',
                    }}
                >
                    {filteredLinks.map((l, k) => {
                        return (
                            <li style={{ marginLeft: 0, padding: 0 }} key={k}>
                                <a href={l.url} target="_blank" rel="noopener noreferrer">
                                    {l.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            )}
        </>
    );
};
