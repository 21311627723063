import { Grid } from '@mui/material';
import React from 'react';
import { actionContainerStyle } from '../styles';
import { OptionsService } from '../../../services/price-calculator';
import { SeatReservationInfoView } from './SeatReservationInfoView';
import { ISeatSelection } from '../services/seat-selection';
import { ISaleOptionDto } from '../../../clients/services';

interface ReservationProps {
    numberOfSeats: number;
    reservations: ISeatSelection[];
    optionsService: OptionsService;
    countReservations: number;
    changeOptions?: (reservation: ISeatSelection, options: ISaleOptionDto[]) => void | undefined;
}

export const Reservations: React.FC<ReservationProps> = ({
    reservations,
    numberOfSeats,
    optionsService,
    countReservations,
    changeOptions,
}) => {
    return (
        <div style={{ ...actionContainerStyle }}>
            <Grid container>
                {reservations && reservations.length > 0 ? (
                    <>
                        <Grid item xs={12}>
                            <b style={{ color: '#000000' }}>
                                {numberOfSeats === 1 && numberOfSeats === reservations.length && <>Dein Sitz:</>}
                                {numberOfSeats > 1 && numberOfSeats === reservations.length && (
                                    <>Deine {numberOfSeats} Sitze:</>
                                )}
                                {numberOfSeats > 1 && numberOfSeats !== reservations.length && (
                                    <>
                                        Du hast {reservations.length} von {numberOfSeats} Sitzen gewählt.
                                    </>
                                )}
                            </b>
                        </Grid>
                        {reservations.map((r) => (
                            <SeatReservationInfoView
                                key={`${r.blockId}-${r.row}-${r.seat}`}
                                reservation={r}
                                countReservations={countReservations ?? reservations.length}
                                optionsService={optionsService}
                                changeOptions={changeOptions}
                            />
                        ))}
                        <Grid item xs={12} style={{ color: 'black', textAlign: 'right' }}>
                            <span
                                style={{
                                    borderBottom: '1px solid black',
                                    width: 100,
                                }}
                            >
                                <b>Gesamtpreis</b>: CHF {optionsService.getFullPrice(reservations).toFixed(0)}
                                .-
                            </span>
                            <span
                                style={{
                                    borderBottom: '1px solid black',
                                    width: 100,
                                    height: 6,
                                }}
                            >
                                {' '}
                            </span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12}>
                        <b style={{ color: '#000000' }}>
                            <>Bitte wähle deine Sitzplätze aus.</>
                        </b>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
