import React, { useEffect, useState } from 'react';
import { ReservationService } from '../../services';
import { MainContent } from '../../components/MainContent';
import { DateTime } from 'luxon';
import { IConcertDto, OrderBy, OrderBy2, DownloadControllerClient, IConcertSeriesDto } from '../../clients/services';
import { Button, FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import fileDownloader from '../../services/file-downloader';
import { actionContainerStyle } from '../Tickets/styles';
import { useAuth } from '../../auth/auth-provider';
import { DateTimeUtils } from 'cm-utils';
import { ConcertName } from '../../components/ConcertPreview/ConcertName';

const service = new ReservationService();

export const Export: React.FC = (props) => {
    const { profile, hasRole } = useAuth();

    const [hasError, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [concerts, setConcerts] = useState<IConcertDto[]>([]);
    const [concertSeries, setConcertSeries] = useState<IConcertSeriesDto[]>([]);
    const [concertSeriesId, setConcertSeriesId] = useState<number>(0);
    const [concertId, setConcertId] = useState<number>(0);
    const [dataType, setDataType] = useState<string>('orders');
    const [ordersOrderBy, setOrdersOrderBy] = useState<string>('name');
    const [reservationsOrderBy, setReservationsOrderBy] = useState<string>('bySeat');
    const [desc, setDesc] = useState<boolean>(false);
    const [isAllowed, setIsAllowed] = useState<boolean>(false);

    const onSubmit = async () => {
        if (dataType === 'orders') {
            if (concertId !== 0) {
                await fileDownloader.downloadFile(
                    DownloadControllerClient,
                    async (client) => {
                        await client.getOrdersForConcertCSV(concertId, OrderBy2.Name, desc);
                    },
                    'orders-' + DateTimeUtils.formatStore(DateTime.local()),
                    true,
                );
            } else {
                await fileDownloader.downloadFile(
                    DownloadControllerClient,
                    async (client) => {
                        await client.getAllOrdersCSV(OrderBy.Name, desc);
                    },
                    'orders-' + DateTimeUtils.formatStore(DateTime.local()),
                    true,
                );
            }
        } else {
            if (concertId !== 0) {
                await fileDownloader.downloadFile(
                    DownloadControllerClient,
                    async (client) => {
                        await client.getReservationsForConcertCSV(concertId, reservationsOrderBy === 'byName', desc);
                    },
                    'reservations-' + DateTimeUtils.formatStore(DateTime.local()),
                    true,
                );
            } else {
                await fileDownloader.downloadFile(
                    DownloadControllerClient,
                    async (client) => {
                        await client.getAllReservationsCSV(reservationsOrderBy === 'byName', desc);
                    },
                    'reservations-' + DateTimeUtils.formatStore(DateTime.local()),
                    true,
                );
            }
        }
    };

    const loadConcerts = async (id: number) => {
        setLoading(true);
        try {
            const concerts = await service.getConcertsForSeries(id);
            setConcerts(concerts);
        } catch (e) {
            alert(e);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const loadConcertSeries = async () => {
        setLoading(true);
        try {
            const series = await service.getConcertSeries();
            setConcertSeries(series);
        } catch (e) {
            alert(e);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const downloadExcel = async () => {
        if (concertSeriesId === 0) {
            return;
        }

        await fileDownloader.downloadFile(
            DownloadControllerClient,
            async (client) => {
                await client.getExcelExportForSeries(concertSeriesId);
            },
            'bestellungen-' + DateTimeUtils.formatStore(DateTime.local()) + '.xlsx',
            true,
        );
    };

    useEffect(() => {
        const loadData = async () => {
            const hasRoleAdmin = hasRole(profile, 'admin');
            setIsAllowed(hasRoleAdmin);
            if (hasRoleAdmin) {
                await loadConcertSeries();
                if (concertSeriesId) {
                    await loadConcerts(concertSeriesId);
                    return;
                }
            }
            setConcerts([]);
        };
        loadData();
    }, [profile, concertSeriesId, hasRole]);

    const changeConcert = (event: SelectChangeEvent<number>) => {
        const concertId = parseInt(event.target.value as string);
        setConcertId(concertId);
    };

    const changeConcertSeries = (event: SelectChangeEvent<number>) => {
        const newValue = parseInt(event.target.value as string);
        setConcertSeriesId(newValue);
    };

    const changeDataType = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDataType(event.target.value);
    };

    const changeOrdersOrderBy = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setOrdersOrderBy(event.target.value);
    };

    const changeReservationsOrderBy = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setReservationsOrderBy(event.target.value);
    };

    const changeDesc = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDesc(event.target.checked);
    };

    const renderConcerts = () => {
        const concertsOptions = [];
        concertsOptions.push(<option value={0}>Alle Konzerte</option>);
        for (const concert of concerts) {
            concertsOptions.push(
                <option value={concert.id}>
                    <ConcertName {...concert} />
                </option>,
            );
        }
        return <>{concertsOptions}</>;
    };

    const renderConcertSeries = () => {
        const seriesOptions = [];
        seriesOptions.push(<option value={0}>Bitte wählen</option>);
        for (const series of concertSeries) {
            seriesOptions.push(<option value={series.id}>{series.title}</option>);
        }
        return <>{seriesOptions}</>;
    };

    return (
        <MainContent>
            {!isAllowed ? (
                <>
                    <div style={{ ...actionContainerStyle, textAlign: 'left', color: 'black' }}>Kein Zugriff.</div>
                </>
            ) : (
                <>
                    <div style={{ ...actionContainerStyle, textAlign: 'left' }}>
                        <FormControl fullWidth style={{ marginBottom: '20px' }}>
                            <InputLabel htmlFor={'concertSeries'}>Konzertserie</InputLabel>
                            <Select
                                native
                                value={concertSeriesId}
                                onChange={changeConcertSeries}
                                inputProps={{
                                    name: 'concertSeries',
                                    id: 'concertSeries-native-simple',
                                }}
                                label="Konzertserie"
                                fullWidth
                            >
                                {renderConcertSeries()}
                            </Select>
                        </FormControl>
                    </div>
                    {!!concertSeriesId && (
                        <div style={actionContainerStyle}>
                            <h2>Excel-Download:</h2>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '400px' }}
                                onClick={downloadExcel}
                            >
                                Download Excel-File
                            </Button>
                        </div>
                    )}
                </>
            )}
        </MainContent>
    );
};
