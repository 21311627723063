import { NavLink } from 'react-router-dom';
import { PageRoutes } from '../page-routes';
import { RightContent } from './RightContent';

export const TicketReservationSide = () => {
    return (
        <RightContent title="Ticket-Reservierung">
            <>
                <p>
                    <NavLink to={PageRoutes.Tickets}>
                        <img
                            style={{ width: 200, marginTop: 10, marginBottom: 10 }}
                            src="/images/tickets.png"
                            alt="Tickets"
                        />
                    </NavLink>
                </p>
                <p>
                    Bitte reserviere die Tickets direkt <NavLink to={PageRoutes.Tickets}>online</NavLink> auf dieser
                    Webseite.
                    <br />
                    <br />
                </p>
                <div className="frame_2 first_frame" style={{ backgroundImage: 'none' }}>
                    <p>
                        <NavLink to={PageRoutes.Tickets}>Online-Reservierung</NavLink>
                    </p>
                </div>
                <div className="frame_2" style={{ backgroundImage: 'none' }}>
                    <p>Telefon +41 (0)79 207 90 19 </p>
                </div>
            </>
        </RightContent>
    );
};

export default TicketReservationSide;
