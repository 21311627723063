import React from 'react';

export const RightContentFrame = ({ title, children }: { title: string; children: React.ReactNode }) => {
    return (
        <div className="frame_2 first_frame" style={{ backgroundImage: 'none' }}>
            <p>
                <div className="color1">{title}</div>
                {children}
            </p>
        </div>
    );
};
