import React from 'react';
import { Button } from '@mui/material';
import { IBlockDisplayInfo, ISeatDisplayInfo } from '../services/plan-info';
import { IPlanDto, ISaleOptionDto, ISeatReservationDto } from '../../../clients/services';
import { Plan } from '../plan';
import { Explanation } from '../parts/Explanation';
import { Reservations } from '../parts/Reservations';
import { RootDiv, actionContainerStyle } from '../styles';
import { OptionsService } from '../../../services/price-calculator';
import { ISeatSelection } from '../services/seat-selection';

type Props = {
    continue: () => void;
    back: () => void;
    numberOfSeats: number;
    plan: IPlanDto;
    desired: ISeatSelection[];
    reserved: ISeatReservationDto[];
    blocks: IBlockDisplayInfo[];
    clickSeat: (seat: ISeatDisplayInfo) => void;
    optionsService: OptionsService;
    changeOptions: (reservation: ISeatSelection, options: ISaleOptionDto[]) => void;
};

export const SelectSeats: React.FC<Props> = (props) => {
    return (
        <>
            <Explanation numberOfSeats={props.numberOfSeats} />
            <Plan
                plan={props.plan}
                blockInfos={props.blocks}
                nrOfDesiredSeats={props.numberOfSeats}
                ownReservations={props.desired}
                reservations={props.reserved}
                onClickSeat={props.clickSeat}
                devMode={false}
            />
            <Reservations
                reservations={props.desired}
                numberOfSeats={props.numberOfSeats}
                optionsService={props.optionsService}
                changeOptions={props.changeOptions}
                countReservations={props.numberOfSeats}
            ></Reservations>
            <div style={{ ...actionContainerStyle, marginTop: 1 }}>
                <RootDiv>
                    <div style={{ display: 'inline' }}>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={props.back}
                            style={{ width: '200px', marginRight: '30px', color: 'black' }}
                        >
                            Zurück
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.continue}
                            style={{ width: '200px' }}
                            disabled={props.desired.length < props.numberOfSeats}
                        >
                            Weiter
                        </Button>
                    </div>
                </RootDiv>
            </div>
        </>
    );
};
