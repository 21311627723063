import React from 'react';
import { ITicketInfoDto } from '../../../clients/services';

type Props = {
    order: ITicketInfoDto;
};

export const PersonTicketInfo: React.FC<Props> = (props) => {
    return (
        <>
            {props.order.tracingData?.contacts?.length ? (
                0 && (
                    <div style={{ marginBottom: 20 }}>
                        Personen:
                        <br />
                        {props.order.tracingData?.contacts?.map((s, k) => (
                            <>
                                <span key={k}>
                                    {s.firstName} {s.lastName}
                                </span>
                                <br />
                            </>
                        ))}
                        <br />
                    </div>
                )
            ) : (
                <div style={{ marginBottom: 20 }}>
                    Bestellung durch:
                    <br />
                    {props.order.person?.firstName} {props.order.person?.lastName}
                </div>
            )}
            <div style={{ marginBottom: 20 }}>
                Sitzplätze:
                <br />
                {props.order.seats.map((s, k) => (
                    <div key={k}>
                        <b>
                            {s.blockName && <>{s.blockName} - </>}
                            Reihe {s.row} / Sitz {s.seat}
                        </b>
                        {s.options && s.options?.length > 0 ? (
                            <ul style={{ listStyleType: 'none' }}>
                                {s.options.map((o, k) => (
                                    <li key={k}>
                                        {o.text} - CHF {o.price.toFixed(0)}.-
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <> - CHF {(s.price > 0 ? s.price : props.order.concert.price ?? 50).toFixed(0)}.-</>
                        )}
                    </div>
                ))}
            </div>
            <div style={{ marginBottom: 20 }}>
                Gesamtpreis:
                <br />
                <b>
                    CHF{' '}
                    {props.order.seats
                        .reduce((sum, s) => sum + (s.price > 0 ? s.price : props.order.concert.price ?? 50), 0)
                        .toFixed(0)}
                    .-
                </b>
            </div>
        </>
    );
};
