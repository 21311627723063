import { ContentItemDtoType, IConcertSeriesDto, ContentItemDto } from '../../clients/services';
import { Paragraph } from './Paragraph';
import parse from 'html-react-parser';

const getParagraphs = (series: IConcertSeriesDto) => {
    const paragraps = [];

    const addDefaultPicture = !series.content?.paragraphs?.some((p) => p.type === ContentItemDtoType.DefaultPicture);
    const addConcertInfo = !series.content?.paragraphs?.some((p) => p.type === ContentItemDtoType.ConcertInfo);
    const addReservationLink = !series.content?.paragraphs?.some(
        (p) => p.type === ContentItemDtoType.TicketReservationLink,
    );

    if (addDefaultPicture) {
        paragraps.push(new ContentItemDto({ type: ContentItemDtoType.DefaultPicture }));
        if (addConcertInfo) {
            paragraps.push(new ContentItemDto({ type: ContentItemDtoType.ConcertInfo }));
            if (addReservationLink) {
                paragraps.push(new ContentItemDto({ type: ContentItemDtoType.TicketReservationLink }));
            }
        }
    }

    for (const p of series?.content?.paragraphs ?? []) {
        paragraps.push(p);
        if (p.type === ContentItemDtoType.DefaultPicture) {
            if (addConcertInfo) {
                paragraps.push(new ContentItemDto({ type: ContentItemDtoType.ConcertInfo }));
                if (addReservationLink) {
                    paragraps.push(new ContentItemDto({ type: ContentItemDtoType.TicketReservationLink }));
                }
            }
        } else if (p.type === ContentItemDtoType.ConcertInfo) {
            if (addReservationLink) {
                paragraps.push(new ContentItemDto({ type: ContentItemDtoType.TicketReservationLink }));
            }
        }
    }

    return paragraps;
};

export const ConcertPreview = (props: IConcertSeriesDto) => {
    const paragraphs = getParagraphs(props);

    return (
        <div className="konzert">
            <div>
                <h2>{parse(props.title)}</h2>
                {props.content?.introduction && <h3>{parse(props.content.introduction)}</h3>}
                {paragraphs?.map((p, k) => (
                    <Paragraph key={k} p={p} series={props} />
                ))}
                <hr />
            </div>
        </div>
    );
};

export default ConcertPreview;
