import ConcertPreview from '../../components/ConcertPreview/ConcertPreview';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { ReservationService } from '../../services';
import { useEffect, useState } from 'react';
import { ConcertDto, IConcertSeriesDto } from '../../clients/services';
import { LinearProgress } from '@mui/material';

const service = new ReservationService();

export const DynamicArchive = ({ year }: { year: number }) => {
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [wasLoaded, setWasLoaded] = useState<boolean>(false);
    const [series, setSeries] = useState<IConcertSeriesDto[]>([]);
    const [sliderImages, setSliderImages] = useState<{ original: string; originalTitle: string }[]>([]);

    const loadConcerts = async () => {
        setLoading(true);
        try {
            const loadedSeries = await service.getConcertSeriesForArchive(year);
            const seriesToShow = loadedSeries.filter((s) => !s.hide);

            for (const serie of seriesToShow) {
                const concertForSeries = await service.getConcertsForSeries(serie.id);
                serie.concerts = concertForSeries.map((c) => new ConcertDto(c));
            }

            setWasLoaded(true);
            setSeries(seriesToShow);

            setSliderImages(
                seriesToShow
                    ?.filter((i) => !!i.banner && i.banner.url)
                    .map((i) => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        return { original: i.banner!.url!, originalTitle: i.title };
                    }),
            );
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadConcerts();
    }, []);

    return (
        <>
            {isLoading ? (
                <LinearProgress></LinearProgress>
            ) : (
                <>
                    {sliderImages?.length > 0 && (
                        <div
                            style={{
                                width: 670,
                                height: 315,
                                marginBottom: 40,
                                borderBottom: '6px solid #ae3721',
                                borderRadius: '6px',
                                borderTop: '3px solid #8eaee1',
                            }}
                            className="image_wrapper image-border"
                        >
                            <ImageGallery
                                items={sliderImages}
                                showFullscreenButton={false}
                                autoPlay={true}
                                showNav={true}
                                showThumbnails={true}
                                showBullets={false}
                                slideInterval={6000}
                                showPlayButton={false}
                            />
                        </div>
                    )}
                    {series?.map((c, key) => (
                        <ConcertPreview key={key} {...c}></ConcertPreview>
                    ))}
                </>
            )}
        </>
    );
};

export default DynamicArchive;
