import { NavLink } from 'react-router-dom';
import { PageRoutes } from '../page-routes';

export const Navigation = () => {
    return (
        <div id="header_2">
            <div id="menutop">
                <div className="menutop_main">
                    <NavLink to={PageRoutes.Welcome}>AKTUELL</NavLink>
                </div>
                <div className="menutop_main">
                    <NavLink to={PageRoutes.Archive}>R&Uuml;CKBLICK KONZERTE</NavLink>
                </div>
                <div className="menutop_main">
                    <NavLink to={PageRoutes.Tickets}>TICKETS</NavLink>
                </div>
                <div className="menutop_main">
                    <NavLink to={PageRoutes.Playlists}>MARTINS PLAYLISTS</NavLink>
                </div>
                <div className="menutop_main">
                    <NavLink to={PageRoutes.Videos}>VIDEOS</NavLink>
                </div>
                <div className="menutop_main">
                    <NavLink to={PageRoutes.Links}>LINKS</NavLink>
                </div>
            </div>
        </div>
    );
};
