import { IContentItemDto } from '../../clients/services';
import { correctPic } from '../../helpers/correct-pic';

export const Audio = (p: IContentItemDto) => (
    <>
        {p.url && (
            <div style={{ marginTop: 20 }}>
                <p style={{ marginBottom: 10 }}>{p.title}</p>
                <audio controls src={correctPic(p.url)}>
                    Your browser does not support the audio element.
                </audio>
            </div>
        )}
    </>
);
