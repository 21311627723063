import React from 'react';
import { IConcertDto, IConcertSeriesDto } from '../../clients/services';
import { correctPic } from '../../helpers/correct-pic';
import { ConcertName } from './ConcertName';
import { Links } from './Links';
import { ConcertData } from './ConcertData';
import { Grid } from '@mui/material';
import { ConcertMessages } from './ConcertMessages';

type Props = {
    concert: IConcertDto;
    concertSeries?: IConcertSeriesDto;
};

export const DetailedConcertInfo: React.FC<Props> = (props) => {
    return (
        <>
            <Grid container className="frame_1" style={{ textAlign: 'left', paddingRight: 25, width: 950 }}>
                <Grid item xs={12} className="frame_2">
                    <span className="color1">
                        <b>KONZERT: </b>
                    </span>
                    <b>{props.concert.title?.toUpperCase()}</b>
                </Grid>
                {props.concertSeries?.picture?.url && (
                    <Grid item xs={4}>
                        <img
                            className="image_border"
                            src={correctPic(props.concertSeries.picture.url)}
                            alt={props.concertSeries.picture.title}
                            style={{ maxWidth: '275px', marginTop: 20 }}
                        />
                    </Grid>
                )}
                <Grid item xs={props.concertSeries?.picture?.url ? 8 : 12}>
                    <h3>
                        <b>
                            <ConcertName {...props.concert} />
                        </b>
                    </h3>

                    <b>
                        <ConcertData
                            concert={props.concert}
                            concertSeries={props.concertSeries}
                            showReductions
                            showConcertTitle={false}
                        />
                    </b>
                    <ConcertMessages concert={props.concert} />
                    <Links concert={props.concert} concertSeries={props.concertSeries} />
                </Grid>
            </Grid>
        </>
    );
};
