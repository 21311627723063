import { RestrictionDto, BlockRestrictionDtoSkipRowMode, RestrictionDtoSkipRowMode } from '../../../clients/services';
import { SeatRestriction } from '../models/seat-restriction';

export class RestrictionChecker {
    constructor(private restrictions: RestrictionDto | undefined) {}

    hasRestrictions(): boolean {
        return this.restrictions !== undefined;
    }

    isRestrictionless(blockId: number, row: number): boolean {
        if (!this.hasRestrictions()) {
            return true;
        }
        const blockRestrictions = this.restrictions?.blockRestrictions?.find((b) => b.blockId === blockId);
        if (blockRestrictions && blockRestrictions.blocked) {
            return false;
        }
        if (blockRestrictions && blockRestrictions.noRestriction) {
            return true;
        }
        const rowRestrictions = blockRestrictions?.rowRestrictions?.find((r) => r.row === row);
        if (rowRestrictions && rowRestrictions.noRestriction) {
            return true;
        }

        if (
            !this.restrictions?.emptySeatsInBetween &&
            !this.restrictions?.maxSeats &&
            !this.restrictions?.skipRowMode
        ) {
            return true;
        }

        return false;
    }

    getEmptySeatsInBetween(blockId: number, rowNr: number): number {
        if (this.restrictions) {
            const blockRestrictions = this.restrictions?.blockRestrictions?.find((b) => b.blockId === blockId);
            if (blockRestrictions) {
                if (blockRestrictions.noRestriction) {
                    return 0;
                }
                const rowRestrictions = blockRestrictions.rowRestrictions?.find((r) => r.row === rowNr);
                if (rowRestrictions?.noRestriction) {
                    return 0;
                }
                if (rowRestrictions && rowRestrictions.emptySeatsInBetween !== undefined) {
                    return rowRestrictions.emptySeatsInBetween;
                }
                if (blockRestrictions.emptySeatsInBetween !== undefined) {
                    return blockRestrictions.emptySeatsInBetween;
                }
            }
            return this.restrictions.emptySeatsInBetween ?? 0;
        }

        return 0;
    }

    getMaxSeats(): number | undefined {
        if (this.restrictions && this.restrictions.maxSeats !== 0) {
            return this.restrictions.maxSeats;
        }

        return undefined;
    }

    getExplicitRestriction(reservation: { row: number; seat: number; blockId: number }): SeatRestriction {
        const blockRestrictions = this.restrictions?.blockRestrictions?.find((r) => r.blockId === reservation.blockId);
        if (blockRestrictions) {
            if (blockRestrictions.blocked) {
                return SeatRestriction.BlockIsBlocked;
            }
            if (blockRestrictions.noRestriction) {
                return SeatRestriction.None;
            }
            if (blockRestrictions.blockSeats?.find((s) => s.row === reservation.row && s.seat === reservation.seat)) {
                return SeatRestriction.SeatIsBlocked;
            }
            const rowRestrictions = blockRestrictions.rowRestrictions?.find((r) => r.row === reservation.row);
            if (rowRestrictions?.blockRow) {
                return SeatRestriction.RowIsBlocked;
            }
            if (rowRestrictions?.blockSeats) {
                if (rowRestrictions?.blockSeats?.find((s) => s === reservation.seat)) {
                    return SeatRestriction.SeatIsBlocked;
                }
            }
            if (rowRestrictions?.noRestriction) {
                return SeatRestriction.None;
            }
            if (blockRestrictions.skipRowMode === BlockRestrictionDtoSkipRowMode.Even) {
                return reservation.row % 2 === 0 ? SeatRestriction.RowIsBlocked : SeatRestriction.None;
            }
            if (blockRestrictions.skipRowMode === BlockRestrictionDtoSkipRowMode.UnEven) {
                return reservation.row % 2 === 1 ? SeatRestriction.RowIsBlocked : SeatRestriction.None;
            }
        }
        if (this.restrictions?.skipRowMode === RestrictionDtoSkipRowMode.Even) {
            return reservation.row % 2 === 0 ? SeatRestriction.RowIsBlocked : SeatRestriction.None;
        }
        if (this.restrictions?.skipRowMode === RestrictionDtoSkipRowMode.UnEven) {
            return reservation.row % 2 === 1 ? SeatRestriction.RowIsBlocked : SeatRestriction.None;
        }
        return SeatRestriction.None;
    }

    blockIsBlocked(blockId: number): boolean {
        if (!this.restrictions) {
            return false;
        }
        const blockRestrictions = this.restrictions.blockRestrictions?.find((r) => r.blockId === blockId);
        if (blockRestrictions) {
            return !!blockRestrictions.blocked;
        }
        return false;
    }

    rowIsBlocked(blockId: number, row: number): boolean {
        if (!this.restrictions) {
            return false;
        }
        const blockRestrictions = this.restrictions.blockRestrictions?.find((r) => r.blockId === blockId);
        if (blockRestrictions) {
            if (blockRestrictions.blocked) {
                return true;
            }
            const rowRestrictions = blockRestrictions.rowRestrictions?.find((r) => r.row === row);
            if (rowRestrictions?.blockRow) {
                return true;
            }
        }
        return false;
    }

    isBlocked(
        reservations: { row: number; seat: number; blockId: number }[],
        blockId: number,
        row: number,
        seat: number,
    ): boolean {
        if (this.getExplicitRestriction({ blockId, row, seat }) !== SeatRestriction.None) {
            return true;
        }
        const emptySeatsInBetween = this.getEmptySeatsInBetween(blockId, row);
        if (!emptySeatsInBetween || emptySeatsInBetween === 0) {
            return false;
        }
        const existingReservations = reservations.filter((r) => r.blockId === blockId && r.row === row);
        if (existingReservations.length === 0) {
            return false;
        }

        for (let i = 1; i <= emptySeatsInBetween; i++) {
            if (existingReservations.filter((r) => r.seat === seat - i || r.seat === seat + i).length > 0) {
                return true;
            }
        }
        return false;
    }
}
