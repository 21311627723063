import React, { useState } from 'react';
import { Field, FieldArray, Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import { Grid, Typography } from '@mui/material';
import { TextField } from 'formik-mui';
import { IContactTracingDataDto } from '../../../clients/services';
interface Props {
    onContinue: (tracingData: IContactTracingDataDto) => Promise<void>;
    back: (tracingData: IContactTracingDataDto) => void;
    tracingData: IContactTracingDataDto;
    disabled: boolean;
    namesOnly?: boolean;
}

export const ContactTracingForm: React.FC<Props> = (props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.]{0,1}[(]{0,1}[0]{0,1}[)]{0,1}[-\s./0-9]{8,12}$/;

    const validationSchema = props.namesOnly
        ? Yup.object({
              contacts: Yup.array().of(
                  Yup.object().shape({
                      firstName: Yup.string().required('Bitte gib einen Vornamen für diese Person an.'),
                      lastName: Yup.string().required('Bitte gib deinen Nachnamen für diese Person an.'),
                  }),
              ),
          })
        : Yup.object({
              contacts: Yup.array().of(
                  Yup.object().shape({
                      firstName: Yup.string().required('Bitte gib einen Vornamen für diese Person an.'),
                      lastName: Yup.string().required('Bitte gib deinen Nachnamen für diese Person an.'),
                      city: Yup.string().required('Bitte gib einen Ort an.'),
                      zip: Yup.string().required('Bitte gib eine PLZ an.'),
                      country: Yup.string().required('Bitte gib ein Land an.'),
                      tel: Yup.string()
                          .required('Bitte gib eine Telefonnummer an.')
                          .matches(phoneRegExp, 'Die Telefonnummer ist ungültig'),
                  }),
              ),
          });

    return (
        <Formik
            initialValues={props.tracingData}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                setIsSubmitting(true);
                try {
                    await props.onContinue(values);
                    setIsSubmitting(false);
                } catch {
                    setIsSubmitting(false);
                }
            }}
        >
            {({ values }) => (
                <Form>
                    <FieldArray
                        name="contacts"
                        render={(arrayHelpers) => (
                            <div>
                                {values.contacts.length > 0 &&
                                    values.contacts.map((contact, index) => (
                                        <Grid container key={index} spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={{
                                                        color: 'black',
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    {props.namesOnly
                                                        ? `Name der ${index + 1}. Person:`
                                                        : `Kontaktangaben zur ${index + 1}. Person:`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field
                                                    component={TextField}
                                                    name={`contacts.${index}.firstName`}
                                                    type="text"
                                                    label="Vorname"
                                                    value={contact.firstName}
                                                    disabled={props.disabled}
                                                    fullWidth
                                                    variant="standard"
                                                    style={{ width: '100%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field
                                                    component={TextField}
                                                    name={`contacts.${index}.lastName`}
                                                    type="text"
                                                    label="Nachname"
                                                    disabled={props.disabled}
                                                    fullWidth
                                                    variant="standard"
                                                    style={{ width: '100%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ padding: 5 }}></Grid>
                                            {!props.namesOnly && (
                                                <>
                                                    <Grid item xs={4} style={{ padding: 5 }}>
                                                        <Field
                                                            component={TextField}
                                                            name={`contacts.${index}.tel`}
                                                            type="text"
                                                            label="Telefonnummer"
                                                            fullWidth
                                                            disabled={props.disabled}
                                                            variant="standard"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} style={{ padding: 5 }}>
                                                        <Field
                                                            component={TextField}
                                                            name={`contacts.${index}.street`}
                                                            type="text"
                                                            label="Adresse"
                                                            fullWidth
                                                            disabled={props.disabled}
                                                            variant="standard"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} style={{ padding: 5 }}></Grid>
                                                    <Grid item xs={4} style={{ padding: 5 }}>
                                                        <Field
                                                            component={TextField}
                                                            name={`contacts.${index}.zip`}
                                                            type="text"
                                                            label="PLZ"
                                                            fullWidth
                                                            disabled={props.disabled}
                                                            variant="standard"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} style={{ padding: 5 }}>
                                                        <Field
                                                            component={TextField}
                                                            name={`contacts.${index}.city`}
                                                            type="text"
                                                            label="Ort"
                                                            fullWidth
                                                            disabled={props.disabled}
                                                            variant="standard"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} style={{ padding: 5 }}>
                                                        <Field
                                                            component={TextField}
                                                            name={`contacts.${index}.country`}
                                                            type="text"
                                                            label="Land"
                                                            fullWidth
                                                            disabled={props.disabled}
                                                            variant="standard"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Grid>{' '}
                                                </>
                                            )}
                                        </Grid>
                                    ))}
                            </div>
                        )}
                    />
                    <div style={{ display: 'inline', margin: '20px' }}>
                        <Button
                            variant="contained"
                            color="inherit"
                            style={{ width: '200px', color: 'black' }}
                            onClick={() => props.back(values)}
                        >
                            Zurück
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ marginLeft: '20px', width: '200px' }}
                            disabled={isSubmitting || props.disabled}
                        >
                            Weiter
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
