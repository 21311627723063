import { useState, useEffect } from 'react';
import { TextField, Card, CardContent, CardActions, Button, CardHeader, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Page } from '../components/Page';
import { useAuth } from '../auth/auth-provider';

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [helperText] = useState('');
    const [error] = useState(false);

    const navigate = useNavigate();
    const { profile, login } = useAuth();

    useEffect(() => {
        if (profile?.id) {
            return navigate('/admin');
        }
    }, [profile]);

    useEffect(() => {
        if (email.trim() && password.trim()) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [email, password]);

    const handleLogin = async () => {
        await login({ email, password });
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode === 13 || e.which === 13) {
            isButtonDisabled || handleLogin();
        }
    };

    const myForm = styled('form')({});

    return (
        <Page>
            <form
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: 400,
                    margin: `0 auto`,
                }}
                noValidate
                autoComplete="off"
            >
                <Card
                    style={{
                        marginTop: 20,
                    }}
                >
                    <CardHeader
                        sx={{
                            textAlign: 'center',
                            background: '#212121',
                            color: '#fff',
                        }}
                        title="Login"
                    />
                    <CardContent>
                        <div>
                            <TextField
                                error={error}
                                fullWidth
                                id="emailAddress"
                                type="email"
                                label="Email-Adresse"
                                placeholder="Email-Adresse"
                                margin="normal"
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyPress={(e) => handleKeyPress(e)}
                            />
                            <TextField
                                error={error}
                                fullWidth
                                id="password"
                                type="password"
                                label="Passwort"
                                placeholder="Passwort"
                                margin="normal"
                                helperText={helperText}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyPress={(e) => handleKeyPress(e)}
                            />
                        </div>
                        {error && (
                            <p style={{ color: 'red' }}>
                                Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie ihre Email-Adresse und ihr
                                Passwort.
                            </p>
                        )}
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            sx={{
                                marginTop: 10,
                                flexGrow: 1,
                            }}
                            onClick={() => handleLogin()}
                            disabled={isButtonDisabled}
                        >
                            Login
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </Page>
    );
};

export default Login;
