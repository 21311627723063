export enum ParagraphType {
    Quote,
    Text,
    Subtitle,
}

export interface ArticleParagraphDto {
    id: number;
    text: string;
    type: ParagraphType;
}
