import { IConcertDto } from '../../clients/services';

export const ConcertName = (c: IConcertDto) => (
    <>
        {c.location && c.location.name ? (
            <>
                {c.location.name}, {c.location.city}
            </>
        ) : (
            <>{c.title}</>
        )}
    </>
);
