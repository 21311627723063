import { DateTime } from 'luxon';
import { DateTimeUtils } from 'cm-utils';

export interface IProfile {
    id: number;
    firstName: string;
    lastName: string;
    roles: string[];
}

export interface IToken {
    token: string;
    validUntil: DateTime;
}

export interface IAuthStore {
    getToken(): IToken | null;
    setToken(token: IToken): void;
    getRefreshToken(): IToken | null;
    setRefreshToken(token: IToken): void;
    setProfile(profile: IProfile | null): void;
    getProfile(): IProfile | null;
    clear(): void;
}

export class AuthStore implements IAuthStore {
    private static TokenKey = 'auth-token';
    private static RefreshTokenKey = 'auth-refresh-token';
    private static ProfileKey = 'auth-profile';

    clear(): void {
        localStorage.removeItem(AuthStore.TokenKey);
        localStorage.removeItem(AuthStore.RefreshTokenKey);
        localStorage.removeItem(AuthStore.ProfileKey);
    }

    getToken(): IToken | null {
        const token = this.loadToken(AuthStore.TokenKey);
        if (token && this.checkIsTokenValid(token)) {
            return token;
        }
        return null;
    }

    getRefreshToken(): IToken | null {
        const token = this.loadToken(AuthStore.RefreshTokenKey);
        if (token && this.checkIsTokenValid(token)) {
            return token;
        }
        return null;
    }

    getTokenValidUntil(): DateTime | null {
        return this.getToken()?.validUntil ?? null;
    }

    getProfile(): IProfile | null {
        const token = this.loadToken(AuthStore.TokenKey);
        if (token && this.checkIsTokenValid(token)) {
            const profile = this.loadProfile();
            return profile;
        }
        this.clear();
        return null;
    }

    setToken(token: IToken): void {
        const serialized = JSON.stringify(token);
        localStorage.setItem(AuthStore.TokenKey, serialized);
    }

    setRefreshToken(token: IToken): void {
        const serialized = JSON.stringify(token);
        localStorage.setItem(AuthStore.RefreshTokenKey, serialized);
    }

    setProfile(profile: IProfile | null): void {
        const serialized = JSON.stringify(profile);
        localStorage.setItem(AuthStore.ProfileKey, serialized);
    }

    private loadToken(key: string): IToken | null {
        const tokenObj = localStorage.getItem(key);
        if (tokenObj) {
            const parsed = JSON.parse(tokenObj);
            return { token: parsed.token, validUntil: DateTime.fromISO(parsed.validUntil) } as IToken;
        }
        return null;
    }

    private checkIsTokenValid(token: IToken | null): boolean {
        if (!token) {
            return false;
        }
        return DateTimeUtils.isInTheFuture(token.validUntil);
    }

    private loadProfile(): IProfile | null {
        const profile = localStorage.getItem(AuthStore.ProfileKey);
        if (profile) {
            return JSON.parse(profile) as IProfile;
        }
        return null;
    }
}
