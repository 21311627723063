import React, { useState } from 'react';
import { useFormik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import { AddressModel } from '../models/address.model';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import { InputLabel, FormHelperText, LinearProgress, Grid } from '@mui/material';

interface Props {
    onContinue: (form: AddressModel) => Promise<void>;
    onBack: (form: AddressModel) => void;
    address: AddressModel;
}

interface InputProps {
    field: 'firstName' | 'lastName' | 'street' | 'zip' | 'city' | 'country' | 'email' | 'tel';
    title: string;
    type: 'email' | 'text';
    formik: {
        values: AddressModel;
        errors: FormikErrors<AddressModel>;
        touched: FormikTouched<AddressModel>;
        handleChange: (
            eventOrPath: string | React.ChangeEvent<any>,
        ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
        handleBlur: (eventOrString: any) => void | ((e: any) => void);
    };
    disabled?: boolean;
}

const MyInput: React.FC<InputProps> = (props) => {
    return (
        <FormControl fullWidth error={!!(props.formik.touched[props.field] && props.formik.errors[props.field])}>
            <InputLabel htmlFor={props.field}>{props.title}</InputLabel>
            <Input
                id={props.field}
                value={props.formik.values[props.field]}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                aria-describedby={props.field + '-text'}
                disabled={props.disabled}
                fullWidth
                style={{ marginLeft: 14 }}
            />

            {props.formik.touched[props.field] && props.formik.errors[props.field] ? (
                <FormHelperText id={props.field + '-error-text'}>{props.formik.errors[props.field]}</FormHelperText>
            ) : null}
        </FormControl>
    );
};

export const ReservationForm: React.FC<Props> = (props) => {
    const reservationFormModel = props.address;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.]{0,1}[(]{0,1}[0]{0,1}[)]{0,1}[-\s./0-9]{8,12}$/;

    const formik = useFormik({
        initialValues: {
            firstName: reservationFormModel.firstName,
            lastName: reservationFormModel.lastName,
            street: reservationFormModel.street,
            zip: reservationFormModel.zip,
            city: reservationFormModel.city,
            country: reservationFormModel.country ?? 'Schweiz',
            email: reservationFormModel.email,
            tel: reservationFormModel.tel,
        } as AddressModel,
        validationSchema: Yup.object({
            firstName: Yup.string().required('Bitte gib deinen Vornamen an.'),
            lastName: Yup.string().required('Bitte gib deinen Nachnamen an.'),
            street: Yup.string().required('Bitte gib deine vollständige Postadresse an.'),
            city: Yup.string().required('Bitte gib deine vollständige Postadresse an.'),
            zip: Yup.number().required('Bitte gib deine vollständige Postadresse an.'),
            country: Yup.string().required('Bitte gib deine vollständige Postadresse an.'),
            email: Yup.string()
                .email('Bitte gebe eine gültige Email-Adresse an.')
                .required(
                    'Bitte gebe eine Email-Adresse an, damit ich dir die weiteren Informationen für die Bezahlung senden kann.',
                ),
            tel: Yup.string()
                .required('Bitte gib deine Telefonnummer an.')
                .matches(phoneRegExp, 'Die Telefonnummer ist ungültig'),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            await props.onContinue(values);
            setIsSubmitting(false);
        },
    });

    return (
        <>
            {isSubmitting && <LinearProgress></LinearProgress>}
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item xs={6}>
                        <MyInput
                            formik={formik}
                            field="firstName"
                            title="Vorname"
                            type="text"
                            disabled={isSubmitting}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MyInput
                            formik={formik}
                            field="lastName"
                            title="Nachname"
                            type="text"
                            disabled={isSubmitting}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MyInput formik={formik} field="street" title="Adresse" type="text" disabled={isSubmitting} />
                    </Grid>

                    <Grid item xs={2}>
                        <MyInput formik={formik} field="zip" title="PLZ" type="text" disabled={isSubmitting} />
                    </Grid>

                    <Grid item xs={6}>
                        <MyInput formik={formik} field="city" title="Ort" type="text" disabled={isSubmitting} />
                    </Grid>

                    <Grid item xs={4}>
                        <MyInput formik={formik} field="country" title="Land" type="text" disabled={isSubmitting} />
                    </Grid>

                    <Grid item xs={6}>
                        <MyInput formik={formik} field="email" title="Email" type="email" disabled={isSubmitting} />
                    </Grid>
                    <Grid item xs={6}>
                        <MyInput
                            formik={formik}
                            field="tel"
                            title="Telefon Nummer"
                            type="text"
                            disabled={isSubmitting}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'inline', margin: 20, padding: 10 }}>
                            <Button
                                variant="contained"
                                color="inherit"
                                style={{ width: '200px', color: 'black' }}
                                onClick={() => props.onBack(formik.values)}
                            >
                                Zurück
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{ marginLeft: '20px', width: '200px' }}
                                disabled={isSubmitting}
                            >
                                Weiter
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};
