export const Archive2010 = () => {
    return (
        <>
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2013_clb_01.jpg"
                            alt="Claire Lynch Band"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Stacie Huckeba
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>CLAIRE LYNCH BAND</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Dienstag, 31. August 2010
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.clairelynch.com/" title="Claire Lynch" target="_blank" rel="noreferrer">
                                www.clairelynch.com
                            </a>
                        </p>
                    </div>
                    <h3>Claire Lynch erh&auml;lt IBMA AWARD als FEMALE VOCALIST OF THE YEAR 2010</h3>
                    <p>
                        Claire Lynch hat sich damit gegen Patty Loveless, Allison Krauss, Rhonda Vincent und Sonya
                        Isaacs durchgesetzt. Die IBMA-Awards sind die wichtigsten Auszeichnungen, die die Musikbranche
                        f&uuml;r die Bluegrass-K&uuml;nstlerInnen j&auml;hrlich vergibt.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Claire Lynch gilt schon seit langem als eine der kreativsten K&uuml;nstlerinnen im Bereich der
                        akustischen Musik. Sie ist neben Allison Krauss eine der wenigen Frauen, die die Grenzen der
                        Bluegrass-Musik gesprengt haben. Ihre ausschliesslich akustische Musik umspannt Folk, Bluegrass,
                        Swing, <br />
                        Country, Jazz und Blues. Zahlreiche ihrer Eigenkompositionen wurden von anderen
                        K&uuml;nstlerinnen eingespielt und Gr&ouml;ssen wie Dolly Parton, Emmylou Harris oder Linda
                        Ronstadt engagierten Claire Lynch wegen ihrer grossartigen Stimme f&uuml;r ihre Aufnahmen.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die auf h&ouml;chstem Niveau spielende Claire Lynch Band zelebriert neben kraftvollem sowie
                        virtuosem Bluegrass auch auf agile und filigrane Weise Swing, Cajun und Folk. Im Mittelpunkt
                        steht aber stets das Singer-Songwriter-Genre mit vielen wunderbaren Liedern, die Claire Lynch
                        oft selber geschrieben hat.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Matthew Wingate</strong>: guitar, mandolin, vocals
                        <br />
                        <strong>Mark Schatz</strong>: bass, banjo
                        <br />
                        <strong>Jason Thomas</strong>: mandolin, fiddle, vocals
                        <br />
                        <strong>Claire Lynch</strong>: guitar, lead vocals
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2012_cw_01.jpg"
                            alt="CAROLYN WONDERLAND"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>CAROLYN WONDERLAND</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 25. Juli 2010
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.carolynwonderland.com" target="_blank" rel="noreferrer">
                                www.carolynwonderland.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.myspace.com/carolynwonderland" target="_blank" rel="noreferrer">
                                www.myspace.com/carolynwonderland
                            </a>
                        </p>
                    </div>
                    <h3>&Uuml;berragende Musikalit&auml;t</h3>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Carolyn Wonderland is the real deal! She&apos;s an amazing guitar player. She whistled a
                            solo. She even played the trumpet! And damn, can she sing.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Los Angeles Times
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            A dollop of Janis Joplin, a slice of Stevie Ray Vaughan, and a big load of soulful
                            individuality... that&apos;s Wonderland, a seething-hot Texas singer-guitarist. And she can
                            write, too! Produced by Asleep at the Wheel&apos;s Ray Benson, Miss Understood focuses on
                            tough yet vulnerable blues, but also captures the melodic soul of classic American song...
                            No wonder Dylan is an avowed fan.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Daniel Gewertz, Boston Herald
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            When she blisters the guitar and cocks her head fetchingly to sing her songs like
                            &quot;I&apos;m Innocent&quot; she stands in the good company of Sue Foley, Debbie Davies,
                            and Bonnie Raitt. But when she whistles, as she does with disarming ease on another of her
                            compositions &quot;I&apos;m the Man,&quot; or picks up the trumpet, she&apos;s one of a
                            kind.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Margaret Moser, Austin Chronicle
                            </span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2010_jh_01.jpg"
                            alt="James Hand"
                        />
                    </p>
                    <p className="legendentext">
                        James Hand Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2010_tl_01.jpg"
                            alt="Tracie Lynn"
                        />
                    </p>
                    <p className="legendentext">
                        Tracie Lynn Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>JAMES HAND &amp; TRACIE LYNN</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Montag, 21. Juni 2010
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19.30 Uhr (T&uuml;r&ouml;ffnung 18 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.jamesslimhand.com" target="_blank" rel="noreferrer">
                                www.jamesslimhand.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.myspace.com/jamesslimhand" target="_blank" rel="noreferrer">
                                www.myspace.com/jamesslimhand
                            </a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.tracielynn.com" target="_blank" rel="noreferrer">
                                www.tracielynn.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.myspace.com/tracielynncom" target="_blank" rel="noreferrer">
                                www.myspace.com/tracielynncom
                            </a>
                        </p>
                    </div>
                    <h3>Doppelkonzert von James Hand und Tracie Lynn</h3>
                    <p>
                        James Hand und Tracie Lynn geben ein Doppelkonzert am 21. Juni 2010 und werden von einer
                        fantastischen Band aus Texas begleitet
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>James Hand</strong>
                    </p>
                    <p>
                        Hand geh&ouml;rt seit mehr als 40 Jahren zu den pr&auml;genden Interpreten der Country-Musik in
                        Texas. Country-Legende Willie Nelson sagt &uuml;ber ihn, er ist der &bdquo;echte Deal&quot; und
                        trifft damit den Nagel auf den Kopf. James Hand passt nicht in das glatt polierte
                        Nashville-Gesch&auml;ft, er scheint direkt aus der Vergangenheit auftauchend die Zukunft zu
                        repr&auml;sentieren. Ein eigener Stil, der an Country-&Uuml;bervater Hank Williams erinnert, und
                        die Weigerung sich verbiegen zu lassen von Kommerz und Pop.{' '}
                    </p>
                    <p>
                        <br />
                        Kleine Geschichten, in denen jeder das Eine oder Andere aus seinem Leben wieder erkennen kann,
                        sind die St&auml;rke der Country-Musik. Dank S&auml;ngern wie James Hand wird diese Kunst
                        lebendig gehalten. Letztendlich geh&ouml;rt Musik ja zu den grossen Gef&uuml;hls-Schaffern und
                        die Songs von James Hand treffen zielsicher mitten ins Herz, auch wenn manchmal schon Schatten
                        &uuml;ber dem einstigen Zauber der Liebe liegen.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Tracie Lynn </strong>
                    </p>
                    <p>
                        {' '}
                        Lynn ist eine der vielversprechendsten weiblichen Interpretinnen aus Texas. Zu ihren
                        musikalischen Einfl&uuml;ssen z&auml;hlen Hank Williams, Patty Loveless und Emmylou Harris.
                    </p>
                    <p>
                        <br />
                        Tracie Lynn&rsquo;s Stil ist echt und sehr erfrischend. Ihre Musik hat etwas spezielles, das sie
                        von anderen Interpretinnen unterscheidet. Und dieser Unterschied macht es aus und ber&uuml;hrt.
                        Ihre Melodien bringt man n&auml;mlich einfach nicht aus dem Kopf. Tracie Lynn vermittelt als
                        eine der wenigen K&uuml;nstlerinnen das, was richtige und wirkliche Country Musik ist:
                        brillantes Song-writing, sch&ouml;ner, ausdrucksstarker Gesang, eing&auml;ngige Melodien und
                        erstklassige Begleitung.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Band</strong>
                    </p>
                    <p>
                        {' '}
                        Begleitet werden James Hand und Tracie Lynn von einer fantastischen Band, die f&uuml;r sich
                        alleine schon f&uuml;r ein Konzerthighlight gut w&auml;re.{' '}
                    </p>
                    <p>
                        <br />
                        <strong>Kenny Grimes</strong> (electric guitar) war mit Willie Nelson und The Flatlanders auf
                        Tour und ist ein wahrer Meister am Telecaster. <br />
                        <strong>Gene Kurtz</strong> (bass) sammelte Erfahrungen in diversen Musikrichtungen und
                        pr&auml;gte die Rhythmen einiger bekannter Interpreten.
                        <br />
                        <strong>Herb Belofsky</strong> (drums) war Gr&uuml;ndungsmitglied und Produzent der wundervollen
                        Country-Rock Band Rainravens. <br />
                        <strong>Kurtz und Belofsky</strong> tourten beide zusammen viele jahre mit Dale Watson&apos;s
                        Lone Stars. <br />
                        <strong>Kim Deschamps</strong> (steel guitar) ist ein begnadeter Multiinstumentalist, der mit
                        seinem Spiel den Sound von Blue Rodeo und Cowboy Junkies massgeblich pr&auml;gte.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Also geniessen wir die Musik von James Hand &amp; Tracie Lynn und erz&auml;hlen es allen weiter.
                        Diese hochkar&auml;tigen Musiker aus Texas werden uns bestimmt mehr als einen nachhaltigen
                        Eindruck vermitteln, was echte Country Musik ausmacht. Ein weiteres Konzert-Highlight!
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>James Hand</strong>: vocals, guitar{' '}
                        <em>(Willie Nelson said: &quot;he&apos;s the deal&quot;)</em>
                        <br />
                        <strong>Tracie Lynn</strong>: vocals, guitar{' '}
                        <em>(pure Texas style honky tonk with western and bluegrass overtones) </em>
                        <br />
                        <strong>Kenny Grimes</strong>: electric guitar <em>(an accomplished guitarist) </em>
                        <br />
                        <strong>Gene Kurtz</strong>: bass{' '}
                        <em>(for more than six years member of Dale Watson&apos;s fabulous Lone Stars)</em>
                        <br />
                        <strong>Herb Belofsky</strong>: drums{' '}
                        <em>(for many years member of Dale Watson&apos;s fabulous Lone Stars)</em>
                        <br />
                        <strong>Kim Deschamps</strong>: steel guitar{' '}
                        <em>(a supremely talented world class pedal steel guitarist)</em>
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2010_boh_01.jpg"
                            alt="BOH"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Band of Heathens
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>BAND OF HEATHENS</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Donnerstag, 6. Mai 2010
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.bandofheathens.com"
                                title="Band of Heathens"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.bandofheathens.com
                            </a>
                            <br />
                            <a
                                href="http://www.bandofheathens.de"
                                title="Band of Heathens"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.bandofheathens.de
                            </a>
                        </p>
                        <p>
                            auf facebook:{' '}
                            <a href="https://www.facebook.com/thebandofheathens" target="_blank" rel="noreferrer">
                                www.facebook.com/thebandofheathens
                            </a>
                        </p>
                    </div>
                    <h3>DAS Kronjuwel der Roots-Musik</h3>
                    <p>
                        Was Band Of Heathens so unwiderstehlich macht sind die Qualitäten, die der Band seit ihrer
                        Gründung im Jahr 2005 innewohnen. Damals schlossen sich{' '}
                        <strong>Ed Jurdi, Gordy Quist und Colin Brooks, </strong>die allesamt schon Soloalben
                        veröffentlicht hatten und als eigenständige Singer-Songwriter in Austin arbeiteten, nach einer
                        Reihe von losen Jams im Austiner Club Momos&apos;s fest zusammen. Die geistesverwandten Musiker
                        formierten sich zu einer packenden Rock&apos;n&apos;Roll-Band, ja vielleicht zur
                        herausragendsten Roots Music Band Amerikas, die gleich mit zwei Livealben ihre Bandkarriere
                        startete. Und es sind diese packenden und energiegeladenen Live-Shows, die die Konzertbesucher
                        sowohl in Amerika als auch in Europa begeistern und die Fangemeinde immer grösser werden lässt.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2010_tv_01.jpg"
                            alt="Toni Vescoli und Bänd"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Toni Vescoli
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>TONI VESCOLI &amp; B&Auml;ND</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Donnerstag, 25. M&auml;rz 2010
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.vescoli.ch" target="_blank" rel="noreferrer">
                                www.vescoli.ch
                            </a>
                        </p>
                    </div>
                    <h3>Schweizer Musiker und Singer-Songwriter</h3>
                    <p>
                        Vescoli wuchs in Peru und Küsnacht ZH auf. Seine Musikerkarriere begann bereits während seiner
                        Ausbildung zum Hochbauzeichner. 1962 gründete er die Band Les Sauterelles (frz. &quot;Die
                        Heuschrecken &quot;), mit der er extensiv auf Tournee ging und bis zu 350 Konzerte im Jahr
                        absolvierte. Der Bekanntheitsgrad in der Schweiz war entsprechend hoch, so dass die erste
                        Single, das Instrumentalstück &quot;Hongkong &quot;, auch gleich zu einem Verkaufsschlager
                        wurde. Im gleichen Jahr spielte die Band im Vorprogramm von Cliff Richard & The Shadows.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Vescoli pflegte in seiner gesamten Karriere verschiedene Musikstile, von einem &quot;Schweizer
                        Bob Dylan &quot; bis zu Country-Musik. Auf seinen Soloalben spielen denn auch immer wieder
                        bekannte Gastmusiker mit, so zum Beispiel <strong>Augie Meyers</strong> (von den Texas Tornados)
                        oder
                        <strong>Joe Ely. </strong>Er tritt weiterhin mit den 1996 wiedervereinigten{' '}
                        <strong>Les Sauterelles</strong> oder der Ethno Country Rock Show auf.
                        <br />
                        <span className="color3 quelle" style={{ fontStyle: 'italic' }}>
                            &ndash; Wikipedia
                        </span>
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2010;
