export const Archive2007 = () => {
    return (
        <div className="konzert">
            <div className="image_wrapper">
                <p>
                    <img
                        className="image_border"
                        style={{ width: 166 }}
                        src="/images/konzerte/konzert_2011_tr_01.jpg"
                        alt="TOM RUSSELL"
                    />
                </p>
                <p className="legendentext">
                    Foto&copy;:
                    <br />
                    W&ouml;lfli Imhof
                </p>
            </div>
            <div className="text_image_left">
                <h2>TOM RUSSELL</h2>
                <div className="frame_1">
                    <p>
                        <b>
                            <span className="color1">DATUM:</span> Samstag, 22. Dezember 2007
                            <br />
                            <span className="color1">KONZERTORT:</span> Little Nashville, Liesberg
                        </b>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <a href="http://www.tomrussell.com" target="_blank" rel="noreferrer">
                            www.tomrussell.com
                        </a>
                    </p>
                </div>
                <h3> Gelebte Poesie mit einem der besten Storytellers Amerikas</h3>
                <p>
                    <strong>Tom Russell</strong>: lead vocals, acoustic guitar
                </p>
                <p>
                    {' '}
                    <strong>Mal Thad Beckman</strong>: lead acoustic guitar, mandolin
                </p>
            </div>
        </div>
    );
};

export default Archive2007;
