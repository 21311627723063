export const Archive2019 = () => {
    return (
        <>
            {' '}
            <div className="konzert">
                &nbsp;
                <div>
                    <img
                        style={{ width: '100%', marginBottom: 30 }}
                        src="/images/konzerte/konzert_2019_sst_01.jpg"
                        alt="Scott Southworth Trio"
                    />
                </div>
                <div className="">
                    <h2>SCOTT SOUTHWORTH TRIO (USA)</h2>
                    <div className="frame_1">
                        <div className="frame_2 first_frame">
                            <p>
                                <span className="color1"> ORT:</span>{' '}
                                <b> Kapuzinerkirche, Kapuzinergasse, 4310 Rheinfelden</b>
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Samstag, 19. Oktober 2019
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20.00 Uhr (T&uuml;r&ouml;ffnung 19.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.&ndash;
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.scottsouthworth.com"
                                title="Scott Soouthworth"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.scottsouthworth.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Scott Southworth Trio</h3>
                    <p>
                        Das letzte Album &laquo;Hey Hillbilly Singer&raquo; von Scott Southworth wurde kÜrzlich von der
                        &laquo;Academy of Western Artists Pure Country&raquo; zum Album des Jahres erkoren. Es wurde
                        auch von zahlreichen Kritikern in h&ouml;chsten T&ouml;nen gelobt und es fand in vielen
                        Jahres-Hitlisten Aufnahme.
                    </p>
                    <p>
                        <br />
                        Nachstehend zwei Einsch&auml;tzungen, die sehr gut die Qualit&auml;t von Scott
                        Southworth&rsquo;s Musik beschreiben:{' '}
                    </p>
                    <p>&nbsp; </p>
                    <blockquote>
                        <p>
                            &laquo;If you don&rsquo;t like Scott Southworth, then you don&rsquo;t like country
                            music!&raquo;
                            <br />
                            <span className="color3 quelle">&ndash; Saving Country Music</span>
                        </p>
                    </blockquote>
                    <p>&nbsp; </p>
                    <blockquote>
                        <p>
                            Scott Southworth performs high energy honky tonk songs, Texas swingers, two-steppers, truck
                            stop rockers and tear in your beer ballads that will transport the listener into a dark,
                            neon lit dive bar with sawdust on the dance floor and ice cold long necks in a bucket.
                            <br />
                            <span className="color3 quelle">&ndash; When The Cowboy Sings</span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <p>
                        Scott Southworth explains, &quot;We record fast and loose with the best of the best pickers
                        Nashville has to offer. And given the Pop Country style that&apos;s all the rage in sessions
                        these days, these musicians are energized and excited to dig into the type of music that made
                        them move to Nashville in the first place that enthusiasm shines through in the recordings and
                        raises my vocal performance as well!&quot;
                    </p>
                    <p>&nbsp;</p>
                    <p>Ich kann den Besuch dieses Konzertes allen Country Musik Liebhabern wÄrmstens empfehlen!</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div>
                    <img
                        style={{ width: '100%', marginBottom: 30 }}
                        src="/images/konzerte/konzert_2019_tmr_01.jpg"
                        alt="Thomas Michael Riley"
                    />
                </div>
                <div className="">
                    <h2>THOMAS MICHAEL RILEY (USA)</h2>
                    <div className="frame_1">
                        <div className="frame_2 first_frame">
                            <p>
                                <span className="color1"> ORT:</span> <b> Alts Schlachthuus, Laufen</b>{' '}
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Mittwoch, 1. Mai 2019
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20.00 Uhr (T&uuml;r&ouml;ffnung 19.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.&ndash;
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.thomasmichaelriley.com"
                                title="Thomas Michael Riley"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.thomasmichaelriley.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Ein erstklassiger Liederschreiber und Sänger mit viel Humor und einer tollen Bühnenpräsenz</h3>
                    <p>
                        Thomas Michael Riley wurde in den Jahren 2002 und 2004 als Hill Country Entertainer in Texas
                        ausgezeichnet. Zahlreiche seiner Alben wurden von den Kritikern in den h&ouml;chsten T&ouml;nen
                        gelobt und einige seiner Lieder schafften auch den Sprung in die vielbeachteten Top 10 der Texas
                        Music Charts. Thomas Michael Riley&lsquo;s Fangemeinde w&auml;chst seit Jahren stetig. Bereits
                        legend&auml;r ist sein j&auml;hrlich stattfindendes Music Festival in Luckenbach, Texas, das
                        jeweils zig-tausende Besucher anlockt. Er hat weit &uuml;ber 500 Lieder geschrieben und
                        &uuml;ber 10 Alben ver&ouml;ffentlicht.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Thomas Michael Riley&lsquo;s credo lautet: &laquo;Good music loves good company&raquo;. F&uuml;r
                        ihn ist das Leben ein Geschenk. Also meint Thomas: &laquo;Folge deinem Herzen, lebe im Hier und
                        Jetzt und lache viel &uuml;ber dich selbst!&raquo;.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        F&uuml;r alle, die gerne erstklassige, lebhafte wie auch nachdenkliche Lieder m&ouml;gen, die
                        zudem mit viel Witz, Humor und sch&ouml;nen Melodien daherkommen, empfehle ich den Besuch des
                        Konzertes mit Thomas Michael Riley. Seine Bariton-Stimme und seine B&uuml;hnenpr&auml;senz
                        ziehen die Besucher stets in ihren Bann. Begleitet wird Thomas Michael Riley vom grossartigen
                        Gitarristen Kenny Grimes, vom Bassisten Donnie Price und dem Schlagzeuger Louie Broussard.
                    </p>
                    <h3 className="color2">Thomas Michael Riley on Tour:</h3>
                    <p>
                        <strong>Freitag, 26. April 2019, Biglen</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span className="color1">üffentliches Konzert</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                            href="http://www.baeren-biglen.ch/artikel/2809/Kultur/Veranstaltungen"
                            title="Thomas Michael Riley"
                            target="_blank"
                            rel="noreferrer"
                        >
                            www.baeren-biglen.ch
                        </a>
                    </p>
                    <p>
                        <strong>Samstag, 27. April 2019, B&uuml;rglen</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span className="color1">(PRIVATKONZERT)</span>
                    </p>
                    <p>
                        <strong>Sonntag, 28. April 2019, Annecy (Frankreich)</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span className="color1">Öffentliches Konzert</span>
                    </p>
                    <p>
                        <strong>Dienstag, 30. April 2019, Dittingen</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span className="color1">(PRIVATKONZERT)</span>
                    </p>
                    <p>
                        <strong>Mittwoch, 1. Mai 2019, Laufen</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span className="color1">Öffentliches Konzert</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                            href="http://www.swisstexmusic.ch"
                            title="Thomas Michael Riley"
                            target="_blank"
                            rel="noreferrer"
                        >
                            www.swisstexmusic.ch
                        </a>
                    </p>
                    <p>
                        <strong>Samstag, 4. Mai 2019, Pontivy (Frankreich)</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span className="color1">Öffentliches Konzert</span>
                    </p>
                    <p>
                        <strong>Mittwoch, 8. Mai 2019, Grasswil</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span className="color1">(PRIVATKONZERT)</span>
                    </p>
                    <p>
                        <strong>Freitag, 10. Mai 2019, Ulm (Deutschland)</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span className="color1">Öffentliches Konzert</span>
                    </p>
                    <p>
                        <strong>Samstag, 11. Mai 2019, &Ouml;sterreich</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span className="color1">Öffentliches Konzert</span>
                    </p>

                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div>
                    <img
                        style={{ width: '100%', marginBottom: 30 }}
                        src="/images/konzerte/konzert_2019_kb_01.jpg"
                        alt="Krueger Brothers"
                    />
                </div>
                <div className="">
                    <h2>KRÜGER BROTHERS (CH/USA)</h2>
                    <div className="frame_1">
                        <div className="frame_2 first_frame">
                            <p>
                                <span className="color1"> ORT:</span>{' '}
                                <b>
                                    {' '}
                                    Landgasthof Riehen, Grosser Festsaal
                                    <br />
                                    Baselstrasse 38, Riehen
                                </b>
                                , BS
                            </p>
                            <p>
                                <b>
                                    <a
                                        href="http://www.landgasthof-riehen.ch/salle/salle_de.html"
                                        title="Landgasthof Riehen"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        www.landgasthof-riehen.ch
                                    </a>
                                </b>
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Montag, 13. Mai 2019
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20.00 Uhr (T&uuml;r&ouml;ffnung 19.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 58.&ndash;
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.krugerbrothers.com"
                                title="Krueger Brothers"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <strong>www.krugerbrothers.com</strong>
                            </a>
                        </p>
                    </div>
                    <h3>Einfach genial !</h3>
                    <p>
                        Die in der Schweiz geborenen und seit vielen Jahren in den USA lebenden{' '}
                        <strong>Jens und Uwe Krüger</strong> am Banjo und an der Gitarre, entwickeln seit Jahren
                        zusammen mit ihrem Bassisten <strong>Joel Landsberg</strong> die traditionelle Folk und
                        akustische Musik Amerikas in eine eindrückliche neue Richtung.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Im traditionellen Sinne spielen sie mit unglaublichem und atemberaubendem Tempo. In ihren
                        aktuellen Kompositionen verschmelzen die <strong>Kräger Brothers</strong> einerseits
                        Virtuosität, Originalität und Wertschätzung für die Schönheit der Musik und Kultur der
                        Appalachen, und andererseits bezeugen sie auch ihre Liebe zur klassischen Musik Europas. Jeder
                        einzelne ihrer Songs ist ein Meisterwerk. Umhüllt von ihrer genialen Musikalität, ihrem
                        brillianten Zusammenspiel und vom Gesang mit den klaren ausdrucksstarken Stimmen sind sie in
                        ihrer Form einzigartig.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die <strong>Krüger Brothers</strong> werden uns mit ihrer unbändigen Spielfreude, ihrer
                        beruhigenden Genialität und ihrer spontanen Art wiederum begeistern ! Lasst Euch das nicht
                        entgehen !
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div>
                    <img
                        style={{ width: '100%', marginBottom: 30 }}
                        src="/images/konzerte/konzert_2019_rith_01.jpg"
                        alt="Rob Ickes Trey Hensley"
                    />
                </div>
                <div className="">
                    <h2>ROB ICKES &amp; TREY HENSLEY (USA)</h2>
                    <div className="frame_1">
                        <div className="frame_2 first_frame">
                            <p>
                                <span className="color1"> ORT:</span>{' '}
                                <b>
                                    {' '}
                                    Hotel Sch&uuml;tzen, Rheinfelden | Jazzkeller
                                    <br />
                                    Bahnhofstrasse 19, 4310 Rheinfelden
                                </b>
                            </p>
                            <p>
                                <b>
                                    <a
                                        href="https://www.hotelschuetzen.ch"
                                        title="Hotel Schuetzen Rheinfelden"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        www.hotelschuetzen.ch
                                    </a>
                                </b>
                                <br />
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 26. Mai 2019
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19.30 Uhr (T&uuml;r&ouml;ffnung 18.45
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.&ndash;
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.robandtrey.com"
                                title="Rob Ickes And Trey Hensley"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.robandtrey.com
                            </a>
                        </p>
                    </div>
                    <h3>Einer der angesagtesten Acts in Nashville!</h3>
                    <p>
                        <strong>Rob Ickes</strong> wird von vielen Musikern als Genie und Virtuose bezeichnet. Er ist
                        15-fach preisgekr&ouml;nter Dobro-Spieler des Jahres der IBMA. <strong>Rob Ickes</strong> war
                        auch Gr&uuml;ndungsmitglied der legend&auml;ren Bluegrass Formation &laquo;Blue Highway&raquo;.
                        Sein fl&uuml;ssiger und gef&uuml;hlvoller Stil ist nicht nur auf zahlreichen Alben von Merle
                        Haggard, Alison Krauss, The Cox Family, Tony Rice, Vince Gill, Alan Jackson oder Reba McEntire
                        zu h&ouml;ren. Vielmehr ist <strong>Rob Ickes</strong> auch &laquo;live&raquo; mit einigen
                        dieser genannten K&uuml;nstler aufgetreten.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Seit 2015 spielt <strong>Rob Ickes</strong> zusammen mit dem &auml;usserst talentierten
                        Gitarristen und S&auml;nger <strong>Trey Hensley</strong>. <strong>Hensley</strong> wiederum
                        wurde schon als 11-j&auml;hriger von Marty Stuart eingeladen, mit Marty und Earl Scruggs in der
                        Grand Ole Opry aufzutreten. Seither stand <strong>Trey Hensley</strong> zusammen mit einigen
                        Stars wie Johnny Cash, Charlie Daniels oder Peter Frampton auf der B&uuml;hne.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Rob Ickes &amp; Trey Hensley</strong> sind zwei aussergew&ouml;hnlich begnadete Musiker.
                        Sie sind eine Offenbarung nicht nur f&uuml;r Fans traditioneller Musik. Ihre Liebe zu Bluegrass,
                        Country, Blues, Rock, Western Swing und Jazz ist gegenseitig. Ihr erstes gemeinsames Album
                        &laquo;Before The Sun Goes Down&raquo; wurde f&uuml;r einen Grammy Award nominiert und ihr
                        zweites Album &laquo;The Country Blues&raquo; &uuml;berzeugt sogar durch noch gr&ouml;ssere
                        Vielfalt.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Heute geh&ouml;ren <strong>Rob Ickes &amp; Trey Hensley</strong> in Nashville zu den
                        angesagtesten Acts! Es ist eine einmalige Gelegenheit, einen der besten Dobro-Spieler zusammen
                        mit einem der gr&ouml;ssten Talente aus Nashville auf der B&uuml;hne zu sehen.
                    </p>
                    <h3 className="color2">Weitere Konzerte in der Schweiz:</h3>
                    <p>
                        <strong>Dienstag, 21. Mai 2019</strong> <br />
                        Remise B&uuml;hni, Neuholzweg 1, 3303 Jegenstorf,
                    </p>
                    <p>
                        <span className="color1">Konzert: 20 Uhr, Ticket: CHF 40.&ndash;</span>
                    </p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div>
                    <img
                        style={{ width: '100%', marginBottom: 30 }}
                        src="/images/konzerte/konzert_2019_dw_01.jpg"
                        alt="Dale Watson And His Lone Stars ft Celine Lee"
                    />
                </div>
                <div className="">
                    <h2>DALE WATSON &amp; HIS LONE STARS ft CELINE LEE (USA)</h2>
                    <div className="frame_1">
                        <div className="frame_2 first_frame">
                            <p>
                                <span className="color1"> ORT:</span> <b> Alts Schlachthuus, Laufen</b>{' '}
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Samstag, 15. Juni 2019
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20.00 Uhr (T&uuml;r&ouml;ffnung 19.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.&ndash;
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.dalewatson.com" title="Dale Watson" target="_blank" rel="noreferrer">
                                www.dalewatson.com
                            </a>
                        </p>
                    </div>
                    <h3>Dale Watson der Kultk&uuml;nstler</h3>
                    <p>
                        <strong>He&rsquo;s The Real Deal: Authentischer geht es nicht!</strong>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Es ist mir gelungen, endlich wieder einmal den Kult-K&uuml;nstler <strong>Dale Watson</strong>
                        zu engagieren. <strong>Dale Watson</strong> ist nicht nur ein hervorragender S&auml;nger,
                        sondern auch ein begnadeter Liederschreiber und Gitarrist sowie ein besonderer Entertainer.
                        <strong>Dale Watson</strong> garantiert uns einen Abend lang herrlichste Musik und er wird uns
                        zeigen, warum er seinen Musikstil &laquo;Ameripolitan&raquo; nennt. <br />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Dale Watson</strong> ist ein Mensch mit einem faszinierenden Charisma. Ihn
                        &laquo;live&raquo; zu erleben ist stets etwas Besonderes und seine Musik bleibt einem in guter
                        Erinnerung. <strong>Dale Watson</strong> hat immer bewusst den harten und steinigen Weg im
                        Musikgesch&auml;ft genommen und er l&auml;sst auch keine Zweifel daran, dass er seinen Weg
                        weiter beschreiten und seinen von ihm selbst festgelegten Auftrag auch k&uuml;nftig
                        erf&uuml;llen wird. <br />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Dale Watson</strong> geniesst nicht nur in den USA Kult-Status. Er macht s&uuml;chtig
                        auf unerkl&auml;rliche Weise. Viele haben noch nicht mal seinen Namen geh&ouml;rt. Andere
                        verg&ouml;ttern ihn. Ein Ph&auml;nomen vielleicht, wie man es etwa bei Johnny Cash kannte, der
                        zu Lebzeiten die Verehrung in Country Music, Punk, Rockabilly, Rock und weit &uuml;ber
                        s&auml;mtliche Sparten hinaus erfuhr. <strong>Dale Watson&rsquo;s</strong> geradliniger und
                        hitzig-verwurzelter Mix aus Texas Honky Tonk, Western Swing, Outlaw Country und Rockabilly geht
                        keine Umwege. Er verfolgt seinen eigenen Weg ohne Kompromisse. Seine Musik spiegelt seine
                        Lebensphilosphie: wild, unabh&auml;ngig, animierend. <br />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Authentischer geht es nicht.</strong>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        <strong>Dale Watson</strong> kommt wie immer in Begleitung seiner grandiosen Lone Stars, das
                        heisst am Kontrabass steht Chris Crepps, der sich auch mit Background-Gesang perfekt
                        einf&uuml;gt, am Schlagzeug sitzt Mike Bernal und der brillante Don Pawlak an der Pedal Steel.
                        Diese Formation ist &uuml;ber viele Jahre zu einer &uuml;berragenden Band zusammengewachsen und
                        sie steht im Gleichklang mit ihrem Frontmann <strong>Dale Watson.</strong>
                        <br />
                    </p>
                    <p>&nbsp;</p>
                    <p>Ein Konzert, das sich kein Musikliebhaber entgehen l&auml;sst!</p>
                    <h3 className="color2">EINZIGES KONZERT IN DER SCHWEIZ</h3>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div>
                    <img
                        style={{ width: 100, marginBottom: 30 }}
                        src="/images/konzerte/konzert_2019_rk_01.jpg"
                        alt="Reckless Kelly"
                    />
                </div>
                <div className="">
                    <h2>RECKLESS KELLY (USA)</h2>
                    <div className="frame_1">
                        <div className="frame_2 first_frame">
                            <p>
                                <span className="color1"> ORT:</span> <b> Alts Schlachthuus, Laufen</b>{' '}
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 30. Juni 2019
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19.30 Uhr (T&uuml;r&ouml;ffnung 18.45
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.&ndash;
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="https://www.recklesskelly.com"
                                title="Reckless Kelly"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.recklesskelly.com
                            </a>
                        </p>
                    </div>
                    <h3>
                        Our goal was to make music that had a country vibe but a solid rock edge.&rdquo; &ndash; The
                        Braun Brothers
                    </h3>
                    <p>
                        Die in den fr&uuml;hen 90er Jahren von den Br&uuml;dern Willy Braun (Gesang, Gitarre) und Cody
                        Braun (Gesang, Geige, Mandoline, Harmonica) gegr&uuml;ndete Formation galt schon in ihren
                        Anf&auml;ngen als Geheimtipp in der pulsierenden Americana/Country Szene in Austin. Der
                        nationale Durchbruch gelang der hochk&auml;rtigen Band aber erst im Jahre 2003 mit dem
                        fantastischen Album <em>&laquo;Under The Table &amp; Above The Sun&raquo;</em>.
                        <strong>Reckless Kelly</strong> waren nun endg&uuml;ltig angekommen in der modernen Roots-Welt
                        und standen mit ihrem kompakten, schn&ouml;rkellosen Programm f&uuml;r die optimale
                        Verkn&uuml;pfung von klassischen Country Rock-Werten nach Art von Poco und der Nitty Gritty Dirt
                        Band mit der Singer/Songwriter-Kultur des Americana-Zeitgeists in der G&uuml;teklasse eines
                        Steve Earle oder Buddy Miller.
                    </p>
                    <p>&nbsp;</p>
                    <p>Es freut mich sehr, euch diese tolle Band zum ersten mal in der Schweiz zu pr&auml;sentieren.</p>
                    <p>&nbsp; </p>
                    <blockquote>
                        <p>
                            Mal straight rockend, flie&szlig;end, Americana, die Country Music ein wenig ber&uuml;hrend,
                            dieser Variante haben sich die Braun-Br&uuml;der stets bedient und bleiben auch, wenngleich
                            etwas milder, auf ihrem aktuellen Album &laquo;Sunset Motel&raquo; dabei. Immer sch&ouml;n
                            geradeaus, wie ein amerikanischer Highway. Die Faszination, Country Music auszudehnen, zu
                            rocken, ohne drauf los zu ballern, wieder eine weiche Brise, die nach dem Sound der Eagles
                            klingt, mit einzubringen. So wollten Reckless Kelly immer klingen und so h&ouml;ren wir sie
                            auf &laquo;Sunset Motel&raquo;.
                            <br />
                            <span className="color3 quelle">&ndash; countrymusicnews.de</span>
                        </p>
                    </blockquote>
                    <p>&nbsp; </p>
                    <blockquote>
                        <p>
                            The Austin-based band were Americana before there really was such a term, combining roots,
                            rock and country influences into an uncompromising musical approach that has earned the
                            Grammy-winning group a very steadfast and loyal following.
                            <br />
                            <span className="color3 quelle">&ndash; A Taste of Country</span>
                        </p>
                    </blockquote>

                    <h3 className="color2">ERSTES UND EINZIGES KONZERT IN DER SCHWEIZ</h3>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2019;
