import { IBlockDto, IRowSettingsDto } from '../../../clients/services';

export class BlockChecker {
    constructor(private readonly block: IBlockDto) {}

    rowExists(rowNr: number): boolean {
        if (rowNr < this.startRowCountAt()) {
            return false;
        }

        if (rowNr > this.endRowCountAt()) {
            return false;
        }

        if (this.isSkipped(rowNr)) {
            return false;
        }

        return true;
    }

    seatExists(row: number, seat: number): boolean {
        if (!this.block) {
            return false;
        }

        if (!this.rowExists(row)) {
            return false;
        }

        if (seat < this.startSeatCountAt(row)) {
            return false;
        }

        return !this.seatIsRemoved(row, seat);
    }

    seatIsRemoved(rowNr: number, seat: number): boolean {
        const rowSetting = this.getRowSetting(rowNr);
        if (!rowSetting) {
            return false;
        }

        if (rowSetting.removeSeats && rowSetting.removeSeats.filter((s) => s === seat).length > 0) {
            return true;
        }

        if (rowSetting.endSeatNumberAt && rowSetting.endSeatNumberAt > 0) {
            return seat > rowSetting.endSeatNumberAt;
        }

        return false;
    }

    endRowCountAt(): number {
        return this.startRowCountAt() + this.block.numberOfRows - 1;
    }

    startRowCountAt(): number {
        return this.block.startRowsAt ?? 1;
    }

    getBlockSeatCount(): number {
        return this.block.seatsInRow ?? 1;
    }

    startSeatCountAt(rowNr: number): number {
        const rowSetting = this.getRowSetting(rowNr);
        if (rowSetting && (rowSetting.startSeatNumbersAt || rowSetting.startSeatNumbersAt === 0)) {
            return rowSetting.startSeatNumbersAt;
        }

        if (this.block.blockWideSeatCount) {
            if (rowNr > (this.block.startRowsAt ?? 1)) {
                return this.endSeatCountAt(rowNr - 1) + 1;
            }
            return this.block.startSeatNumbersAt ?? 1;
        }

        return this.block.startSeatNumbersAt ?? 1;
    }

    endSeatCountAt(rowNr: number): number {
        const rowSettings = this.block.rowSettings?.find((r) => r.row === rowNr);
        if (rowSettings?.gaps && rowSettings.gaps.length > 0) {
            const sum = rowSettings.gaps.map((g) => g.shiftNrOfGaps).reduce((x, y) => x + y);
            return this.startSeatCountAt(rowNr) + (this.getBlockSeatCount() - 1) - sum;
        }
        return this.startSeatCountAt(rowNr) + (this.getBlockSeatCount() - 1);
    }

    getRowSetting(rowNr: number): IRowSettingsDto | undefined {
        return this.block.rowSettings?.find((r) => r.row === rowNr);
    }

    isSkipped(rowNr: number): boolean {
        const rowSetting = this.getRowSetting(rowNr);
        if (rowSetting && rowSetting.skipRow) {
            return true;
        }

        return false;
    }

    hasCorridorAfter(rowNr: number): boolean {
        const rowSetting = this.getRowSetting(rowNr);
        if (rowSetting && rowSetting.addCorridorAfter) {
            return true;
        }

        return false;
    }
}
