import React from 'react';
import { ArticleDto } from '../data/article.dto';
import { ParagraphType } from '../data/article-paragraph.dto';

export const Article: React.FC<ArticleDto> = (article: ArticleDto) => {
    return (
        <div>
            {article.title && <h2>{article.title}</h2>}
            {article.paragraphs.map((paragraph, k) => {
                switch (paragraph.type) {
                    case ParagraphType.Subtitle:
                        return (
                            <>
                                <p key={k}>
                                    <strong>{paragraph.text}</strong>
                                </p>
                                <br />
                            </>
                        );
                    default:
                        return (
                            <>
                                <p key={k}>{paragraph.text}</p>
                                <p key={k + '-2'}>&nbsp;</p>
                            </>
                        );
                }
            })}
            {article.links &&
                article.links.map((link, k) => {
                    return (
                        <a key={k} href={link.url}>
                            {link.title}
                        </a>
                    );
                })}
        </div>
    );
};
