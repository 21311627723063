import { MainContent, MainNarrow } from '../components';
import { RightColumn } from '../components/RightColumn';
import TicketReservationSide from '../components/TicketReservationSide';
export const Links = () => {
    return (
        <>
            <MainContent>
                <MainNarrow>
                    <h1>LINKS</h1>
                    <div>
                        <h2>Musik in den USA</h2>
                        <table className="width-100">
                            <tbody>
                                <tr>
                                    <td className="width-40">Alles &uuml;ber Austin, Texas</td>
                                    <td className="width-60">
                                        <a href="http://www.austintexas.org" target="_blank" rel="noreferrer">
                                            www.austintexas.org
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="width-40">Alles &uuml;ber Austin, Texas</span>
                                    </td>
                                    <td>
                                        <a href="http://www.austin360.com" target="_blank" rel="noreferrer">
                                            www.austin360.com
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>News / Berichte aus Austin</td>
                                    <td>
                                        <a href="http://www.austinchronicle.com" target="_blank" rel="noreferrer">
                                            www.austinchronicle.com
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Texas Musik-Szene</td>
                                    <td>
                                        <a href="http://www.texasmusicscene.com" target="_blank" rel="noreferrer">
                                            www.texasmusicscene.com
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Austin City Limits &ndash; Music&apos;n&apos;more</td>
                                    <td>
                                        <a href="http://www.austincitylimits.com" target="_blank" rel="noreferrer">
                                            www.austincitylimits.com
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Music Online-Store</td>
                                    <td>
                                        <a href="http://www.lonestarmusic.com" target="_blank" rel="noreferrer">
                                            www.lonestarmusic.com
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Music Online-Store</td>
                                    <td>
                                        <a href="http://www.cdbaby.com/Style/country" target="_blank" rel="noreferrer">
                                            www.cdbaby.com/Style/country
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Music Online-Store</td>
                                    <td>
                                        <a href="http://www.waterloorecords.com" target="_blank" rel="noreferrer">
                                            www.waterloorecords.com
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Outlaw Country Musik-Seite</td>
                                    <td>
                                        <a href="http://www.savingcountrymusic.com" target="_blank" rel="noreferrer">
                                            www.savingcountrymusic.com
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Neues Musik-Genre mit Einfluss von prominenten Wurzeln</td>
                                    <td>
                                        <a href="http://www.ameripolitanmusic.com" target="_blank" rel="noreferrer">
                                            www.ameripolitanmusic.com
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <h2>Weitere Links</h2>
                        <table className="width-100">
                            <tbody>
                                <tr>
                                    <td className="width-40">Kulturzentrum Alts Schlachthuus</td>
                                    <td className="width-60">
                                        <a href="http://www.kulturzentrumlaufen.ch" target="_blank" rel="noreferrer">
                                            www.kulturzentrumlaufen.ch
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Offizielle Seite Stadt Laufen</td>
                                    <td>
                                        <a href="http://www.laufen-bl.ch" target="_blank" rel="noreferrer">
                                            www.laufen-bl.ch
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </MainNarrow>
                <RightColumn>
                    <TicketReservationSide />
                </RightColumn>
            </MainContent>
        </>
    );
};

export default Links;
