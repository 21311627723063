import React from 'react';
import { ISeatDisplayInfo } from '../services/plan-info';
import { IConcertDto, IConcertSeriesDto, IResponseDto, ISaleOptionDto } from '../../../clients/services';
import { DetailedConcertInfo } from '../../../components/ConcertPreview/DetailedConcertInfo';
import { AddressModel } from '../models/address.model';
import { Reservations } from '../parts/Reservations';
import { actionContainerStyle } from '../styles';
import { Alert } from '@mui/material';
import { messageType } from '../../../clients/message-type-mapper';
import { OptionsService } from '../../../services/price-calculator';
import { ISeatSelection } from '../services/seat-selection';

type Props = {
    changeOptions?: (reservation: ISeatDisplayInfo, options: ISaleOptionDto[]) => void;
    desired: ISeatSelection[];
    numberOfSeats: number;
    form: AddressModel;
    concert: IConcertDto;
    concertSeries?: IConcertSeriesDto;
    response?: IResponseDto;
    optionsService: OptionsService;
};

export const ConfirmReservation: React.FC<Props> = (props) => {
    return (
        <>
            <DetailedConcertInfo concert={props.concert} concertSeries={props.concertSeries}></DetailedConcertInfo>
            <div style={{ ...actionContainerStyle, paddingRight: 40 }}>
                {!props.response ? (
                    <Alert severity="error">Die Reservation wurde nicht erfolgreich durchgeführt!</Alert>
                ) : (
                    <>
                        {!props.response.isSuccessful ? (
                            <>
                                {props.response.messages.map((m, k) => (
                                    <Alert
                                        key={k}
                                        severity={messageType(m.type)}
                                        style={{ marginBottom: '30px', textAlign: 'left' }}
                                    >
                                        {m.text}
                                    </Alert>
                                ))}
                            </>
                        ) : (
                            <>
                                <Alert severity="success" style={{ marginBottom: '30px', textAlign: 'left' }}>
                                    {props.response.messages[0]?.text}
                                    <br />
                                    Bitte klicke auf den in der Email angegebenen Link um deine Reservation zu
                                    bestätigen und den Sitzplan nochmals anzusehen.
                                </Alert>
                            </>
                        )}
                    </>
                )}
                <Reservations
                    reservations={props.desired}
                    numberOfSeats={props.numberOfSeats}
                    optionsService={props.optionsService}
                    countReservations={props.numberOfSeats}
                ></Reservations>
            </div>
        </>
    );
};
