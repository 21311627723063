import { IConcertDto, IConcertSeriesDto } from '../../clients/services';
import Comment from './Comment';
import { ConcertData } from './ConcertData';
import { ConcertName } from './ConcertName';
import { Links } from './Links';

export const ConcertInfo = (props: { concert: IConcertDto; concertSeries: IConcertSeriesDto }) => {
    return (
        <div className="frame_1">
            <b>
                <div className="frame_2 first_frame" style={{ backgroundImage: 'none', marginBottom: 10 }}>
                    <span className="color1"> ORT:</span> <ConcertName {...props.concert} />
                </div>

                <ConcertData concert={props.concert} concertSeries={props.concertSeries} showReductions />
            </b>

            {props.concert.comments?.map((c) => (
                <Comment key={c.message} {...c} />
            ))}
            {props.concertSeries?.comments?.map((c) => (
                <Comment key={c.message} {...c} />
            ))}
            <Links concert={props.concert} concertSeries={props.concertSeries} />
        </div>
    );
};

export default ConcertInfo;
