export const Archive2018 = () => {
    return (
        <>
            {' '}
            <div className="konzert">
                <div className="">
                    <h2>PRIVAT KONZERTE 2018</h2>
                    <div>
                        <img
                            style={{ width: '100%', marginBottom: 30 }}
                            src="/images/slider/pic21.jpg"
                            alt="Brennen Leigh and Noel McKay"
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2018_cpje_01.jpg"
                            alt="Courtney Patton Jaon Eady"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2014_cp-je_03.png"
                            alt="CP-JE"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>COURTNEY PATTON &amp; JASON EADY (USA)</h2>
                    <div className="frame_1 ausverkauft">
                        <div className="frame_2 first_frame">
                            <h2>
                                <span className="color3">AUSVERKAUFT!</span>
                            </h2>
                            <p>
                                <span className="color1">ANDERER ORT:</span>{' '}
                                <b>
                                    {' '}
                                    Hotel Sch&uuml;tzen, Rheinfelden | Jazzkeller
                                    <br />
                                    Bahnhofstrasse 19, 4310 Rheinfelden
                                </b>
                            </p>
                            <p>
                                <b>
                                    <a
                                        href="https://www.hotelschuetzen.ch"
                                        title="Hotel Schuetzen Rheinfelden"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        www.hotelschuetzen.ch
                                    </a>
                                </b>
                                <br />
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Donnerstag, 27. September 2018
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20.00 Uhr (T&uuml;r&ouml;ffnung 19.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.&ndash;
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.courtneypatton.com" target="_blank" rel="noreferrer">
                                www.courtneypatton.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.jasoneady.com" target="_blank" rel="noreferrer">
                                www.jasoneady.com/
                            </a>
                        </p>
                        <p>
                            facebook:{' '}
                            <a
                                href="https://www.facebook.com/pages/Courtney-Patton/13622975750?ref=hl"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Courtney Patton
                            </a>
                        </p>
                        <p>
                            facebook:{' '}
                            <a href="https://www.facebook.com/jasoneady/" target="_blank" rel="noreferrer">
                                Jason Eady
                            </a>
                        </p>
                    </div>
                    <h3>Jason Eady &ndash; Countrypoet zwischen Tag und Nacht</h3>
                    <p>
                        Der begnadete Jason Eady ist ein K&uuml;nstler, der die Countrymusik ohne All&uuml;ren und
                        kommerzielle Ambitionen auf ihren grundehrlichen Kern zur&uuml;ckf&uuml;hrt. Statt laut und
                        upbeat sind es eher die sanften Schwingungen, die seinen Stil dominieren.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Jason Eady verf&uuml;gt &uuml;ber die textliche Sensibilit&auml;t eines Merle Haggard und
                        &uuml;ber die musikalische Emotionalit&auml;t eines Don Williams. Seine Musik ist an alle
                        gerichtet, die sich eher der traditionellen Seite des Genres zugeh&ouml;rig f&uuml;hlen. Seine
                        Werke sind von zeitloser Sch&ouml;nheit und sind ein Beweis f&uuml;r die Ueberlebenskraft guter,
                        stilechter Countrymusik.
                    </p>
                    <h3>Courtney Patton &ndash; die starke Frau an Jason Eady&rsquo;s Seite</h3>
                    <p>
                        F&uuml;r Insider z&auml;hlt Courtney Patton zu den besten unabh&auml;ngigen K&uuml;nstlerinnen
                        in Texas. Ihre Musik fusst auf intelligenten und bodenst&auml;ndigen Texten, auf einer
                        twangy-Stimme und ansteckenden Melodien. Courtney Patton&rsquo;s Musik vermittelt harmonische
                        und melodische Gl&uuml;ckseligkeit.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Courtney Patton&rsquo;s Motto lautet : &laquo;Auf unserem Lebensweg sind wir alle mit
                        Herausforderungen konfrontiert. Ein starker Wille und ein dicke Haut helfen uns h&auml;ufig, die
                        gr&ouml;ssten Hindernisse aus dem Weg zu schaffen. Wir alle haben die F&auml;higkeiten, noch
                        besser zu werden in allem was wir tun. Und wir alle sollten unseren Traum leben und stets nach
                        dem Besten streben und nie stehenbleiben, und unseren Weg gehen bis sich der Erfolg
                        einstellt.&raquo;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Ich freue mich, Euch diese beiden aussergew&ouml;hnlichen Singer-Songwriter aus Texas gemeinsam
                        zu pr&auml;sentieren. Ich garantiere euch urspr&uuml;ngliche und authentische Country Musik,
                        vorgetragen von zwei eigenst&auml;ndigen und sehr talentierten
                        K&uuml;nstlerpers&ouml;nlichkeiten, die jede f&uuml;r sich schon ein Konzertbesuch wert ist. Im
                        Doppelpack nat&uuml;rlich erst recht ! Im Gep&auml;ck haben zudem sowohl Jason Eady als auch
                        Courtney Patton ihre neuen Alben.
                    </p>
                    <h3 className="color2">Weitere Konzerte in der Schweiz:</h3>
                    <p>
                        Sonntag, 23.9.2018: Privatkonzert
                        <br />
                        Mittwoch, 26.9.2018: Mahogany Hall, Bern
                        <br />
                        Donnerstag, 27.9.2018: Hotel Sch&uuml;tzen, Rheinfelden{' '}
                        <span className="color2">AUSVERKAUFT!</span>
                        <br />
                        Freitag, 28.9.2018: Privatkonzert
                        <br />
                        Samstag, 29.9.2018: Rifferswil
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <a href="tickets.html">JETZT &amp; HIER TICKETS BESTELLEN</a>
                    </p>
                    <p>&nbsp; </p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20180927_cpje_RZ_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20180927_cpje_RZ_lo.jpg"
                                alt="Courtney Patton Jason Eady"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/2018_rhonda_vincent.jpg"
                            alt="Rhonda Vincent"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/2018_rhonda_vincent_02.jpg"
                            alt="Rhonda Vincent"
                        />
                    </p>
                    <p>&nbsp;</p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Rhonda Vincent
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>CONGRATULATIONS TO RHONDA VINCENT &amp; THE RAGE (USA)</h2>
                    <h3>Annual Grammy Awards 2018</h3>
                    <p>
                        So much to Celebrate!!&nbsp; A Grammy Win at the 60th Annual Grammy Awards in New York City for
                        Best Bluegrass Album; and last night at the 44th Annual SPBGMA Awards in Nashville, TN &ndash;
                        Congratulations to Mickey Harris &quot;Bass Player of the Year&quot;, Hunter Berry&nbsp;
                        &quot;Fiddle Player of the Year&quot;, Josh Williams &quot;Guitar Player of the
                        Year&quot;;&nbsp; and thank you so much for the &quot;Female Vocalist of the Year&quot;.&nbsp;
                        Thank you to each and every one of you who made these honors possible. We appreciate you so
                        much, and all the continuous support of our music.&nbsp; We may just have a party at every show
                        this year in celebration.&nbsp; Please join us to celebrate!
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <b>
                            <a
                                href="http://www.rhondavincent.com"
                                title="Rhonda Vincent"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.rhondavincent.com
                            </a>
                        </b>
                        <br />
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_kb_01.jpg"
                            alt="Krueger Brothers"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_kbkq_01.jpg"
                            alt="Krueger Brothers Chamber Ensemble"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2013_kb_02.png"
                            alt="Krueger Brothers"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Kr&uuml;ger Brothers
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>KR&Uuml;GER BROTHERS &amp; THE KONTRAS QUARTET (USA/CH)</h2>
                    <div className="frame_1">
                        <div className="frame_2 first_frame">
                            <p>
                                <span className="color1">ANDERER ORT:</span>{' '}
                                <b>
                                    {' '}
                                    Landgasthof Riehen, Im Grossen Festsaal
                                    <br />
                                    Baselstrasse 38, 4125 Riehen, BS
                                </b>
                            </p>
                            <p>
                                <b>
                                    <a
                                        href="http://www.landgasthof-riehen.ch/salle/salle_de.html"
                                        title="Landgasthof Riehen"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        www.landgasthof-riehen.ch
                                    </a>
                                </b>
                                <br />
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Donnerstag, 02. November 2017
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19.00 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 70.&ndash; (nummerierte Sitzpl&auml;tze)
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.krugerbrothers.com"
                                title="Krueger Brothers"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <strong>www.krugerbrothers.com</strong>
                            </a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Besonders sehenswert:{' '}
                            <a
                                href="http://krugerbrothers.com/swiss-tv-kruger-brothers-documentary-available/"
                                title="Krueger Brothers"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <strong>
                                    &quot;Banjo &amp; Bluegrass&quot; Die Krüger Brothers und ihr Traum von America
                                </strong>
                            </a>
                        </p>
                    </div>
                    <h3>Kr&uuml;ger Brothers &amp; The Kontras Quartet</h3>
                    <p>
                        Die <strong>Krüger Brothers</strong> sind mittlerweile weltweit bekannt für ihre einzigartige
                        Mischung von klassischer Musik, Folk, Jazz und Bluegrass. Ihre herausragende Musikalität, die
                        Schönheit in ihrem eleganten und oft komplexen Zusammenspiel werden vom Publikum stets sehr
                        geschützt.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        In den letzten Jahren haben sich die <strong>Krüger Brothers</strong> immer wieder Themen und
                        Formen klassischer Musik zugewandt. Am offensichtlichsten ist dies geschehen mit den Werken
                        &quot;Spirit Of The Rockies&quot; im Jahre 2013 (das im letzten Jahr in der Schweiz aufgeführte
                        Werk), sowie dem &laquo;Appalachian Concerto&raquo; im Jahre 2011, mit &laquo;Lucid
                        Dreamer&raquo; im Jahre 2015 und nun mit der brandneuen &laquo;Roan Mountain Suite&raquo;. Bei
                        den drei zuletzt genannten Kompositionen wurden die <strong>Krüger Brothers</strong> musikalisch
                        vom herausragenden <strong>Kontras Quartet</strong> aus Chicago begleitet. Gemeinsame Auftritte
                        in den USA wurden von den Zuhörern und der Presse gefeiert.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die <strong>Krüger Brothers</strong> werden auf ihrer diesjährigen Schweizer-Tournee von eben
                        diesem <strong>Kontras Quartet</strong> begleitet! Wir werden in Riehen eine reiche Auswahl
                        dieser klassischen Kompositionen hören. Besonders gespannt dürfen wir aber auf jene Lieder sein,
                        welche die <strong>Krüger Brothers</strong> aus ihrem üblichen Repertoire präsentieren werden,
                        natürlich in Begleitung des <strong>Kontras Quartet.</strong> Diese Werke werden damit eine ganz
                        neue und spezielle Dimension erhalten, etwas das wir so noch nie gehört haben. Ein
                        unvergesslicher Hörgenuss der besonderen Art ist damit garantiert.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Lasst euch dieses einmalige und geniale Konzerterlebnis nicht entgehen. Selbstverstündlich bin
                        ich euch auch dankbar für eure Weiterempfehlungen. Besten Dank.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <a href="tickets.html">JETZT &amp; HIER TICKETS BESTELLEN</a>
                    </p>
                    <p>&nbsp; </p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20171102_kbkq_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20171102_kbkq_lo.jpg"
                                alt="Krüger Brothers & Kontras Quartet"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2018;
