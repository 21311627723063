import { DateTime } from 'luxon';
import {
    ReservationControllerClient,
    IReservationInfoDto,
    ISeatReservationDto,
    IConcertDto,
    SeatReservationDto,
    IPersonDto,
    PersonDto,
    IOrderDto,
    IConcertSeriesDto,
    IPlanDto,
    IContactTracingDataDto,
    ContactTracingDataDto,
    ITicketInfoDto,
    IResponseDto,
    OrderInfoDto,
} from '../clients/services';
import clientFactory from './client-factory';
import { TicketSaleCheckResponseDto } from '../clients/services';
export class ReservationService {
    private readonly reservationClient: ReservationControllerClient;
    constructor() {
        this.reservationClient = clientFactory.createClient(ReservationControllerClient);
    }

    async getPlanAsync(planId: number): Promise<IPlanDto> {
        return await this.reservationClient.getPlan(planId);
    }

    async checkTicketSale(concertId: number, guid?: string): Promise<TicketSaleCheckResponseDto> {
        return await this.reservationClient.checkTicketSale(concertId, guid);
    }

    async getInfoForConcertAsync(concertId: number): Promise<IReservationInfoDto> {
        return await this.reservationClient.getConcertInfos(concertId);
    }

    async getReservationsForConcertAsync(concertId: number): Promise<ISeatReservationDto[]> {
        return await this.reservationClient.getReservations(concertId);
    }

    async getConcertsForSeries(serieId: number): Promise<IConcertDto[]> {
        return await this.reservationClient.getConcerts(serieId);
    }

    async getConcertSeries(): Promise<IConcertSeriesDto[]> {
        return await this.reservationClient.getConcertSeries();
    }

    async getConcertSeriesForTicketSales(): Promise<IConcertSeriesDto[]> {
        return await this.reservationClient.getConcertSeriesWithTicketSale();
    }

    async getConcertSeriesForWelcomePage(): Promise<IConcertSeriesDto[]> {
        return await this.reservationClient.getConcertSeriesForWelcomePage();
    }

    async getConcertSeriesForArchive(year: number): Promise<IConcertSeriesDto[]> {
        return await this.reservationClient.getConcertSeriesForArchive(year);
    }

    async getArchiveYears(): Promise<number[]> {
        const currentYear = DateTime.now().year;
        const takeCurrentYear = await this.reservationClient.hasPastConcertSeries(currentYear);
        const startYear = takeCurrentYear ? currentYear : currentYear - 1;

        return [startYear, startYear - 1, startYear - 2];
    }

    async reserve(
        concertId: number,
        reservations: ISeatReservationDto[],
        person: IPersonDto,
        tracingData?: IContactTracingDataDto,
        earlyAccessToken?: string,
    ): Promise<IResponseDto> {
        const reservationDtos = reservations.map((r) => new SeatReservationDto(r));
        const data = new OrderInfoDto();
        data.earlyAccessGuid = earlyAccessToken;
        data.reservations = reservationDtos;
        data.person = new PersonDto(person);
        data.concertId = concertId;
        if (tracingData) {
            data.tracingData = new ContactTracingDataDto(tracingData);
        }
        return await this.reservationClient.reserve(data);
    }

    async getOrder(concertId: number, guid: string, verifyEmail: boolean): Promise<IOrderDto> {
        return await this.reservationClient.getOrder(concertId, guid, verifyEmail);
    }

    async getTicketInfo(concertId: number, guid: string): Promise<ITicketInfoDto> {
        return await this.reservationClient.getTicketInfo(concertId, guid);
    }
}
