interface QuoteProps {
    children: React.ReactNode;
    source: string;
    style?: React.CSSProperties;
}

export const Quote = (props: QuoteProps) => (
    <blockquote style={props.style}>
        <p>
            {props.children}
            <br />
            <span className="color3 quelle">&ndash; {props.source}</span>
        </p>
    </blockquote>
);
