export const Archive2011 = () => {
    return (
        <>
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2011_tr_01.jpg"
                            alt="TOM RUSSELL"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>TOM RUSSELL</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Samstag, 13. August 2011
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.tomrussell.com" target="_blank" rel="noreferrer">
                                www.tomrussell.com
                            </a>
                        </p>
                    </div>
                    <h3> Gelebte Poesie mit einem der besten Storytellers Amerikas</h3>
                    <p>
                        <strong>Tom Russell</strong>: lead vocals, acoustic guitar
                    </p>
                    <p>
                        {' '}
                        <strong>Mal Thad Beckman</strong>: lead acoustic guitar, mandolin
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2011_rv_01.jpg"
                            alt="RHONDA VINCENT"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>RHONDA VINCENT &amp; THE RAGE</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Mittwoch, 27. und Donnerstag, 28. Juli 2011
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.rhondavincent.com" target="_blank" rel="noreferrer">
                                www.rhondavincent.com
                            </a>
                        </p>
                    </div>
                    <h3> The Queen of Bluegrass &ndash; absolute Weltklasse!</h3>
                    <p>
                        Rhonda spielt Mandoline, Gitarre, Geige, singt mit hinreissender Stimme Bluegrass und sammelt
                        reihenweise Auszeichnungen. Heute z&auml;hlt sie zu den grossen Stars der Countryszene.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Rhonda&rsquo;s letzter Auftritt in der Schweiz anl&auml;sslich der Country Night in Gstaad im
                        Jahre 2007 war schlicht und einfach gesagt der Hammer, etwas vom mitreissendsten, was ich je auf
                        einer Schweizer B&uuml;hne erlebt habe. In k&uuml;rzester Zeit glich das Alpengalazelt einem
                        Hexenkessel, das Publikum war begeistert und beklatschte die unz&auml;hligen Soli frenetisch.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Rhonda&rsquo;s Show beinhaltet Bluegrass mit Feuer, Traditionals und Gospeleinlagen sowie
                        Instrumentalst&uuml;cke, die in unglaublicher Virtuosit&auml;t und kraftvoller Intensit&auml;t
                        vorgetragen werden. Jedes einzelne Mitglied der hervorragenden Band z&auml;hlt zu den besten
                        Bluegrass-Musikern.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Rhonda Vincent</strong>: lead vocals, mandolin
                        <br />
                        <strong>Hunter Berry</strong>: fiddle
                        <br />
                        <strong>Aaron McDaris</strong>: banjo
                        <br />
                        <strong>Ben Helson</strong>: guitar
                        <br />
                        <strong>Mickey Harris</strong>: bass
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2011_jeb_01.jpg"
                            alt="JOE ELY BAND"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>JOE ELY BAND</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Samstag, 9. Juli 2011
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.ely.com" target="_blank" rel="noreferrer">
                                www.ely.com
                            </a>
                        </p>
                    </div>
                    <h3> &quot;Satisfied At Last&quot; Tour &ndash; Texas Music at its very best!</h3>
                    <p>
                        In einer perfekten Welt, wohl auch mit etwas mehr Gl&uuml;ck und Anpassungsf&auml;higkeit
                        h&auml;tte der Texaner Joe Ely eine Superstar&ndash;Karriere beschreiten k&ouml;nnen wie etwa
                        Bruce Springsteen. Das Talent, eine markante Stimme und eine dynamische B&uuml;hnenpr&auml;senz
                        wurden ihm stets attestiert. Doch irgendwie wollte sich Joe einfach nicht anpassen und so ging
                        er stets seinen eigenen Weg. Daf&uuml;r erlangte Ely Kultstatus. F&uuml;r die Country Fans war
                        er zu sehr Rock&sbquo;n&lsquo;Roll und f&uuml;r das Rockpublikum zu sehr Country. Joe zieht noch
                        heute seine Zuh&ouml;rer mit seiner engagierten, leidenschaftlichen Art und seiner treibenden
                        Musik in seinen Bann. Man kann f&ouml;rmlich das L&auml;cheln in seiner Stimme h&ouml;ren. Davon
                        zeugt auch sein neuestes Werk &bdquo;Satisfied at last&ldquo;, und der Titel &bdquo;Not That
                        Much Has Changed&ldquo; sagt eigentlich schon viel &uuml;ber Joe&rsquo;s Leben und seine Musik.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Joe Ely</strong>: lead vocals, acoustic guitar
                        <br />
                        <strong>David Grissom</strong>: electric guitar
                        <br />
                        <strong>Pat Manske</strong>: drums
                        <br />
                        <strong>Jimmy Petitt</strong>: bass
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2011_rl_01.jpg"
                            alt="ROBYN LUDWICK"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>ROBYN LUDWICK</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Samstag, 4. Juni 2011
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.robynludwick.com" target="_blank" rel="noreferrer">
                                www.robynludwick.com
                            </a>
                        </p>
                    </div>
                    <h3> Americana &amp; Texas Folk-Rock-Blues, grossartiges Songwriting</h3>
                    <blockquote>
                        <p>
                            Robyn&rsquo;s songs go straight to where she aims them, be it the heart, the bone, or the
                            muscle. She is absolutely one of the bravest &amp; most gifted writers I know and that we
                            have here.
                            <span className="color3 quelle">
                                <br />
                                &ndash; Walt Wilkins
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Robyn&rsquo;s is a seriously expressive voice &ndash; both as a songwriter and as a singer.
                            I hear centuries of truth in there.
                            <span className="color3 quelle">
                                <br />
                                &ndash; Gurf
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Robyn Ludwick writes songs you either wish you&rsquo;d written or would swear you grew up
                            with, like a Polaroid from 1966 you&rsquo;ve seen a thousand times. If it&rsquo;s in the
                            water, then she&rsquo;s on the same rope swing as Harper Lee and Gillian Welch.
                            <span className="color3 quelle">
                                <br />
                                &ndash; Trish Murphy
                            </span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2013_kb_01.jpg"
                            alt="Krueger Brothers"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Kr&uuml;ger Brothers
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>KR&Uuml;GER BROTHERS</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Dienstag, 24. Mai 2011
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.krugerbrothers.com" target="_blank" rel="noreferrer">
                                www.krugerbrothers.com
                            </a>
                        </p>
                    </div>
                    <h3>Einfach genial</h3>
                    <p>
                        Mit <strong>Jens Kr&uuml;ger</strong> (Banjo), <strong>Uwe Kr&uuml;ger</strong> (Gitarre,
                        Leadgesang), <strong>Joel Landsberg</strong> (Bass). Musikalisch geh&ouml;ren die Kr&uuml;ger
                        Brothers mit ihrer gelungenen Mischung aus Bluegrass, Country, Folk und Klassik zweifellos zum
                        Besten, was man &uuml;berhaupt&nbsp; zu h&ouml;ren bekommt.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2013_tmr_01.jpg"
                            alt="tmr"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>THOMAS MICHAEL RILEY</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 8. Mai 2011
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.thomasmichaelriley.com"
                                title="Thomas Michael Riley"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.thomasmichaelriley.com
                            </a>
                        </p>
                    </div>
                    <h3>Texas Honky-Tonk &ndash; Country &amp; Western &ndash; grossartiges Entertainment</h3>
                    <p>
                        It&apos;s hard to beat Thomas Michael Riley&apos;s fun personality, his talent as a singer
                        songwriter, and his dynamic stage presence. Thomas Michael Riley is earning the status of a
                        legend as he writes and sings his own brand of colorful and thoughtful country Americana music.
                        Each performance, Thomas delights fans of country music with his songwriting skills, spirited
                        music and wonderful sense of humor. His ability to capture his audience is pure magic!
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2011_sp_01.jpg"
                            alt="SARAH PIERCE BAND"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>SARAH PIERCE BAND</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Donnerstag, 24. M&auml;rz 2011
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.sarahpierce.com" target="_blank" rel="noreferrer">
                                www.sarahpierce.com
                            </a>
                        </p>
                    </div>
                    <h3>Zeitgen&ouml;ssische Country- &amp; Folk-Musik mit Pop- und Rock-Einfl&uuml;ssen</h3>
                    <blockquote>
                        <p>
                            Over the last couple of years, Austin&apos;s Sarah Pierce turned her sixth and seventh
                            studio releases loose on the world. Individually, they are disparate and at times
                            mesmerizing in tremendously unique fashion. Taken together, as with the repeated listens
                            which led to this review, Cowboy&apos;s Daughter and Bring It On paint a picture of an
                            artist possessed of a breadth of talent which bears watching and is more than worth your
                            hard-earned dollars&apos; listen. Click to read the entire review.
                            <span className="color3 quelle">
                                <br />
                                &ndash; Dave Pilot, Outlaw Magazine
                            </span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2011;
