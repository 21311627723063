export const Archive2013 = () => {
    return (
        <>
            {' '}
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2013_kb_01.jpg"
                            alt="Krueger Brothers"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2013_kb_02.png"
                            alt="Krueger Brothers"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Krüger Brothers
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>KR&Uuml;GER BROTHERS</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Mittwoch, 30. Oktober 2013
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.krugerbrothers.com" target="_blank" rel="noreferrer">
                                www.krugerbrothers.com
                            </a>
                        </p>
                    </div>
                    <h3>Einfach genial! Absolutely brilliant!</h3>
                    <p>
                        Mit <strong>Jens Krüger</strong> (Banjo), <strong>Uwe Krüger</strong> (Gitarre, Leadgesang),
                        <strong>Joel Landsberg</strong> (Bass), <strong>Josh Day</strong> (Percussion). Jens ist der
                        erste nicht-amerikanische Musiker, der den Steve Martin Prize for Excellence in Banjo and
                        Bluegrass Music Award erhalten hat. Dieser wurde ihm während der David Letterman Show am 25.
                        September übergeben. Wir gratulieren und sind stolz, ihn und seine Brüder wieder in Laufen zu
                        begrüssen.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Musikalisch gehören die Kr&uuml;ger Brothers mit ihrer gelungenen Mischung aus Bluegrass,
                        Country, Folk und Klassik zweifellos zum Besten, was man überhaupt zu hören bekommt.
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            I used to think the banjo was somewhat limited to certain styles, until I heard Jens Kruger.
                            Jens has played some of the most beautiful and expressive banjo I&rsquo;ve ever heard..
                            <span className="color3 quelle">
                                <br />
                                &ndash; Ron Block of Alison Krauss and Union Station
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            It&rsquo;s a blast playing with them&hellip; not only are they all superior musicians, they
                            are the nicest guys you&rsquo;d ever want to meet.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Adam Steffey
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Jens Kruger has established himself as one of the world&rsquo;s most musically sophisticated
                            and technically accomplished five-string banjo players.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Happy Traum
                            </span>
                        </p>
                    </blockquote>

                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2013_tmr_01.jpg"
                            alt="tmr"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2013_tmr_02.png"
                            alt="tmr"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>THOMAS MICHAEL RILEY</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Montag, 7. Oktober 2013
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.thomasmichaelriley.com"
                                title="Thomas Michael Riley"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.thomasmichaelriley.com
                            </a>
                        </p>
                    </div>
                    <h3>Singer Songwriter with good humor</h3>
                    <p>
                        It&apos;s hard to beat Thomas Michael Riley&apos;s fun personality, his talent as a singer
                        songwriter, and his dynamic stage presence. Thomas Michael Riley is earning the status of a
                        legend as he writes and sings his own brand of colorful and thoughtful country Americana music.
                        Each performance, Thomas delights fans of country music with his songwriting skills, spirited
                        music and wonderful sense of humor. His ability to capture his audience is pure magic!
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Thomas Michael Riley</strong>: Gitarre, Leadgesang
                    </p>
                    <p>
                        {' '}
                        <strong>Kenny Grimes</strong>: Gitarre
                    </p>
                    <p>
                        {' '}
                        <strong>Donnie Price</strong>: Bass{' '}
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2013_clb_01.jpg"
                            alt="Claire Lynch Band"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2013_clb_02.png"
                            alt="Claire Lynch Band"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Stacie Huckeba
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>CLAIRE LYNCH BAND</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 8. September 2013
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19 Uhr (T&uuml;r&ouml;ffnung 18 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.clairelynch.com/" title="Claire Lynch" target="_blank" rel="noreferrer">
                                www.clairelynch.com
                            </a>
                        </p>
                    </div>
                    <h3>Akustische Musik auf allerh&ouml;chstem Niveau</h3>
                    <p>
                        In Laufen wird die Claire Lynch Band viele Lieder aus dem brandneuen Album &quot;Dear
                        Sister&quot; präsentieren. Akustische Musik auf allerhöchstem Niveau ist garantiert mit{' '}
                        <strong>Claire Lynch</strong> (Leadgesang, Gitarre), <strong>Mark Schatz</strong> (Bass,
                        Clawhammer Banjo),
                        <strong>Matt Wingate</strong> (Gitarre, Mandoline, Gesang) und <strong>Bryan McDowell</strong>
                        (Mandoline, Violine, Gitarre, Gesang).
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            She has one of the sweetest, purest and best lead voices in the music business today.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Dolly Parton
                            </span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            I&apos;ve always thought Claire Lynch has the voice of an angel. <br />
                            I am a fan.
                            <br />
                            <span className="color3 quelle">&ndash; Emmylou Harris</span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2013_sb_01.jpg"
                            alt="Suzy Bogguss"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2013_sb_02.png"
                            alt="Suzy Bogguss"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>SUZY BOGGUSS</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Dienstag, 16. April 2013
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.suzybogguss.com/" title="Suzy Bogguss" target="_blank" rel="noreferrer">
                                www.suzybogguss.com
                            </a>
                        </p>
                        <p>
                            Download{' '}
                            <a
                                href="/docs/SUZY_BOGGUSS-ATX_Flyer_SuzyBogguss2013.pdf"
                                title="Suzy Bogguss Konzert-Flyer"
                                target="_blank"
                            >
                                Konzert-Flyer
                            </a>
                        </p>
                    </div>
                    <h3>Eine wundervolle Stimme</h3>
                    <p>
                        Suzy Bogguss landete mit ihrem Album &bdquo;Aces&ldquo; im Jahre 1991 einen Millionenseller und
                        weitere drei ihrer Alben ernteten Gold-Auszeichnungen f&uuml;r jeweils mehr als 500&lsquo;000
                        verkaufte Exemplare. Zahlreiche Lieder schafften es auch in die Top Ten der US-Country Charts.
                        Suzy Bogguss bewegt sich sehr kompetent in verschiedenen Musikstilrichtungen von traditioneller
                        Country Musik, Western Swing, moderner Country Musik zum Jazz und Folk. Die vielseitige Suzy
                        Bogguss wird in Laufen von <strong>Pat Bergeson</strong> (Gitarre, Harmonica) und
                        <strong>Charlie Chadwick</strong> (Kontrabass) begleitet.
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Her voice sparkles like crystal water.{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Chet Atkins
                            </span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2013_cbot_01.jpg"
                            alt="COLIN & OWEN"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2013_cbot_02.png"
                            alt="COLIN & OWEN"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>COLIN BROOKS &amp; OWEN TEMPLE</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Mittwoch, 6. M&auml;rz 2013
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Colin Brooks auf{' '}
                            <a
                                href="http://www.youtube.com/watch?v=0CNK4Xpuz9I"
                                title="Colin Brooks auf Youtube"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Youtube
                            </a>
                        </p>
                        <p>
                            Owen Temple auf{' '}
                            <a
                                href="http://www.youtube.com/watch?v=n8YNEDm3jt4"
                                title="Owen Temple auf Youtube"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Youtube
                            </a>
                        </p>
                        <p>
                            <a href="http://www.owentemple.com" title="Owen Temple" target="_blank" rel="noreferrer">
                                www.owentemple.com
                            </a>
                        </p>
                        <p>
                            Download{' '}
                            <a
                                href="/docs/ATX_Flyer_Colin_Owen2013.pdf"
                                title="Colin Owen Konzert-Flyer"
                                target="_blank"
                            >
                                Konzert-Flyer
                            </a>
                        </p>
                    </div>
                    <h3>Singer / Songwriter-Paket aus Austin</h3>
                    <p>
                        Der hochtalentierte Brooks war Mitbegr&uuml;nder der fantastischen{' '}
                        <strong>BAND OF HEATHENS</strong>. Seine Musik fusst auf der Tradition des Country und Blues.
                        Brooks bietet intensive und authentische Singer/Songwriter- und Gitarrenmusik. Der
                        Singer/Songwriter Owen Temple ist fest mit der Musik-Szene von Austin verwurzelt. Seine Songs
                        sind voll von skurrilen Charakteren, seltsamen Geschichten und schr&auml;gen Lebensl&auml;ufen
                        aus seiner weitl&auml;ufigen Heimat. Colin Brooks und Owen Temple werden den Abend sowohl solo
                        als auch gemeinsam auf der B&uuml;hne bestreiten. Ein unterhaltsames und abwechslungsreiches
                        Programm ist garantiert.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2013_bl_01.jpg"
                            alt="BOB LIVINGSTON"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2013_bl_02.png"
                            alt="BOB LIVINGSTON"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>BOB LIVINGSTON</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Freitag, 1. M&auml;rz 2013
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.texasmusic.org/bob_livingston.php"
                                title="Owen Temple"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.texasmusic.org/bob_livingston
                            </a>
                        </p>
                        <p>
                            Download{' '}
                            <a
                                href="/docs/BOB_LIVINGSTON-ATX_Flyer_Livingston2013.pdf"
                                title="Bob Livingston Konzert-Flyer"
                                target="_blank"
                            >
                                Konzert-Flyer
                            </a>
                        </p>
                    </div>
                    <h3>Ein multikultureller Cowboy back in Laufen</h3>
                    <p>
                        Seine Schweizer Premiere bestritt Bob Livingston in Laufen im November 2009. Seine ungebremste
                        Spielfreude, seine humorvolle und faszinierende Erz&auml;hlkunst sowie seine Vielseitigkeit
                        &uuml;berzeugten das Publikum damals vollends. Ein grossartiger Entertainer mit einem
                        reichhaltigen musikalischen Hintergrund.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2013;
