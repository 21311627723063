import { MainContent, MainNarrow } from '../components';
import { RightColumn } from '../components/RightColumn';
import TicketReservationSide from '../components/TicketReservationSide';
export const Other = () => {
    return (
        <>
            <MainContent>
                <MainNarrow>
                    <h1>WEITERE KONZERTE UND VERANSTALTUNGEN</h1>
                    <div>
                        <p>
                            <b>
                                Tickets für die unten aufgelisteten Konzerte und Veranstaltungen müssen direkt über den
                                jeweiligen Konzertanbieter bezogen werden.
                            </b>
                            <br />
                        </p>{' '}
                        <p style={{ marginTop: 20 }}>
                            Die Konzerte finden alle ausserhalb der Konzertveranstaltung &quot;MARTIN MEIER{' '}
                            <span style={{ fontStyle: 'italic' }}>presents</span>&quot; statt.
                            <br /> Damit Ihr auch an weiteren Konzertveranstaltungen in den Genuss von Top-Darbietungen
                            kommen könnt, stelle ich diese Liste zur Verfügung.
                        </p>
                        <p style={{ marginTop: 20 }}>
                            Ich wünsche euch viel Vergnügen bei folgenden Konzerten und Veranstaltungen.
                        </p>
                        <p style={{ marginTop: 20 }}>
                            <span className="color2">
                                - Momentan sind leider keine Konzerte oder Veranstaltungen aufgeführt.
                            </span>
                        </p>
                        <br />
                        <br /> alle Angaben ohne Gewähr
                    </div>
                </MainNarrow>
                <RightColumn>
                    <TicketReservationSide />
                </RightColumn>
            </MainContent>
        </>
    );
};

export default Other;
