import { styled } from '@mui/system';

export const RootDiv = styled('div')({
    '& > *': {
        margin: 5,
    },
    '& .MuiTextField-root': {
        margin: 5,
        width: 200,
    },
});

export const formControlStyle = {
    margin: 5,
    minWidth: 120,
};

export const actionContainerStyle = {
    backgroundColor: '#FFFFFF',
    padding: '20px 20px 22px',
    borderBottom: '3px solid #8ba2c2',
    borderRadius: '5px',
};
