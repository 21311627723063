import React from 'react';

export const RightContent = ({ title, children }: { title: string; children: React.ReactNode }) => {
    return (
        <div className="right_content" style={{ textAlign: 'left' }}>
            <h1>{title}</h1>
            {children}
            <hr />
        </div>
    );
};
