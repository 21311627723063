import React from 'react';

interface Props {
    label?: string;
}

export const Entrance: React.FC<Props> = (props: Props) => {
    return (
        <>
            <div style={{ backgroundColor: 'grey' }}>{props.label ?? 'E'}</div>
        </>
    );
};
