export const Archive2009 = () => {
    return (
        <>
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2009_blt_01.jpg"
                            alt="BOB LIVINGSTON"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        www.texasmusic.org
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>BOB LIVINGSTON TRIO</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> November 2009
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.texasmusic.org/bob_livingston.php"
                                title="Owen Temple"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.texasmusic.org/bob_livingston
                            </a>
                        </p>
                        <p>
                            <a href="http://www.myspace.com/boblivingston" target="_blank" rel="noreferrer">
                                www.myspace.com/boblivingston
                            </a>
                        </p>
                    </div>
                    <h3>With Bradley Kopp and Richard Bowden</h3>
                    <p>
                        Bob Livingston ist einer jener Musiker, die in den 70er-Jahren am Ursprung der Outlaws-Bewegung
                        in Austin standen. Willie Nelson, Waylon Jennings, Billy Joe Shaver und Jerry Jeff Walker
                        f&uuml;hrten die damalige Musikrevolution an. Bob Livingston war w&auml;hrend vieler Jahre
                        Mitglied der legend&auml;ren Lost Gonzo Band. Diese z&auml;hlte in den 70er- Jahren zu den
                        absoluten Top-Live Bands in den USA. Bob Livingston und weitere &quot;Lost Gonzos&quot;
                        begleiteten neben Jerry Jeff Walker auch Michael Martin Murphey, Ray Wylie Hubbard und andere.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Seit vielen Jahren tourt der nimmerm&uuml;de Bob weltweit und regelm&auml;ssig als
                        Musikbotschafter im Auftrage des US State Department. So brachte er die texanische Musik in
                        L&auml;nder wie Indien, Pakistan, Jemen, Thailand oder Vietnam. Seine Lieder erz&auml;hlen
                        folglich von seinen Erlebnissen auch in den von ihm bereisten L&auml;ndern und den Erfahrungen
                        mit Menschen anderer Kulturen. Seine CD &quot;Mahatma Gandhi and Sitting Bull&quot; verbindet
                        die Musikstile von Ost und West auf eindr&uuml;ckliche Weise und wurde zu Recht bei seinem
                        Erscheinen im Jahre 2004 als eines der Top-Alben eingestuft. Seine Homepage sowie die diversen
                        Links sind eine wahre musikalische Fundgrube.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Bob Livingston</strong> wird auf seiner Tournee begleitet von{' '}
                        <strong>Bradley Kopp</strong>(der u.a. mit Bob Dylan, Little Feat, Boz Scaggs oder den einzelnen
                        Flatlanders aufgetreten ist) sowie von <strong>Richard Bowden </strong>(der jahrelang an der
                        Seite von Linda Ronstadt, der Country-Rock K&ouml;nigin der 70er-Jahre, mit Don Henley von den
                        Eagles und mit Roger McQuinn gespielt hat).
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2009_bs_01.jpg"
                            alt="TEXAS SWEET HOT TRIO"
                        />
                    </p>
                    <p className="legendentext">
                        Bettysoo Foto&copy;:
                        <br />
                        unbekannt
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2009_at_01.jpg"
                            alt="TEXAS SWEET HOT TRIO"
                        />
                    </p>
                    <p className="legendentext">
                        Abi Tapia Foto&copy;:
                        <br />
                        unbekannt
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2009_cf_01.jpg"
                            alt="TEXAS SWEET HOT TRIO"
                        />
                    </p>
                    <p className="legendentext">
                        Charlie Faye Soo Foto&copy;:
                        <br />
                        unbekannt
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>TEXAS SWEET HOT TRIO</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Mittwoch, 21. Oktober 2009
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.myspace.com/texassweethottrio" target="_blank" rel="noreferrer">
                                www.myspace.com/texassweethottrio
                            </a>
                        </p>
                        <p>
                            <a href="http://www.bettysoo.com" target="_blank" rel="noreferrer">
                                www.bettysoo.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.charliefaye.com" target="_blank" rel="noreferrer">
                                www.charliefaye.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.abitapia.com" target="_blank" rel="noreferrer">
                                www.abitapia.com
                            </a>
                        </p>
                    </div>
                    <h3>
                        Bettysoo &ndash; Abi Tapia &ndash; Charlie Faye
                        <br />
                        Die mit Spannung erwartete Europa-Premiere der drei sehr talentierten und noch jungen
                        Singer-Songwriterinnen
                    </h3>
                    <p>
                        Die drei Singer-Songwriterinnen <strong>Bettysoo, Charlie Faye </strong>und{' '}
                        <strong>Abi Tapia</strong>haben sich zum Texas Sweet Hot Trio zusammengefunden. Sie alle kommen
                        aus Austin, Texas. Sie sind noch relativ unbekannt, doch wer weiss, vielleicht werden wir den
                        einen oder anderen Namen schon bald besser kennen. Ihre Lieder handeln vom t&auml;glichen Leben,
                        von der Liebe, den Naturkatastrophen und vielem mehr. Ihre Vorbilder sind Townes van Zandt,
                        Emmylou Harris, Willie Nelson und Nanci Griffith.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die drei Frauen haben als Solointerpretinnen bereits verschiedene Liederschreiber-Wettbewerbe
                        gewonnen und sind an den prestigetr&auml;chtigen Folk-Festivals in Washington und in Kerrville
                        aufgetreten. Die drei K&uuml;nstlerinnen haben sich entschieden, zusammen den europ&auml;ischen
                        Kontinent zu erobern. F&uuml;r sie ist es der erste Trip nach Europa und sie sind sehr gespannt,
                        wie ihre akustische Musik bei uns Gefallen finden wird. Neben diversen Konzerten in den
                        Benelux-Staaten sind auch Auftritte in England, Deutschland und der Schweiz geplant. Die drei
                        Frauen spielen Gitarre, Mandoline, Harmonica und singen Lead- und Background-Vocals. Alle haben
                        neue Alben im Gep&auml;ck.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Bettysoo</strong> gilt in den texanischen Insiderkreisen dank ihrer
                        aussergew&ouml;hnlichen Stimme als eine der kommenden grossen Interpretinnen. Ihr Folk-Rock
                        erinnert an Emmylou Harris, Alison Krauss und Patty Griffin. Ihr neues Album wurde von Gurf
                        Morlix produziert, der u.a. schon erfolgreiche Werke von Lucinda Williams, Ray Wylie Hubbard
                        oder Robert Earl Keen produziert hat.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Das Energieb&uuml;ndel <strong>Charlie Faye</strong> besticht mit ihrer leicht bluesigen Stimme.
                        Ihre Musik lehnt sich prim&auml;r an den Folk-Rock und Roots Rock an. Ihre Stimme &auml;hnelt
                        jener von Mary Chapin Carpenter. Auf ihrem soeben erschienenen Erstlingswerk spielen einige der
                        Top-Musiker von Austin.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Abi Tapia</strong> f&uuml;hlt sich sowohl in der Country als auch in der Folk-Musik zu
                        Hause. Ihre ehrlichen Lieder und eing&auml;ngigen Melodien m&uuml;ssten eigentlich schon bald
                        bei einem breiteren Publikum Anklang finden. Abi Tapia wird seit einigen Jahren sehr stark
                        gef&ouml;rdert von den uns bekannten und beliebten Christine Albert &amp; Chris Gage, was ja
                        schon ein Hinweis auf ihr musikalisches Potenzial ist.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Dass mehrere K&uuml;nstlerInnen gleichzeitig miteinander die B&uuml;hne teilen, ist gerade in
                        Austin keine Seltenheit. Ber&uuml;hrungs&auml;ngste kennen die texanischen MusikerInnen ohnehin
                        nicht, im Gegenteil unterstreichen sie mit solchen Auftritten ihren gegenseitigen Respekt und
                        ihre Wertsch&auml;tzung f&uuml;r das jeweilige musikalische Schaffen des anderen.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Wer weiss, vielleicht steht wenigstens eine der drei Interpretinnen aus dem Texas Sweet Hot Trio
                        schon bald im gr&ouml;sseren Scheinwerferlicht. Packen wir also die Gelegenheit, diese jungen
                        K&uuml;nsterlinnen am Anfang ihrer Karrieren als Veranstalter oder Besucher zu begleiten.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2009_mb_01.jpg"
                            alt="MIKE BLAKELY"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Annie Blakely
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2013_tmr_01.jpg"
                            alt="MIKE BLAKELY"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>MIKE BLAKELY &amp; THOMAS MICHAEL RILEY</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Montag, 27. und Dienstag, 28. April 2009
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.mikeblakely.com" target="_blank" rel="noreferrer">
                                www.mikeblakely.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.thomasmichaelriley.com" target="_blank" rel="noreferrer">
                                www.thomasmichaelriley.com
                            </a>
                        </p>
                    </div>
                    <h3>Mit THE WHISKEY TRADERS</h3>
                    <p>
                        Auch bekannt und hierzulande immer wieder gern gesehen und geh&ouml;rt ist S&auml;nger,
                        Songschreiber und Buchautor Mike Blakely, der uns Ende April besucht. Aber aufgepasst: Erstmals
                        bringt er seinen Freund Thomas Michael Riley sowie seine Original US-Band mit in die Schweiz!
                        Thomas Michael Riley (TMR), als Hill Country Entertainer der Jahre 2002, 2004 ausgezeichnet,
                        entschied sich erst 2002, seiner Leidenschaft zu folgen und voll auf die Karte Musik zu setzen.
                        So wuchs seine Fan-Gemeinde nicht nur in Texas, viel ist TMR heute im Begriff, eine nationale
                        Gr&ouml;sse zu werden, weshalb er regelm&auml;ssig zwischen Nashville und Texas pendelt. Mit
                        seiner Qualit&auml;t als Interpret von lebhaften wie auch nachdenklichen Liedern sowie mit
                        seiner Baritonstimme zieht er die Zuh&ouml;rer in seinen Bann.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die beiden texanischen K&uuml;nstler werden begleitet von Mikes hervorragender US-Band
                        <strong>The Whiskey Traders</strong>: <strong>Larry Nye</strong> (Gitarrist) und
                        <strong>Donnie Price</strong> (Bassist) (beide von fr&uuml;heren Touren ein Begriff), sowie{' '}
                        <strong>Jeff Brosch</strong> (Drums) und <strong>Scott Martin</strong> (Steelgitarre, Dobro).
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2012_ag_01.jpg"
                            alt="ALBERT AND GAGE"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        W&ouml;lfli Imhof
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>ALBERT &amp; GAGE</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Mittwoch, 11. M&auml;rz 2009 und Mittwoch, 13.
                                Oktober 2010
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19 Uhr)
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.albertandgage.com" target="_blank" rel="noreferrer">
                                www.albertandgage.com
                            </a>
                        </p>
                        <p>
                            <a href="http://www.myspace.com/albertandgage" target="_blank" rel="noreferrer">
                                www.myspace.com/albertandgage
                            </a>
                        </p>
                    </div>
                    <h3>Exquisite Musik aus Austin, TX</h3>
                    <p>
                        Die Musik von Albert &amp; Gage einzuordnen ist sehr schwierig. Das Duo besticht stets mit einem
                        unglaublich abwechslungsreichen Programm. F&uuml;r mich spielen sie vor allem &quot;music made
                        in Texas&quot;, und diese Musik umfasst nebst Country und Folk auch Blues, Soul, Swing, Jazz und
                        sogar Chansons. Mit ihren perfekt harmonierenden Stimmen und ihrem instrumentalen K&ouml;nnen
                        entfalten Albert &amp; Gage eine erstaunliche Klangf&uuml;lle. Akzentuiert wird ihre Musik im
                        besonderen von Chris Gage&apos;s leidenschaftlichem Klavierspiel sowie seinen virtuosen und
                        melodi&ouml;sen Gitarrensoli. Gage kitzelt dabei das Klavier genau so souver&auml;n wie seine
                        Gitarre. Christine Albert hingegen ist mit ihrer Gitarre f&uuml;r den Rhythmus und die Harmonie
                        zust&auml;ndig.
                    </p>
                    <p>&nbsp; </p>
                    <p>
                        Albert &amp; Gage ernteten bei den beiden bisherigen Auftritten in Laufen jeweils frenetischen
                        Applaus und stehende Ovationen ohne Ende. Wer das Duo schon einmal live erlebt hat, wird auch
                        das n&auml;chste Konzert nicht verpassen wollen. Albert &amp; Gage werden von{' '}
                        <strong>David Carroll</strong> am Contrabass begleitet, der mal f&uuml;r eine dezente, mal
                        f&uuml;r eine fetzige rhythmische Unterlage sorgen wird.
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            Um diese ... so herrlich unkompliziert, aufreizend elektrisierend und dann auch wieder bis
                            in die tiefste Seele gef&uuml;hlvoll gemachte Musik zu erleben, ... lohnt sich jeder weite
                            Weg.
                            <span className="color3 quelle">
                                <br />
                                &ndash; Rudolf Wesner
                            </span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <hr />
        </>
    );
};

export default Archive2009;
