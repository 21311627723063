import { Alert, AlertTitle, Typography } from '@mui/material';
import { CommentDtoCommentType, ICommentDto, CommentDtoLevel } from '../../clients/services';
import parse from 'html-react-parser';

export const Comment = (props: ICommentDto) => {
    const getLevel = (level?: CommentDtoLevel) => {
        switch (level) {
            case CommentDtoLevel.Success:
                return 'success';
            case CommentDtoLevel.Info:
                return 'info';
            case CommentDtoLevel.Warning:
                return 'warning';
            case CommentDtoLevel.Error:
                return 'error';
            default:
                return 'info';
        }
    };

    const getClassName = (level?: CommentDtoLevel) => {
        switch (level) {
            case CommentDtoLevel.Error:
                return 'color2';
            case CommentDtoLevel.Info:
            case CommentDtoLevel.Warning:
                return 'color3';
            default:
                return 'color1';
        }
    };

    if (props.commentType === CommentDtoCommentType.Alert) {
        return (
            <Alert severity={getLevel(props.level)}>
                {props.title && <AlertTitle>{props.title}</AlertTitle>}
                <Typography className={props.className}>{parse(props.message)}</Typography>
            </Alert>
        );
    }

    const className = props.className ?? getClassName(props.level);
    return (
        <>
            <div className={className} style={{ marginTop: 10, marginBottom: 10 }}>
                {parse(props.message)}
            </div>
        </>
    );
};

export default Comment;
