import { NavLink } from 'react-router-dom';
import SideGallery from '../../components/SideGallery';
import { PageRoutes } from '../../page-routes';
import { getNewestPlaylist } from '../../clients/playlist-data';
import { DateTime } from 'luxon';

export const PlaylistPreview = () => {
    const newestPlaylist = getNewestPlaylist();

    const images = [];
    if (newestPlaylist.image) {
        images.push({
            link: newestPlaylist.image,
            title: newestPlaylist.playlist[0].artist + ' - ' + newestPlaylist.playlist[0].title,
        });
    }
    return (
        <SideGallery images={images} title="Martin's Playlist">
            <>
                <p>
                    Hier stelle ich euch jeweils ein paar meiner aktuellen Music-Hits vor. Ihr findet darunter immer
                    wieder h&ouml;renswerte (Geheim-)Tipps!
                    <br />
                </p>
                <p>&nbsp;</p>
                <NavLink to={PageRoutes.Playlists}>
                    Playlist,{' '}
                    {DateTime.fromObject({ year: newestPlaylist.year, month: newestPlaylist.month }).toFormat('LLLL')}{' '}
                    {newestPlaylist.year}
                </NavLink>
            </>
        </SideGallery>
    );
};

export default PlaylistPreview;
