import { IToken } from '../auth/auth-store';

export interface ITokenAccessor {
    getToken(): IToken | null;
}

export interface IClientFactory {
    createSecuredClient<T1>(
        ctor: new (baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) => T1,
    ): T1;

    createClient<T1>(
        ctor: new (baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) => T1,
    ): T1;

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    createSpecialResponseClient<T1>(
        ctor: new (baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) => T1,
        handleResponse: (response: Response) => Promise<Response>,
        secured?: boolean,
    ): T1;
}

export class ClientFactory implements IClientFactory {
    constructor(private readonly tokenAccessor: ITokenAccessor, private readonly baseUrl: string | undefined) {}

    protected transformOptions = (options?: RequestInit): RequestInit | undefined => {
        const token = this.tokenAccessor.getToken();
        if (!token) {
            return options;
        }

        if (options == null) {
            return {
                headers: {
                    authorization: 'Bearer ' + token.token,
                },
            };
        }

        options.headers = {
            ...options.headers,
            authorization: 'Bearer ' + token.token,
        };
        return options;
    };

    createSecuredClient<T1>(
        ctor: new (baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) => T1,
    ): T1 {
        const http = {
            fetch: (url: RequestInfo, init?: RequestInit) => window.fetch(url, this.transformOptions(init)),
        };
        return new ctor(this.baseUrl, http);
    }

    createClient<T1>(
        ctor: new (baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) => T1,
    ): T1 {
        return new ctor(this.baseUrl);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    createSpecialResponseClient<T1>(
        ctor: new (baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) => T1,
        handleResponse: (response: Response) => Promise<Response>,
        secured?: boolean,
    ) {
        const http = {
            fetch: async (url: RequestInfo, init?: RequestInit) => {
                const response = secured
                    ? await window.fetch(url, this.transformOptions(init))
                    : await window.fetch(url);
                const transferedResponse = await handleResponse(response);
                return transferedResponse;
            },
        };
        return new ctor(this.baseUrl, http);
    }
}
