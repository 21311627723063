import React from 'react';
import { ISeatDisplayInfo } from '../services/plan-info';
import { AddressModel as Address } from '../models/address.model';
import { Reservations } from '../parts/Reservations';
import { ReservationForm } from '../forms/ReservationForm';
import { actionContainerStyle } from '../styles';
import { OptionsService } from '../../../services/price-calculator';

import { ISaleOptionDto } from '../../../clients/services';
import { ISeatSelection } from '../services/seat-selection';

type Props = {
    desired: ISeatSelection[];
    numberOfSeats: number;
    address: Address;
    onContinue: (reservationForm: Address) => Promise<void>;
    onBack: (reservationForm: Address) => void;
    onClickSeat: (seat: ISeatDisplayInfo) => void;
    optionsService: OptionsService;
    changeOptions: (reservation: ISeatSelection, options: ISaleOptionDto[]) => void;
};

export const EnterAddress: React.FC<Props> = (props) => {
    return (
        <>
            <Reservations
                reservations={props.desired}
                numberOfSeats={props.numberOfSeats}
                optionsService={props.optionsService}
                countReservations={props.numberOfSeats}
            ></Reservations>
            <div style={{ ...actionContainerStyle, paddingRight: 40 }}>
                <ReservationForm onContinue={props.onContinue} address={props.address} onBack={props.onBack} />
            </div>
        </>
    );
};
