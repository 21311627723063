import { MessageDtoType } from './services';

export function messageType(messageType: MessageDtoType) {
    switch (messageType) {
        case MessageDtoType.ERROR:
            return 'error';
        case MessageDtoType.INFO:
            return 'info';
        case MessageDtoType.WARNING:
            return 'warning';
        case MessageDtoType.SUCCESS:
            return 'success';
        default:
            return 'info';
    }
}
