export const Archive2006 = () => {
    return (
        <div className="konzert">
            <div className="image_wrapper">
                <p>
                    <img
                        className="image_border"
                        style={{ width: 166 }}
                        src="/images/konzerte/konzert_2006_dw_01.jpg"
                        alt="DALE WATSON"
                    />
                </p>
                <p className="legendentext">
                    Foto&copy;:
                    <br />
                    Dale Watson
                </p>
            </div>
            <div className="text_image_left">
                <h2>DALE WATSON</h2>
                <div className="frame_1">
                    <p>
                        <b>
                            <span className="color1">DATUM:</span> Freitag, 24. und Samstag, 25. November 2006
                            <br />
                            <span className="color1">KONZERTORT:</span> Little Nashville, Liesberg
                        </b>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <a href="http://www.theconnextion.com/dalewatson" target="_blank" rel="noreferrer">
                            www.theconnextion.com/dalewatson
                        </a>
                    </p>
                </div>
                <h3>Texanische Countrymusik</h3>
                <p>
                    Dale Watson zog schon in seiner Jugend nach Texas, in die N&auml;he von Houston. Dies pr&auml;gte
                    seinen Musikstil so stark, dass er h&auml;ufig als Vertreter der urspr&uuml;nglichen texanischen
                    Countrymusik betrachtet wird. Er wuchs in einer musikalischen Familie auf, und begann bereits mit
                    zw&ouml;lf Jahren eigene Lieder und Texte zu verfassen. Nach dem Besuch der Highschool arbeitete und
                    musizierte er mehrere Jahre lang in lokalen Clubs und Tanzs&auml;len. Im Jahr 1988 zog er nach Los
                    Angeles und erhielt dort ein Engagement bei der Band des renommierten Palomino Clubs.
                </p>
                <p>&nbsp;</p>
                <p>
                    1990 nahm er seine ersten Singles auf, denen 1992 eine weitere folgte. Nach einem kurzen
                    Arbeitsaufenthalt als Komponist in Nashville, kehrte er nach Austin, Texas zur&uuml;ck. Nach drei
                    Jahren ver&ouml;ffentlichte er 1995 sein Deb&uuml;talbum, welchem nun j&auml;hrlich weitere folgten.
                    Der Stil schwankte dabei zwischen klassischen Country- und Truck-Country-Songs.
                    <br />
                    <span className="color3 quelle" style={{ fontStyle: 'italic' }}>
                        &ndash; Wikipedia
                    </span>
                </p>
            </div>
        </div>
    );
};

export default Archive2006;
