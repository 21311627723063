import { Alert, AlertTitle } from '@mui/material';
import { MessageDto, IConcertDto, MessageDtoType } from '../../../clients/services';
import parse from 'html-react-parser';

export const RestrictionMessage = (props: { concert: IConcertDto }) => {
    const getMessageTitle = (message: MessageDto) => {
        switch (message.type) {
            case MessageDtoType.ERROR:
                return 'Fehler';
            case MessageDtoType.WARNING:
                return 'Achtung';
            case MessageDtoType.INFO:
                return 'Info';
            case MessageDtoType.SUCCESS:
            default:
                return 'Info';
        }
    };

    const getSeverity = (message: MessageDto) => {
        switch (message.type) {
            case MessageDtoType.ERROR:
                return 'error';
            case MessageDtoType.WARNING:
                return 'warning';
            case MessageDtoType.INFO:
                return 'info';
            case MessageDtoType.SUCCESS:
            default:
                return 'success';
        }
    };

    return (
        <>
            {props.concert.restrictionOptions?.message && (
                <Alert
                    severity={getSeverity(props.concert.restrictionOptions?.message)}
                    style={{ padding: 10, margin: 5 }}
                >
                    <AlertTitle>
                        <b>{getMessageTitle(props.concert.restrictionOptions?.message)}</b>
                    </AlertTitle>
                    {parse(props.concert.restrictionOptions?.message.text)}
                </Alert>
            )}
        </>
    );
};
