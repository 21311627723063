export enum SeatRestriction {
    Wasted = 'wasted',
    NextToReserved = 'nextToReserved',
    TooManyNextToEachOther = 'tooManyNextToEachOther',
    TooManySeats = 'tooManySeats',
    AllSeatsChosen = 'allSeatsChosen',
    RowIsBlocked = 'rowIsBlocked',
    SeatIsBlocked = 'seatIsBlocked',
    None = 'none',
    BlockIsBlocked = 'blockIsBlocked',
    NotInSameRow = 'notInSameRow',
    DoesNotExist = 'doesNotExist',
    Reserved = 'reserved',
}
