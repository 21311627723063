type LinkProps = {
    title?: string;
    url: string;
};

export const ArtistLink = (props: LinkProps) => {
    return (
        <>
            <p>
                <a href={props.url} title={props.title} target="_blank" rel="noreferrer">
                    {props.title}
                </a>
            </p>
        </>
    );
};

export default ArtistLink;
