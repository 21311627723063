import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

export enum StepType {
    NumberOfSeats = 0,
    SelectSeat = 1,
    Address = 3,
    ContractTracingData = 4,
    Confirm = 5,
}

type Props = {
    activeStep?: StepType;
    nrOfSeats: number | undefined;
    contactTracing?: boolean;
    namesOnly?: boolean;
};

const completed = 'Danke für deine Reservation.';

export const ReservationStepper: React.FC<Props> = (props: Props) => {
    const stepTypes: StepType[] = [StepType.NumberOfSeats, StepType.SelectSeat];
    const steps: { label: string; text: string }[] = [
        {
            label: 'Anzahl Sitze angeben',
            text: 'Bitte gib die Anzahl Sitze an, die du reservieren willst.',
        },
        {
            label:
                props.nrOfSeats === undefined || props.nrOfSeats < 2
                    ? 'Sitzplatz wählen'
                    : props.nrOfSeats + ' Sitzplätze wählen',
            text: 'Bitte wähle im untenstehenden Plan die gewünschten Sitze aus.',
        },
    ];

    steps.push({
        label: 'Adresse angeben',
        text: 'Bitte gib deine vollständige Post-Adresse im untenstehenden Formular an! Über die angegebene Email-Adresse erhältst du Zugriff auf dein elektronisches Ticket.',
    });
    stepTypes.push(StepType.Address);

    if (props.contactTracing) {
        steps.push({
            label: 'Contact Tracing Daten',
            text:
                (props.namesOnly
                    ? 'Bitte erfasse die Namen aller Personen, für welche du die Sitzplätze gebucht hast. '
                    : 'Bitte erfasse die Namen, Wohnort und Telefonnummer aller Personen, für welche du die Sitzplätze gebucht hast. ') +
                'Nach dem Abschicken erhältst du eine Email mit den Zahlungsinformationen.',
        });
        stepTypes.push(StepType.ContractTracingData);
    }

    steps.push({
        label: 'Reservation bestätigen',
        text: 'Eine Email mit den Zahlungsinformationen wurde an deine Adresse geschickt.',
    });
    stepTypes.push(StepType.Confirm);

    const activeStep = props.activeStep ? steps[stepTypes.indexOf(props.activeStep)] : steps[0];

    return (
        <div
            style={{
                backgroundImage: 'url(/images/bg_konzerte_box.png)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top right',
                color: '#fff',
                backgroundColor: '#2a578e',
                borderBottom: '3px solid #8ba2c2',
                borderRadius: '5px',
                padding: '10px',
            }}
        >
            <div style={{ margin: '20px 20px 22px 22px', padding: 10, backgroundColor: 'white' }}>
                <Stepper activeStep={props.activeStep} alternativeLabel style={{ margin: 20 }}>
                    {steps.map((step, i) => (
                        <Step key={i}>
                            <StepLabel>{step.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <div>
                {activeStep ? (
                    <div>
                        <Typography
                            sx={{
                                marginTop: 5,
                                marginBottom: 5,
                            }}
                        >
                            {activeStep.text}
                        </Typography>
                    </div>
                ) : (
                    <div>
                        <Typography
                            sx={{
                                marginTop: 5,
                                marginBottom: 5,
                            }}
                        >
                            {completed}
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};
