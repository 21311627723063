import { AdminControllerClient, IOrderDataDto, IConcertOrdersDto } from '../clients/services';
import clientFactory from './client-factory';
export class OrderService {
    private readonly adminClient: AdminControllerClient;
    constructor() {
        this.adminClient = clientFactory.createSecuredClient(AdminControllerClient);
    }
    async getOrdersForSeriesAsync(concertSeriesId: number): Promise<IOrderDataDto[]> {
        return await this.adminClient.getOrdersForSeries(concertSeriesId);
    }
    async getOrdersForConcertAsync(concertId: number): Promise<IConcertOrdersDto> {
        return await this.adminClient.getOrdersForConcert(concertId);
    }

    async searchAsync(search: string, concertId: number, concertSeriesId: number): Promise<IOrderDataDto[]> {
        return await this.adminClient.search(concertId, concertSeriesId, search);
    }

    async setPayedAsync(orderId: number, payed: boolean): Promise<void> {
        return await this.adminClient.setPayed(orderId, payed);
    }

    async setCanceledAsync(orderId: number, canceled: boolean): Promise<void> {
        return await this.adminClient.setCanceled(orderId, canceled);
    }

    async deleteOrderAsync(orderId: number): Promise<void> {
        return await this.adminClient.deleteOrder(orderId);
    }
}
