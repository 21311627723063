import { IConcertSeriesDto } from '../../clients/services';
import parse from 'html-react-parser';

const getHighlights = (series: IConcertSeriesDto) => {
    const highlightWords = [];
    if (series.title) {
        highlightWords.push(series.title);
    }

    series.artists?.forEach((a) => highlightWords.push(a.name));
    series.content?.highlight?.forEach((h) => highlightWords.push(h));
    return highlightWords;
};

const highlight = (text: string, series: IConcertSeriesDto) => {
    const highlights = getHighlights(series);
    let newText = text.slice();
    for (const highLight of highlights) {
        newText = newText.replace(highLight, `<b>${highLight}</b>`);
    }
    return newText;
};

export const TextParagraph = ({ text, series }: { text: string; series: IConcertSeriesDto }) => (
    <p style={{ marginTop: 20 }}>{parse(highlight(text, series))}</p>
);
