import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { ISaleOptionDto } from '../../../clients/services';
import { OptionsService } from '../../../services/price-calculator';
import { useEffect, useState } from 'react';
import { ISeatSelection } from '../services/seat-selection';

type Props = {
    reservation: ISeatSelection;
    countReservations: number;
    optionsService: OptionsService;
    changeOptions?: (reservation: ISeatSelection, options: ISaleOptionDto[]) => void;
};

const OptionLabel = (props: { option: ISaleOptionDto }) => (
    <>
        {props.option.text ? <>{props.option.text}</> : <>Normales Ticket</>} {<>CHF {props.option.price}.-</>}
    </>
);

export const SeatReservationInfoView = (props: Props) => {
    const [selectableOptions, setSelectableOptions] = useState(
        props.optionsService.getSelectableOptions(props.reservation, props.countReservations) ?? [],
    );
    const [choiceOptions, setChoiceOptions] = useState(
        props.optionsService
            .getSelectableOptions(props.reservation, props.countReservations)
            ?.filter((o) => !o.isAdditional) ?? [],
    );
    const [additions, setAdditions] = useState(
        props.optionsService
            .getSelectableOptions(props.reservation, props.countReservations)
            ?.filter((o) => o.isAdditional) ?? [],
    );

    useEffect(() => {
        const newSelectableOptions =
            props.optionsService.getSelectableOptions(props.reservation, props.countReservations) ?? [];
        setSelectableOptions(newSelectableOptions);
        setChoiceOptions(newSelectableOptions.filter((o) => !o.isAdditional));
        setAdditions(newSelectableOptions.filter((o) => o.isAdditional));
    }, [props.reservation, props.optionsService, props.countReservations]);

    const changeChoice = (option?: ISaleOptionDto) => {
        if (props.changeOptions) {
            const newSaleOptions: ISaleOptionDto[] = props.reservation.options?.filter((o) => o.isAdditional) ?? [];
            if (option) {
                newSaleOptions.push(option);
            }
            props.changeOptions(props.reservation, newSaleOptions);
        }
    };

    const addSaleOption = (option: ISaleOptionDto) => {
        if (props.changeOptions) {
            const newOptions: ISaleOptionDto[] = props.reservation.options?.filter((o) => o.id !== option.id) ?? [];
            newOptions.push(option);
            props.changeOptions(props.reservation, newOptions);
        }
    };

    const removeSaleOption = (option: ISaleOptionDto) => {
        if (props.changeOptions) {
            const newSaleOptions: ISaleOptionDto[] = props.reservation.options?.filter((o) => o.id !== option.id) ?? [];
            props.changeOptions(props.reservation, newSaleOptions);
        }
    };

    return (
        <Grid
            item
            xs={12}
            style={{
                width: '100%',
                textAlign: 'center',
                backgroundColor: '#0d2f4e',
                margin: '1px',
                padding: '2px',
                borderRadius: '5px',
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <img
                        style={{
                            height: '33px',
                            width: 'auto',
                            verticalAlign: 'middle',
                            margin: '3px 30px 3px 0px',
                        }}
                        src="/images/tickets.png"
                        alt="Ticket"
                    />
                    <span>
                        {props.reservation.blockName ? `${props.reservation.blockName} - ` : ''}Reihe{' '}
                        {props.reservation.row} / Sitz {props.reservation.seat}
                        {' - '}
                        CHF {props.optionsService.getTicketPrice(props.reservation, props.countReservations).toFixed(0)}
                        .-
                    </span>
                </Grid>

                {selectableOptions.length > 1 && (
                    <Grid item xs={12} style={{ textAlign: 'left', color: 'black' }}>
                        <Grid container>
                            {choiceOptions.length > 0 && (
                                <Grid item xs={12}>
                                    <FormControl
                                        style={{
                                            backgroundColor: 'white',
                                            width: '100%',
                                        }}
                                    >
                                        {props.changeOptions && (
                                            <FormLabel
                                                id={`options-${props.reservation.blockId}-${props.reservation.row}-${props.reservation.seat}`}
                                                style={{
                                                    textAlign: 'left',
                                                    marginTop: 10,
                                                    marginLeft: 10,
                                                    color: 'black',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Optionen
                                            </FormLabel>
                                        )}
                                        <RadioGroup
                                            aria-labelledby={`options-${props.reservation.blockId}-${props.reservation.row}-${props.reservation.seat}`}
                                            value={
                                                props.reservation.options?.find((o) => !o.isAdditional && !o.dependsOn)
                                                    ?.id ?? 0
                                            }
                                            onChange={(event: { target: { value: string | undefined } }) => {
                                                changeChoice(
                                                    choiceOptions.find((o) => o.id?.toString() === event.target.value),
                                                );
                                            }}
                                            name={`radio-buttons-${props.reservation.blockId}-${props.reservation.row}-${props.reservation.seat}`}
                                            style={{
                                                textAlign: 'left',
                                                marginLeft: 10,
                                            }}
                                        >
                                            {(props.changeOptions
                                                ? choiceOptions
                                                : props.reservation.options?.filter((o) =>
                                                      choiceOptions.some((op) => op.id === o.id),
                                                  ) ?? []
                                            ).map((o) => (
                                                <FormControlLabel
                                                    key={o.id}
                                                    value={o.id}
                                                    control={<Radio />}
                                                    style={{ padding: 0 }}
                                                    label={<OptionLabel option={o} />}
                                                    disabled={!props.changeOptions || choiceOptions.length < 2}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            )}
                            {additions.length > 0 && (
                                <Grid item xs={12} style={{ backgroundColor: 'white', paddingLeft: 10 }}>
                                    <FormGroup>
                                        {props.changeOptions ? (
                                            <>
                                                {additions.map((o) => (
                                                    <FormControlLabel
                                                        key={o.id}
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => {
                                                                    if (event.target.checked) {
                                                                        addSaleOption(o);
                                                                    } else {
                                                                        removeSaleOption(o);
                                                                    }
                                                                }}
                                                                checked={props.reservation.options?.some(
                                                                    (a) => a.id === o.id,
                                                                )}
                                                            />
                                                        }
                                                        label={<OptionLabel option={o} />}
                                                    />
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {props.reservation.options
                                                    ?.filter((o) => o.isAdditional)
                                                    .map((o, k) => (
                                                        <FormControlLabel
                                                            key={o.id}
                                                            control={
                                                                <Checkbox
                                                                    checked={props.reservation.options?.some(
                                                                        (a) => a.id === o.id,
                                                                    )}
                                                                    disabled={true}
                                                                />
                                                            }
                                                            label={<OptionLabel option={o} />}
                                                        />
                                                    ))}
                                            </>
                                        )}
                                    </FormGroup>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
