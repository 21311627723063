import { useState, useEffect } from 'react';
import {
    IConcertDto,
    ImageDto,
    ImageDtoType,
    IPlanDto,
    ISeatReservationDto,
    IContactTracingDataDto,
    ContactTracingDataDto,
    ContactTracingInfoDto,
    PersonDto,
    IConcertSeriesDto,
    MessageDto,
    ResponseDto,
    CommentDtoCommentType,
    IResponseDto,
    ISaleOptionDto,
    ITicketSaleCheckResponseDto,
    SaleOptionDto,
    TicketSaleCheckResponseDto,
    MessageDtoType,
} from '../../clients/services';
import { MainContent } from '../../components';
import { ReservationService } from '../../services';
import { messageType } from '../../clients/message-type-mapper';
import { ReservationStepper, StepType } from './steps/ReservationStepper';
import { PlanFactory } from './services/plan-factory';
import { RestrictionChecker } from './services/restriction-checker';
import { SeatState } from './models/seat-state';
import { SelectNumberOfSeats } from './steps/SelectNumberOfSeats';
import { SelectSeats } from './steps/SelectSeats';
import { EnterAddress } from './steps/EnterAddress';
import { EnterContractTracingData } from './steps/EnterContractTracingData';
import { ConfirmReservation } from './steps/ConfirmReservation';
import { AddressModel } from './models/address.model';
import { DateFormat } from 'cm-utils';
import { RootDiv } from './styles';
import { useSnackbar } from 'notistack';
import { correctPic } from '../../helpers/correct-pic';
import { Alert } from '@mui/material';
import { DetailedConcertInfo } from '../../components/ConcertPreview/DetailedConcertInfo';
import { OptionsService } from '../../services/price-calculator';
import { ISeatSelection } from './services/seat-selection';
import { IBlockDisplayInfo, ISeatDisplayInfo } from './services/plan-info';
import parse from 'html-react-parser';

const service = new ReservationService();

export const Tickets = (props: { concertId: number; earlyAccessGuid?: string }) => {
    const [ticketSaleErrorMessage, setTicketErrorSaleMessage] = useState<ITicketSaleCheckResponseDto | undefined>(
        undefined,
    );
    const [ticketOrderResponse, setTicketOrderResponse] = useState<IResponseDto | undefined>(undefined);
    const [concert, setConcert] = useState<IConcertDto | undefined>(undefined);
    const [concertSeries, setConcertSeries] = useState<IConcertSeriesDto | undefined>(undefined);
    const [plan, setPlan] = useState<IPlanDto | undefined>(undefined);
    const [reserved, setReserved] = useState<ISeatReservationDto[]>([]);
    const [desired, setDesired] = useState<ISeatSelection[]>([]);
    const [step, setStep] = useState<StepType>(StepType.NumberOfSeats);
    const [numberOfSeats, setNumberOfSeats] = useState<number>(0);
    const [optionsService, setOptionsService] = useState<OptionsService | undefined>(undefined);
    const [address, setAddress] = useState<AddressModel>({} as AddressModel);
    const [blocks, setBlocks] = useState<{
        blocks: IBlockDisplayInfo[];
        possibilities: ISeatDisplayInfo[][];
        maxSeats: number;
    }>();
    const [planFactory, setPlanFactory] = useState<PlanFactory>();
    const [tracingData, setTracingData] = useState<IContactTracingDataDto>({
        contacts: [],
    } as IContactTracingDataDto);

    const { enqueueSnackbar } = useSnackbar();

    const changeOptions = (reservation: ISeatSelection, options: ISaleOptionDto[]) => {
        const newReservation = {
            ...reservation,
            options: options.map((o) => new SaleOptionDto(o)),
            price: options.reduce((sum, o) => sum + o.price, 0),
        };
        const newDesired = desired.map((d) =>
            d.blockId === reservation.blockId && d.row === reservation.row && d.seat === reservation.seat
                ? newReservation
                : d,
        );
        setDesired(newDesired);
    };

    const sendOrder = async (
        theAddress: AddressModel,
        theSeats: ISeatReservationDto[],
        theTracingData?: IContactTracingDataDto,
    ) => {
        if (numberOfSeats === 1 && concert?.restrictionOptions?.requireContactTracing) {
            theTracingData = new ContactTracingDataDto({
                contacts: [
                    new ContactTracingInfoDto({
                        firstName: theAddress.firstName,
                        lastName: theAddress.lastName,
                        street: theAddress.street,
                        zip: theAddress.zip,
                        tel: theAddress.tel,
                        city: theAddress.city,
                        country: theAddress.country,
                    }),
                ],
            });
            setTracingData(theTracingData);
        }

        const seatReservations = theSeats.map((d) => d);
        const person = new PersonDto({
            id: 0,
            firstName: theAddress.firstName,
            lastName: theAddress.lastName,
            street: theAddress.street,
            zip: theAddress.zip,
            city: theAddress.city,
            email: theAddress.email,
            tel: theAddress.tel,
        });
        try {
            const response = await service.reserve(
                props.concertId,
                seatReservations,
                person,
                theTracingData,
                props.earlyAccessGuid,
            );

            setTicketOrderResponse(response);
            for (const message of response.messages) {
                enqueueSnackbar(message.text, { variant: messageType(message.type) });
            }

            if (response.isSuccessful) {
                setStep(StepType.Confirm);
            } else {
                setStep(StepType.SelectSeat);
                await refreshReservations(props.concertId);
            }
        } catch {
            const message =
                'Wegen eines technischen Problemes konnte die Bestellung leider nicht durchgeführt werden. Bitte kontaktiere mich per Telefon.';
            enqueueSnackbar(message, { variant: messageType(MessageDtoType.ERROR) });
            setTicketOrderResponse(
                new ResponseDto({
                    isSuccessful: false,
                    messages: [new MessageDto({ text: message, type: MessageDtoType.ERROR, code: 1 })],
                }),
            );
        }
    };

    const continueToEnterTracingData = async (
        theAddress: AddressModel,
        theSeats: ISeatSelection[],
        theTracingData: IContactTracingDataDto,
    ) => {
        setAddress(theAddress);
        if (numberOfSeats === 1) {
            await sendOrder(theAddress, theSeats, theTracingData);
        } else {
            setStep(StepType.ContractTracingData);
        }
    };

    const clickSeat = (seat: ISeatDisplayInfo) => {
        if (seat.state === SeatState.Free) {
            const newDesired: ISeatSelection[] = [];
            desired?.map((d) => {
                newDesired.push(d);
            });
            const defaultOption = optionsService?.getDefaultOptionForSeat(seat, numberOfSeats);
            const defaultOptions = defaultOption ? [defaultOption] : [];
            newDesired.push({
                blockId: seat.blockId,
                row: seat.row,
                seat: seat.seat,
                state: SeatState.Desired,
                restriction: seat.restriction,
                blockName: seat.blockName,
                options: defaultOptions,
                price: defaultOption ? defaultOption.price : concert?.price ?? 50,
            });
            const refreshedBlocks = planFactory?.getPlanForOwnReservations(numberOfSeats, newDesired);
            if (numberOfSeats !== newDesired.length && refreshedBlocks && refreshedBlocks.possibilities.length === 1) {
                const refreshedBlocks2 = planFactory?.getPlanForOwnReservations(
                    numberOfSeats,
                    refreshedBlocks.possibilities[0],
                );
                setDesired(
                    refreshedBlocks.possibilities[0].map((s) => {
                        const defaultOptionSeat = optionsService?.getDefaultOptionForSeat(s, numberOfSeats);
                        return {
                            blockId: s.blockId,
                            row: s.row,
                            seat: s.seat,
                            state: SeatState.Desired,
                            restriction: s.restriction,
                            blockName: s.blockName,
                            options: defaultOptionSeat ? [defaultOptionSeat] : [],
                            price: defaultOptionSeat ? defaultOptionSeat.price : concert?.price ?? 50,
                        };
                    }),
                );
                setBlocks(refreshedBlocks2);
                return;
            }
            setDesired(newDesired);
            setBlocks(refreshedBlocks);
        } else if (seat.state === SeatState.Desired) {
            const newDesired = desired.filter(
                (s) => s.blockId !== seat.blockId || s.row !== seat.row || s.seat !== seat.seat,
            );

            const refreshedBlocks = planFactory?.getPlanForOwnReservations(numberOfSeats, newDesired);
            if (refreshedBlocks && refreshedBlocks.possibilities.length === 1) {
                const refreshedBlocks2 = planFactory?.getPlanForOwnReservations(numberOfSeats, []);
                setDesired([]);
                setBlocks(refreshedBlocks2);
                return;
            }

            setDesired(newDesired);
            setBlocks(refreshedBlocks);
        }
    };

    const loadPlan = async (concertId: number, earlyAccessGuid?: string) => {
        try {
            const ticketSaleCheck = await service.checkTicketSale(concertId, earlyAccessGuid);
            if (!ticketSaleCheck.canReserve) {
                setTicketErrorSaleMessage(ticketSaleCheck);
            }
            const concertInfo = await service.getInfoForConcertAsync(concertId);
            setConcert(concertInfo.concert);
            setConcertSeries(concertInfo.concertSeries);
            setPlan(concertInfo.plan);
            setReserved(concertInfo.reservations);
            calculateSeats(concertInfo.plan, concertInfo.reservations, desired, numberOfSeats);
            setOptionsService(new OptionsService(concertInfo.concert));
        } catch (e) {
            setTicketErrorSaleMessage(
                new TicketSaleCheckResponseDto({
                    canReserve: false,
                    message:
                        'Die Daten für die Ticketreservierung konnten wegen eines technischen Problemes nicht vollständig geladen werden.',
                }),
            );
            alert(e);
        }
    };

    const backToSelectNumberOfSeats = () => {
        setDesired([]);
        setStep(StepType.NumberOfSeats);
    };

    const continueToEnterAddress = () => {
        if (desired.length < numberOfSeats) {
            enqueueSnackbar('Bitte wähle ' + numberOfSeats + ' Sitze vor dem du weiter klickst.', {
                variant: messageType(MessageDtoType.INFO),
            });
            return;
        }

        setStep(StepType.Address);
    };

    const continueToOrder = async (
        theAddress: AddressModel,
        theSeats: ISeatSelection[],
        theTracingData: IContactTracingDataDto,
    ) => {
        setTracingData(theTracingData);
        await sendOrder(theAddress, theSeats, theTracingData);
        setStep(StepType.Confirm);
    };

    const continueToOrderWithoutTracingData = async (theAddress: AddressModel, theSeats: ISeatSelection[]) => {
        await sendOrder(theAddress, theSeats);
        setStep(StepType.Confirm);
    };

    const backToSeatSelection = (reservationFormModel: AddressModel) => {
        setAddress(reservationFormModel);
        setStep(StepType.SelectSeat);
    };

    const backToEnterAddress = (tracingData: IContactTracingDataDto) => {
        setTracingData(tracingData);
        setStep(StepType.Address);
    };

    const calculateSeats = (
        newPlan: IPlanDto,
        newReserved: ISeatReservationDto[],
        desiredSeats: ISeatDisplayInfo[],
        desiredNumberOfSeats: number,
    ) => {
        const newPlanFactory = new PlanFactory(new RestrictionChecker(newPlan?.restrictions), newReserved, newPlan);
        setPlanFactory(newPlanFactory);
        const newBlocks = newPlanFactory.getPlanForOwnReservations(desiredNumberOfSeats, desiredSeats);
        setBlocks(newBlocks);
        return newBlocks;
    };

    const refreshReservations = async (concertId: number) => {
        try {
            const reserved = await service.getReservationsForConcertAsync(concertId);
            console.log(JSON.stringify(reserved));
            setReserved(reserved);
            if (plan) {
                calculateSeats(plan, reserved, desired, numberOfSeats);
            }
        } catch (e) {
            // do nothing
        }
    };

    useEffect(() => {
        loadPlan(props.concertId, props.earlyAccessGuid);
    }, []);

    const changedNumberOfSeats = (value: number) => {
        let maxSeats = 0;
        if (plan) {
            const blocks = calculateSeats(plan, reserved, desired, value);
            maxSeats = blocks?.maxSeats;
        }

        if (maxSeats === 0) {
            enqueueSnackbar(
                'Es können leider keine Tickets mehr verkauft werden. Das Konzert ist bereits ausverkauft.',
                { variant: messageType(MessageDtoType.INFO) },
            );
            return;
        }
        if (maxSeats < 1) {
            enqueueSnackbar('Bitte mindestens einen Sitz wählen.', { variant: messageType(MessageDtoType.INFO) });
            return;
        } else if (maxSeats < value) {
            enqueueSnackbar(
                'Es können leider nicht mehr als ' +
                    maxSeats +
                    ' Sitzplätze auf einmal reserviert werden. ' +
                    'Falls du mehr als ' +
                    maxSeats +
                    ' Sitze reservieren willst, mache den Reservationsvorgang bitte zweimal durch, um jeweils einen Teil der Sitze zu reservieren.',
                { variant: messageType(MessageDtoType.INFO) },
            );
            return;
        }

        setNumberOfSeats(value);
        setDesired([]);

        if (plan) {
            calculateSeats(plan, reserved, [], value);
        }
    };

    const renderImages = (images: ImageDto[]) => {
        const imageTags = [];
        for (const image of images) {
            imageTags.push(
                <>
                    {image.url && (
                        <div>
                            <a href={image.url} title={image.title} target="_blank" rel="noopener noreferrer">
                                <img src={correctPic(image.url)} alt={image.title} style={{ maxHeight: 300 }} />
                            </a>
                        </div>
                    )}
                </>,
            );
        }
        return imageTags;
    };

    const maxNumberOfSeats = plan?.restrictions?.maxSeats ?? 100;
    const possibleNrOfSeats = blocks?.maxSeats ?? 100;

    return (
        <MainContent>
            {
                <RootDiv>
                    {concert && plan && (
                        <>
                            <h1>Sitzplatzreservation</h1>
                            <h2>{concert.title}</h2>
                            <h2 style={{ marginTop: '20px' }}>{plan.name}</h2>
                            <h3 style={{ marginBottom: '20px' }}>
                                Datum: {concert.date ? concert.date.toFormat(DateFormat.Long) : 'Unbekannt'}
                            </h3>
                            {concert &&
                                concert.images &&
                                concert.images.filter((i) => i.type === ImageDtoType.Advertising).length > 0 &&
                                renderImages(concert.images.filter((i) => i.type === ImageDtoType.Advertising))}
                            {ticketSaleErrorMessage && !ticketSaleErrorMessage.canReserve ? (
                                <>
                                    {concert && (
                                        <DetailedConcertInfo
                                            concert={concert}
                                            concertSeries={concertSeries}
                                        ></DetailedConcertInfo>
                                    )}
                                    {concert.comments
                                        ?.filter((c) => c.commentType === CommentDtoCommentType.TicketSale)
                                        .map((m, k) => (
                                            <Alert severity="info" key={k}>
                                                {parse(m.message)}
                                            </Alert>
                                        ))}

                                    <Alert severity="warning">
                                        <p style={{ color: 'red', fontSize: '20px', textAlign: 'left' }}>
                                            Momentan können für dieses Konzert keine Tickets online reserviert werden
                                        </p>
                                        <div style={{ textAlign: 'left' }}>
                                            <p>
                                                {ticketSaleErrorMessage.message ? (
                                                    parse(ticketSaleErrorMessage.message)
                                                ) : (
                                                    <></>
                                                )}
                                            </p>
                                        </div>
                                    </Alert>
                                </>
                            ) : (
                                <>
                                    {concert && optionsService && (
                                        <>
                                            <ReservationStepper
                                                activeStep={step}
                                                nrOfSeats={numberOfSeats}
                                                namesOnly={concert.restrictionOptions?.registerNamesOnly}
                                            />
                                            <hr />
                                            {step === StepType.NumberOfSeats && (
                                                <>
                                                    <SelectNumberOfSeats
                                                        onChange={changedNumberOfSeats}
                                                        continue={() => setStep(StepType.SelectSeat)}
                                                        numberOfSeats={numberOfSeats}
                                                        maxNrOfSeats={maxNumberOfSeats}
                                                        possibleNrOfSeats={possibleNrOfSeats}
                                                        hasRestrictions={false}
                                                        concert={concert}
                                                        concertSeries={concertSeries}
                                                    />
                                                </>
                                            )}
                                            {step === StepType.SelectSeat && (
                                                <>
                                                    <SelectSeats
                                                        plan={plan}
                                                        blocks={blocks?.blocks ?? []}
                                                        numberOfSeats={numberOfSeats}
                                                        desired={desired}
                                                        reserved={reserved}
                                                        clickSeat={clickSeat}
                                                        back={backToSelectNumberOfSeats}
                                                        continue={continueToEnterAddress}
                                                        optionsService={optionsService}
                                                        changeOptions={changeOptions}
                                                    />
                                                </>
                                            )}
                                            {step === StepType.Address && (
                                                <>
                                                    <EnterAddress
                                                        address={address}
                                                        numberOfSeats={numberOfSeats}
                                                        desired={desired}
                                                        onContinue={async (address: AddressModel) => {
                                                            if (concert.restrictionOptions?.requireContactTracing) {
                                                                await continueToEnterTracingData(
                                                                    address,
                                                                    desired,
                                                                    tracingData,
                                                                );
                                                            } else {
                                                                await continueToOrderWithoutTracingData(
                                                                    address,
                                                                    desired,
                                                                );
                                                            }
                                                        }}
                                                        onBack={backToSeatSelection}
                                                        onClickSeat={clickSeat}
                                                        optionsService={optionsService}
                                                        changeOptions={changeOptions}
                                                    ></EnterAddress>
                                                </>
                                            )}
                                            {step === StepType.ContractTracingData && (
                                                <>
                                                    <EnterContractTracingData
                                                        tracingData={tracingData}
                                                        numberOfContacts={numberOfSeats}
                                                        onContinue={async (theTracingData) =>
                                                            continueToOrder(address, desired, theTracingData)
                                                        }
                                                        onBack={backToEnterAddress}
                                                        address={address}
                                                        namesOnly
                                                    ></EnterContractTracingData>
                                                </>
                                            )}
                                            {step === StepType.Confirm && (
                                                <>
                                                    <ConfirmReservation
                                                        form={address}
                                                        numberOfSeats={numberOfSeats}
                                                        desired={desired}
                                                        concert={concert}
                                                        concertSeries={concertSeries}
                                                        response={ticketOrderResponse}
                                                        optionsService={optionsService}
                                                    ></ConfirmReservation>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    <hr />
                </RootDiv>
            }
        </MainContent>
    );
};
