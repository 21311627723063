import { correctPic } from '../helpers/correct-pic';

type Props = {
    title: string;
    images: { link: string; title: string }[];
    children: React.ReactNode;
};

export const SideGallery = (props: Props) => {
    return (
        <>
            <div className="image_wrapper">
                <ul
                    className="gallery clearfix"
                    style={{ marginTop: 5, listStyleImage: 'none', listStyle: 'none', marginLeft: 0 }}
                >
                    {props.images.map((i, k) => (
                        <li key={i.link} style={{ listStyleImage: 'none', listStyle: 'none' }}>
                            <a href={i.link} title={i.title}>
                                <img
                                    className="image_border"
                                    src={correctPic(i.link)}
                                    style={{ width: 156 }}
                                    alt={i.title}
                                />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="text_image_left">
                <>
                    <h2>{props.title}</h2>
                    {props.children}
                </>
            </div>
        </>
    );
};

export default SideGallery;
