import '../App.scss';
import React from 'react';
import { Navigation } from './Navigation';
import { AdminMenu } from './AdminMenu';
import { Footer } from './Footer';

export const Page = (props: { children: React.ReactNode }) => {
    return (
        <>
            <div className="App">
                <AdminMenu />
                <div id="wrapper_content">
                    <div id="wrapper_grafik">
                        <div id="content_rahmen">
                            <div id="content">
                                <Navigation />
                                {props.children}
                            </div>
                            <div style={{ width: '100%', clear: 'both' }}> </div>
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
