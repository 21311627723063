import { DateTime } from 'luxon';
import { getThreeNewestPlaylists } from '../clients/playlist-data';
import { MainContent, MainNarrow } from '../components';
import { RightColumn } from '../components/RightColumn';
import TicketReservationSide from '../components/TicketReservationSide';
import { correctPic } from '../helpers/correct-pic';
export const Playlists = () => {
    const playlists = getThreeNewestPlaylists();
    return (
        <>
            <MainContent>
                <MainNarrow>
                    <h1>MARTIN&apos;S PLAYLISTS</h1>
                    {playlists.map((p) => (
                        <div key={p.year + '-' + p.month} style={{ marginBottom: 20 }}>
                            <h2>
                                {p.month ? (
                                    <>
                                        Martin&apos;s Playlist,{' '}
                                        {DateTime.fromObject({ year: p.year, month: p.month }).toFormat('LLLL')}{' '}
                                        {p.year}
                                    </>
                                ) : (
                                    <>Martin’s Favorite Albums {p.year}</>
                                )}
                            </h2>
                            {p.image && (
                                <img
                                    src={correctPic(p.image)}
                                    alt="NumberOne"
                                    style={{ marginBottom: 20, width: 340 }}
                                />
                            )}
                            <table>
                                <tbody>
                                    {p.playlist.map((i, k) => (
                                        <>
                                            {k === 0 ? (
                                                <tr key={i.artist + i.title}>
                                                    {p.month && (
                                                        <td className="width-10">
                                                            <strong className="color2">1</strong>
                                                        </td>
                                                    )}
                                                    <td className="width-40">
                                                        <strong>{i.artist}</strong>
                                                    </td>
                                                    <td className="width-50">{i.title}</td>
                                                </tr>
                                            ) : (
                                                <tr key={i.artist + i.title}>
                                                    {p.month && <td>{k + 1}</td>}
                                                    <td>
                                                        <strong>{i.artist}</strong>
                                                    </td>
                                                    <td>{i.title}</td>
                                                </tr>
                                            )}
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </MainNarrow>
                <RightColumn>
                    <TicketReservationSide />
                </RightColumn>
            </MainContent>
        </>
    );
};

export default Playlists;
