import { MainContent } from '../../components/MainContent';
import { MainNarrow } from '../../components/MainNarrow';
import ConcertPreview from '../../components/ConcertPreview/ConcertPreview';
import RightSide from './RightSide';
import Slider from './Slider';
import StaticContent from './StaticContent';
import { useEffect, useState } from 'react';
import { ConcertDto, IConcertSeriesDto } from '../../clients/services';
import { ReservationService } from '../../services';
import { LinearProgress } from '@mui/material';

const service = new ReservationService();

export const Welcome = () => {
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [wasLoaded, setWasLoaded] = useState<boolean>(false);
    const [series, setSeries] = useState<IConcertSeriesDto[]>([]);

    const loadConcerts = async () => {
        setLoading(true);
        try {
            const loadedSeries = await service.getConcertSeriesForWelcomePage();

            for (const serie of loadedSeries) {
                const concertForSeries = await service.getConcertsForSeries(serie.id);
                serie.concerts = concertForSeries.map((c) => new ConcertDto(c));
            }

            setWasLoaded(true);
            setSeries(loadedSeries);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadConcerts();
    }, []);

    return (
        <>
            <MainContent>
                {isLoading ? <LinearProgress /> : <>{series?.some((s) => !!s.banner) && <Slider items={series} />}</>}
                <MainNarrow>
                    <StaticContent />
                    {isLoading ? (
                        <LinearProgress />
                    ) : (
                        <>
                            {series && (
                                <>
                                    {series.map((c, key) => (
                                        <ConcertPreview key={key} {...c}></ConcertPreview>
                                    ))}{' '}
                                </>
                            )}
                        </>
                    )}
                </MainNarrow>
                <RightSide />
            </MainContent>
        </>
    );
};

export default Welcome;
