import React from 'react';
import { ISeatDisplayInfo } from '../services/plan-info';

interface SeatProps {
    seatInfo: ISeatDisplayInfo;
    onClick: (seatInfo: ISeatDisplayInfo) => void;
    devMode: boolean;
}

export const Seat: React.FC<SeatProps> = (props) => {
    const className = 'fa fa-chair seat ' + props.seatInfo.state.toString();
    const devInfo = props.devMode ? ' ' + props.seatInfo.state + ' ' + props.seatInfo.restriction : '';
    return (
        <div onClick={() => props.onClick(props.seatInfo)}>
            <i
                className={className}
                title={
                    props.seatInfo.blockName + ' Reihe ' + props.seatInfo.row + '/Sitz ' + props.seatInfo.seat + devInfo
                }
            ></i>
        </div>
    );
};
