import { MainContent, MainNarrow } from '../components';
import { RightColumn } from '../components/RightColumn';
import TicketReservationSide from '../components/TicketReservationSide';
export const Videos = () => {
    return (
        <>
            <MainContent>
                <MainNarrow>
                    <h1>VIDEOS</h1>

                    <div className="konzert">
                        <div>
                            <h2>KELLEY MICKWEE mit Colin Brooks &amp; John Chipman (USA )</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=PR6ITIYvtmE" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2017_km_youtube.jpg"
                                        alt="Konzert Kelley Mickwee mit Colin Brooks und John Chipman in Breitenbach"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Freitag, 12. Mai 2017</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=PR6ITIYvtmE"
                                            target="_blank"
                                            title="Konzert Kelley Mickwee mit Colin Brooks und John Chipman in Breitenbach"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 107 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>JAMIE LIN WILSON &amp; SUNNY SWEENEY (USA )</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=k4664jexafQ" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2016_jlwss_youtube.jpg"
                                        alt="Jamie Lin Wilson Sunny Sweeney"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Sonntag, 09. Oktober 2016</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=k4664jexafQ"
                                            target="_blank"
                                            title="Konzert Jamie Lin Wilson Sunny Sweeney in Wahlen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 94 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>EILEN JEWELL BAND (USA)</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=7Nc0uAxPbHI" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="images/konzerte/konzert_2016_ejb_youtube.jpg"
                                        alt="EILEN JEWELL BAND"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Dienstag, 13. September 2016</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=7Nc0uAxPbHI"
                                            target="_blank"
                                            title="High Plains Chamboree in Wahlen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 112 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>ALISON BROWN ACOUSTIC QUARTET (USA)</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=GN4w1Ix704c" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2016_abaq_youtube.jpg"
                                        alt="ALISON BROWN ACOUSTIC QUARTET"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Sonntag, 12. Juni 2016</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=GN4w1Ix704c"
                                            target="_blank"
                                            title="Konzert ALISON BROWN ACOUSTIC QUARTET (USA) in Wahlen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 122 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>CLAIRE LYNCH BAND (USA)</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=N0eldkbkdJk" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2016_clb_youtube.jpg"
                                        alt="CLAIRE LYNCH BAND"
                                    />
                                </a>
                                <ul>
                                    <li>
                                        <h4>Donnerstag, 19. Mai 2016</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=N0eldkbkdJk"
                                            target="_blank"
                                            title="Konzert CLAIRE LYNCH BAND (USA) in Wahlen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 137 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>
                                TONI VESCOLI &amp; B&Auml;ND
                                <br />
                                mit Gaststar AMANDA SHAW (USA)
                            </h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=703t8SVzf4o" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2016_tvub_01_youtube.jpg"
                                        alt="Toni Vescoli und B&auml;nd mit Gaststar Amanda Shaw"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Sonntag, 6. M&auml;rz 2016</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=703t8SVzf4o"
                                            target="_blank"
                                            title="Konzert ROB ICKES &amp; TREY HENSLEY in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 146 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>KR&Uuml;GER BROTHERS (USA/CH)</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=KIF63Cl0zMw" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2015_kb_youtube_01.jpg"
                                        alt="KRUGER BROTHERS"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Sonntag, 1. November 2015</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=KIF63Cl0zMw"
                                            target="_blank"
                                            title="Konzert ROB ICKES & TREY HENSLEY in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 143 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>ROB ICKES & TREY HENSLEY (USA)</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=-7pRVsR2Ts4" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2015_rith_youtube_01.jpg"
                                        alt="ROB ICKES & TREY HENSLEY"
                                    />
                                </a>
                                <a href="https://www.youtube.com/watch?v=oznXSssogbc" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2015_rith_youtube_02.jpg"
                                        alt="ROB ICKES & TREY HENSLEY"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Freitag, 16. Oktober 2015</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=-7pRVsR2Ts4"
                                            target="_blank"
                                            title="Konzert ROB ICKES & TREY HENSLEY in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 129 min.
                                        </a>
                                    </li>
                                    <li>
                                        <h4>Freitag, 16. Oktober 2015, Zusatz-Konzert im Bistro</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=oznXSssogbc"
                                            target="_blank"
                                            title="Konzert ROB ICKES & TREY HENSLEY in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 34 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>MADISON VIOLET (CAN)</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=5abqXoJcKL0" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2015_mv_youtube_01.jpg"
                                        alt="Madison Violet"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Donnerstag, 8. Oktober 2015</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=5abqXoJcKL0"
                                            target="_blank"
                                            title="Konzert Madison Violet in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 100 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>COURTNEY PATTON & JASON EADY (USA)</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=WjsukVSrhtw" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15 }}
                                        src="/images/konzerte/konzert_2015_cpje_youtube_01.jpg"
                                        alt="Courtney Patton & Jason Eady"
                                    />
                                </a>
                                <a href="https://www.youtube.com/watch?v=1m0hSvwegpE" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15 }}
                                        src="/images/konzerte/konzert_2015_cpje_youtube_02.jpg"
                                        alt="Courtney Patton & Jason Eady"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Freitag, 4. September 2015</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=WjsukVSrhtw"
                                            target="_blank"
                                            title="Konzert Courtney Patton & Jason Eady in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 113 min.
                                        </a>
                                    </li>
                                    <li>
                                        <h4>Freitag, 4. September 2015, Zusatz-Konzert im Bistro</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=1m0hSvwegpE"
                                            target="_blank"
                                            title="Konzert Courtney Patton & Jason Eady in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 62 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>RHONDA VINCENT & THE RAGE (USA)</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=ZnB4MaYesiQ" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15 }}
                                        src="/images/konzerte/konzert_2015_rv_08_youtube.jpg"
                                        alt="Rhonda Vincent"
                                    />
                                </a>
                                <a href="https://www.youtube.com/watch?v=cO_-fPZhwmg" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15 }}
                                        src="/images/konzerte/konzert_2015_rv_07_youtube.jpg"
                                        alt="Rhonda Vincent"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Freitag, 17. Juli 2015</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=ZnB4MaYesiQ"
                                            target="_blank"
                                            title="Konzert Rhonda Vincent in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt vom Freitag, 17. Juli 2015, von Folkert Kakrow: 127 min.
                                        </a>
                                    </li>
                                    <li>
                                        <h4>Samstag, 18. Juli 2015</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=cO_-fPZhwmg"
                                            target="_blank"
                                            title="Konzert Rhonda Vincent in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt vom Samstag, 18. Juli 2015, von Folkert Kakrow: 133 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <div className="konzert">
                        <div>
                            <h2>MILKDRIVE (USA)</h2>
                            <div className="frame_2 first_frame">
                                <a href="https://www.youtube.com/watch?v=wqPeQKxWlaw" target="_blank" rel="noreferrer">
                                    <img
                                        className="image_border"
                                        style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                        src="/images/konzerte/konzert_2015_md_youtube.jpg"
                                        alt="MilkDrive"
                                    />
                                </a>
                                <ul style={{ marginTop: 28 }}>
                                    <li>
                                        <h4>Sonntag, 17. Mai 2015</h4>
                                        <a
                                            href="https://www.youtube.com/watch?v=wqPeQKxWlaw"
                                            target="_blank"
                                            title="Konzert MilkDrive in Laufen"
                                            rel="noreferrer"
                                        >
                                            Konzert-Mitschnitt von Folkert Kakrow: 130 min.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&nbsp;</p>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </MainNarrow>
                <RightColumn>
                    <TicketReservationSide />
                </RightColumn>
            </MainContent>
        </>
    );
};

export default Videos;
