import { NavLink } from 'react-router-dom';
import '../App.scss';
import preval from 'preval.macro';
import { PageRoutes } from '../page-routes';

export const Footer = () => {
    return (
        <div id="wrapper_footer">
            <div id="footer">
                <div id="left">
                    <p>
                        Last Update:{' '}
                        {preval`module.exports = new Date().toLocaleDateString('de-CH', { year: 'numeric', month: 'numeric', day: 'numeric' });`}
                    </p>
                    <p>
                        Copyright 2015&ndash;2022 SwissTexMusic
                        <br />
                        Designed by p@surbeck
                        <br />
                        <NavLink to={PageRoutes.LegalNotices} className="internal-link">
                            Rechtliche Hinweise
                        </NavLink>
                    </p>
                </div>
                <div id="right">
                    <div className="right_1">
                        <p>
                            <b>
                                MARTIN MEIER <i>presents</i>
                            </b>
                            <br />
                        </p>
                        <p>
                            CH-4242 Laufen
                            <br />
                            Switzerland
                        </p>
                    </div>
                    <div className="right_2">
                        <p>
                            Telefon + 41 (0)79 207 90 19
                            <br />
                        </p>
                    </div>
                    <div className="right_3">
                        <p>
                            <a
                                href="mailto:martin.meier@swisstexmusic.ch"
                                title="Opens window for sending email"
                                className="mail"
                            >
                                martin.meier(at)swisstexmusic.ch
                            </a>
                            <br />
                            <NavLink to={PageRoutes.Welcome} className="internal-link">
                                www.swisstexmusic.ch
                            </NavLink>
                        </p>
                    </div>
                </div>
                <div className="social_footer">
                    <div className="">
                        <p>
                            <b>Newsletter</b>
                        </p>
                        <p>
                            Informationen zu unseren Konzert-veranstaltungen kannst du automatisch per E-Mail erhalten.
                            Registriere dich dafür daf&uuml;r bitte bei{' '}
                            <a
                                href="mailto:martin.meier@swisstexmusic.ch?subject=SwissTexMusic - Newsletter Registrierung"
                                title="Opens window for sending email"
                                className="mail"
                            >
                                Martin Meier
                            </a>
                            .
                        </p>
                        <br />
                    </div>
                    <div className="facebook_footer">
                        <p>
                            <b>Finde uns auf</b>
                            <br />
                            <a href="https://www.facebook.com/swisstexmusic" target="new_window">
                                <img
                                    src="/images/transparent.gif"
                                    alt="Facebook"
                                    title="Facebook"
                                    width="120"
                                    height="53"
                                    style={{ border: 0 }}
                                />
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
