import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/auth-provider';
import { Export } from './TicketsAdmin/Export';
import { Orders } from './TicketsAdmin/Orders';
import { FullWidthPage } from '../components/FullWidthPage';

export const Admin = () => {
    const { profile, hasRole } = useAuth();
    const location = useLocation();

    const isExport = location.pathname === '/admin/export';
    const isPayment = location.pathname === '/admin/orders';
    return (
        <FullWidthPage>
            <div>
                <div className="menutop_main">
                    <NavLink
                        to="/admin/export"
                        title="Export"
                        style={{
                            backgroundColor: isExport ? 'white' : 'inherit',
                            color: isExport ? 'black' : 'inherit',
                        }}
                    >
                        Export
                    </NavLink>
                </div>
                <div className="menutop_main">
                    <NavLink
                        to="/admin/orders"
                        title="Bestellungen"
                        style={{
                            backgroundColor: isPayment ? 'white' : 'inherit',
                            color: isPayment ? 'black' : 'inherit',
                        }}
                    >
                        Bestellungen
                    </NavLink>
                </div>
            </div>
            {hasRole(profile, 'admin') ? (
                <Routes>
                    <Route path="export" element={<Export />} />
                    <Route path="orders" element={<Orders />} />
                </Routes>
            ) : (
                <div>
                    <h1 style={{ paddingTop: 100 }}>No Access! Please login!</h1>
                </div>
            )}
        </FullWidthPage>
    );
};

export default Admin;
