import { LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { RightColumn } from '../../components';
import { ReservationService } from '../../services';

const service = new ReservationService();

export const Navigation = () => {
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [years, setYears] = useState<number[]>([]);

    const loadYears = async () => {
        setLoading(true);
        try {
            const years = await service.getArchiveYears();

            setYears(years);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadYears();
    }, []);

    const NavigationLink = (props: { year: number }) => {
        return (
            <NavLink to={`${props.year}`} style={{ textDecoration: 'none' }}>
                <div className="frame_2 hyperlinks" style={{ textAlign: 'left', backgroundImage: 'none' }}>
                    KONZERT&Uuml;BERSICHT <span className="color2">{props.year}</span>
                </div>
            </NavLink>
        );
    };

    return (
        <RightColumn>
            <div className="frame_hyperlinks">
                <div style={{ width: 235 }}>
                    {isLoading ? (
                        <LinearProgress></LinearProgress>
                    ) : (
                        <>
                            {years?.map((y) => (
                                <NavigationLink key={y} year={y} />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </RightColumn>
    );
};

export default Navigation;
