import { IConcertSeriesDto, CommentDtoCommentType } from '../../clients/services';
import TicketReservationLink from '../../pages/Welcome/TicketReservation';

export const TicketReservation = ({ series }: { series: IConcertSeriesDto }) => {
    return (
        <>
            {series.ticketSaleStart && (
                <TicketReservationLink
                    ticketSaleStart={series.ticketSaleStart}
                    comment={series.comments?.find((c) => c.commentType === CommentDtoCommentType.TicketSale)}
                ></TicketReservationLink>
            )}
        </>
    );
};
