export const Archive2015 = () => {
    return (
        <>
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_kb_01.jpg"
                            alt="Krueger Brothers"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2013_kb_02.png"
                            alt="Krueger Brothers"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Krüger Brothers
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>KR&Uuml;GER BROTHERS (USA/CH)</h2>
                    <div className="frame_1">
                        <p>
                            <strong>
                                <span className="color1">DATUM:</span> Sonntag, 1. November 2015
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19.00 Uhr (T&uuml;r&ouml;ffnung 18.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 52.&ndash;
                            </strong>
                            <br />
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Ticketverkauf startet am 15. August 2015
                            <br />
                            <br />
                        </p>
                        <p>
                            <a href="http:www.krugerbrothers.com" target="_blank" rel="noreferrer">
                                www.krugerbrothers.com
                            </a>
                        </p>
                    </div>
                    <h3>Einfach genial</h3>
                    <p>
                        Die Kr&uuml;ger Brothers geh&ouml;ren schon lange zweifellos zu den talentiertesten Musikern.
                        Doch der Kreis der Musiker, die akustische Musik auf unglaublich hohem Niveau spielen, ist
                        prim&auml;r auf die USA beschr&auml;nkt. Und die meisten Leute, die akustische Musik h&ouml;ren
                        und sch&auml;tzen, sind nun mal eben in den USA zuhause. So war es folgerichtig, dass sich die
                        Kr&uuml;ger Brothers entschlossen haben, im Jahre 2002 nach North Carolina auszuwandern.
                        Dorthin, wo sie ihre Musik vor einem grossen Publikum spielen k&ouml;nnen. Aber auch um mit den
                        Musikern, die sie seit Jahren bewunderten, auf der gleichen B&uuml;hne spielen zu k&ouml;nnen.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Das Repertoire der Kr&uuml;ger Brothers bestand am Anfang noch aus traditioneller amerikanischer
                        Folkmusik und Bluegrass. Heute spielen sie hingegen meistens eigene Kompositionen, die von ihren
                        pers&ouml;nlichen Erfahrungen erz&auml;hlen, und sie haben ihre Musik mit Elementen aus anderen
                        Genres wie der Klassik und des Jazz angereichert. Deshalb z&auml;hlen sie heute zu recht zu den
                        innovativsten Bands der USA.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Was ich besonders sch&auml;tze am Stil der Kr&uuml;ger Brothers ist die Gewandtheit ihrer
                        musikalischen Ideen und die Sch&ouml;nheit in ihrem eleganten und oft komplexen Zusammenspiel.
                        Es ist immer wieder faszinierend den Br&uuml;dern zuzusehen, wie sie w&auml;hrend ihrer
                        Performance und insbesondere nach einem Solo einander Blicke zuwerfen oder ein L&auml;cheln
                        austauschen als wollten sie sagen : &bdquo;Was meinst du dazu?&quot;
                    </p>
                    <p>&nbsp;</p>
                    <p>Na also, wie steht es mit einem Konzertbesuch ? Ein geniales Musikerlebnis ist garantiert.</p>
                    <p>&nbsp;</p>

                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_kb_01.jpg"
                            alt="Krueger Brothers"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2013_kb_02.png"
                            alt="Krueger Brothers"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Kr&uuml;ger Brothers
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-RÜCKBLICK <br />
                        KR&Uuml;GER BROTHERS (USA/CH)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Sonntag, 1. November 2015
                            </b>
                        </p>
                        <p>
                            Konzert Details: <a href="konzerte.html#kruegerbrothers15">KR&Uuml;GER BROTHERS</a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http:www.krugerbrothers.com" target="_blank" rel="noreferrer">
                                www.krugerbrothers.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https:www.youtube.com/watch?v=KIF63Cl0zMw" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2015_kb_youtube_01.jpg"
                                alt="KRUGER BROTHERS"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Sonntag, 1. November 2015</h4>
                                <a
                                    href="https:www.youtube.com/watch?v=KIF63Cl0zMw"
                                    target="_blank"
                                    title="Konzert ROB ICKES & TREY HENSLEY in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 143 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rith_01.jpg"
                            alt="Rob Ickess & Trey Hensley"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2015_rith_01.png"
                            alt="Rob Ickess & Trey Hensley"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Rob &amp; Trey
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>ROB ICKES &amp; TREY HENSLEY (USA)</h2>
                    <div className="frame_1">
                        <p>
                            <strong>
                                <span className="color1">DATUM:</span> Freitag, 16. Oktober 2015
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20.00 Uhr (T&uuml;r&ouml;ffnung 19.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.&ndash;
                            </strong>
                            <br />
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Ticketverkauf startet am 15. August 2015
                            <br />
                            <br />
                        </p>
                        <p>
                            <a href="http:www.robandtrey.com/" target="_blank" rel="noreferrer">
                                www.robandtrey.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Einer der angesagtesten Acts in Nashville !</h3>
                    <p>
                        <strong>Rob Ickes,</strong> Gr&uuml;ndungsmitglied von Blue Highway, wird von vielen Musikern
                        als Genie bezeichnet. Dieser grandiose und mehrfach preisgekr&ouml;nte Dobro-Virtuose
                        (15-maliger Dobro-Spieler des Jahres) hat auch immer wieder einmal Zeit, andere Projekte zu
                        verfolgen. Sein neuestes ist die Zusammenarbeit mit dem jungen, talentierten Gitarristen und
                        S&auml;nger <strong>Trey Hensley.</strong> Diese einzigartige Kombination von zwei
                        aussergew&ouml;hnlich begnadeten Musikern ist eine Offenbarung nicht nur f&uuml;r Fans
                        traditioneller Musik. In Nashville geh&ouml;ren sie zur Zeit zu den angesagtesten Acts !
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Trey Hensley</strong> verf&uuml;gt &uuml;ber eine Stimme, die an Merle Haggard erinnert,
                        und die ideal zur Mischung von klassischer und zeitgen&ouml;ssischer Countrymusik und Bluegrass
                        passt, die die zwei K&ouml;nner zelebrieren. Aber <strong>Hensley</strong> ist vor allem auch
                        ein ausgezeichneter Gitarrist, der mit den Kl&auml;ngen des Dobro von
                        <strong>Ickes</strong> gl&auml;nzend harmoniert.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die beiden Klassemusiker bieten eine ausgewogene Balance mit einer gelungenen Liederauswahl,
                        spannenden Arrangements und herausragendem Picking. Lasst euch von der Spontanit&auml;t und der
                        Schaffensfreude von <strong>Rob Ickes &amp; Trey Hensley</strong> begeistern ! Es ist eine
                        einmalige Gelegenheit, einen der besten Dobro-Spieler zusammen mit einem der gr&ouml;ssten
                        Talente aus Nashville auf der B&uuml;hne zu sehen.
                    </p>
                    <p>&nbsp;</p>

                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rith_02.jpg"
                            alt="Rob Ickess & Trey Hensley"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rith_03.jpg"
                            alt="Rob Ickess & Trey Hensley"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rith_04.jpg"
                            alt="Rob Ickess & Trey Hensley"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rith_05.jpg"
                            alt="Rob Ickess & Trey Hensley"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rith_06.jpg"
                            alt="Rob Ickess & Trey Hensley"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rith_07.jpg"
                            alt="Rob Ickess & Trey Hensley"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2015_rith_01.png"
                            alt="Rob Ickess & Trey Hensley"
                        />
                    </div>
                    <p className="legendentext">
                        Fotos&copy;:
                        <br />
                        Patrick Surbeck
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-RÜCKBLICK <br />
                        ROB ICKES &amp; TREY HENSLEY (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Freitag, 16. Oktober 2015
                            </b>
                        </p>
                        <p>
                            TEXT: Thomas Kobler{' '}
                            <a href="http:www.countrystyle.ch" title="Country Style" target="_blank" rel="noreferrer">
                                www.countrystyle.ch
                            </a>
                        </p>
                        <p>
                            Konzert Details:{' '}
                            <a href="konzerte.html#robickestreyhensley15">ROB ICKES &amp; TREY HENSLEY</a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http:www.robandtrey.com/" target="_blank" rel="noreferrer">
                                www.robandtrey.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Episode Dobro</h3>
                    <p>
                        <strong>
                            Ein Hauch von Jedi-Ritter-Feeling meinte man im &bdquo;Alten Schlachhaus&ldquo; in Laufen
                            beim Auftritt von Rob Ickes und Trey Hensley zu spüren. Der Dobro-Meister mit seinem jungen
                            &bdquo;Padawan&ldquo; (Jedi-Schüler) zeigten mit ihren Gitarren, dass &bdquo;die
                            Macht&ldquo; eindeutig mit ihnen ist
                        </strong>
                        .
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Lichtschwerter kennt mittlerweile fast jeder, aber eine Dobro-Gitarre schon weniger, weshalb Rob
                        Ickes kurz erkl&auml;rte, um was für ein besonderes Instrument es sich dabei handelt:
                        &bdquo;Eigentlich eine Gitarre, die inkorrekt gespielt wird&ldquo;, meinte er augenzwinkernd.
                        Und dass man das Spielen in ungef&auml;hr zehn Minuten erlernen k&ouml;nnte - vorausgesetzt, man
                        w&auml;re im Besitz seiner Instruktions-DVD, die es im Foyer nebst der neusten CD -
                        &bdquo;Before The Sun Goes Down&ldquo; - der beiden zu kaufen gab.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Mit fünfzehn(!) IBMA-Auszeichnungen als &bdquo;Dobroplayer of the Year&ldquo; war am
                        Leistungsverm&ouml;gen von Meister Ickes nicht zu zweifeln, und die Spannung konzentrierte sich
                        auf den jungen Trey Hensley, dessen Gesang und Gitarrenspiel schon von Nashville -Meistern wie
                        Merle Haggard oder Marty Stuart in h&ouml;chsten T&ouml;nen gelobt worden war.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die Country-Weisen lagen richtig. Der 25-J&auml;hrige besitzt einen feinen Bariton mit
                        Ankl&auml;ngen an Joe Nicols, Keith Whitley und etwas Merle. Dazu zupfte, drückte, riss oder
                        schrummte er an Saiten und Hals seiner akustischen Taylor-Gitarre, wie man es nicht alle Tage
                        h&ouml;rt und sieht. Elektrisch soll er es genau so gut k&ouml;nnen.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Rob Ickes, den in Teenagertagen ein Album des Dobro-Meisters Mike Auldridge so beeindruckte,
                        dass er selbst zum Instrument griff, holte aus dem im Kern eher grobschl&auml;chtigen Instrument
                        Kl&auml;nge, die einer Mandoline auf der einen oder einem Banjo auf der andern Seite des
                        Saitenklangspektrums nahe kamen. Alles dazwischen schien für ihn beinahe ein Kinderspiel zu
                        sein.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Auf dem Programm standen Interpretation von Liedern von Earl Scruggs, Ray Charles, Doc Watson,
                        Jimmy Martin, Stevie Ray Vaughan, James Taylor oder auch Elton Johns There Goes A Well-Known
                        Gun. Eine kleine Offenbarung war Hank Williams&lsquo; May You Never Be Alone Like Me. Ickes
                        Dobro, Hensleys Gitarre und Stimme machten aus einem Hank-Klassiker eine neue Episode. Im
                        Publikum herrschte Begeisterung und Einigkeit: Man hatte gerade etwas Grossartiges erlebt. Womit
                        der etwas kühne Bogen zu &bdquo;Star Wars&ldquo; vollendet war.
                    </p>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https:www.youtube.com/watch?v=-7pRVsR2Ts4" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2015_rith_youtube_01.jpg"
                                alt="ROB ICKES & TREY HENSLEY"
                            />
                        </a>
                        <a href="https:www.youtube.com/watch?v=oznXSssogbc" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2015_rith_youtube_02.jpg"
                                alt="ROB ICKES & TREY HENSLEY"
                            />
                        </a>
                        <ul style={{ marginTop: 28, marginRight: 10 }}>
                            <li>
                                <h4>Freitag, 16. Oktober 2015</h4>
                                <a
                                    href="https:www.youtube.com/watch?v=-7pRVsR2Ts4"
                                    target="_blank"
                                    title="Konzert ROB ICKES & TREY HENSLEY in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 129 min.
                                </a>
                            </li>
                            <li>
                                <h4>Freitag, 16. Oktober 2015, Zusatz-Konzert im Bistro</h4>
                                <a
                                    href="https:www.youtube.com/watch?v=oznXSssogbc"
                                    target="_blank"
                                    title="Konzert ROB ICKES & TREY HENSLEY in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 34 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_mv_01.jpg"
                            alt="Madison_Violet"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2015_mv_02.png"
                            alt="Madison_Violet"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Madison Violet
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>MADISON VIOLET (CAN)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Donnerstag, 8. Oktober 2015
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20.00 Uhr (T&uuml;r&ouml;ffnung 19.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http:www.madisonviolet.com" target="_blank" rel="noreferrer">
                                www.madisonviolet.com
                            </a>
                        </p>
                        <p>
                            facebook:{' '}
                            <a href="https:www.facebook.com/madisonviolet?" target="_blank" rel="noreferrer">
                                Madison Violet
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        Sie z&auml;hlen unbestritten zu den besten Singer/Songwritern Kanadas: Brenley MacEachern und
                        Lisa MacIsaac, bekannt als Madison Violet. Seit Anfang des Millenniums sind sie beinahe
                        ununterbrochen &quot;on the road&quot; mit jeweils 200 Konzerten pro Jahr.
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Wenn das mehrfach preisgekr&ouml;nte oder f&uuml;r renommierte Auszeichnungen wie den
                        kanadischen Juno Award nominierte Duo nicht auf der B&uuml;hne steht und mit seinen zwischen
                        Alternative-Country, Folk, Bluegrass und Akustik-Pop wechselnden Liedern sowie seinem
                        unschlagbaren Charme das Publikum verzaubert, dann nehmen Madison Violet neue Songs auf.
                        <br />
                    </p>
                    <p>
                        Selbst f&uuml;r langj&auml;hrige Fans der beiden Ausnahme-Songwriterinnen halten die neuen
                        Lieder einige &Uuml;berraschungen bereit, k&uuml;ndigt doch das kanadische Duo einen Stilwechsel
                        hin zum Indie-Pop an. Denn so unbeschwert leicht, klanglich bunt, rhythmisch vielseitig und
                        grossem Pop zugetan klangen Madison Violet noch nie.{' '}
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Gleichwohl: Trotz neuer stilistischer Ideen, neuem Look und neuer Band, live zeigen die
                        Kanadierinnen in ihrer unnachahmlichen, stets denkw&uuml;rdigen Performance wie gewohnt ihre
                        ganze musikalische Bandbreite &ndash; jene wunderbare Mischung aus Indie-Pop, Folk und Rock, die
                        auch wegen ihrer vokalen Brillanz durchaus Vergleiche mit grossen Vokal-Harmonie-Acts wie Everly
                        Brothers und Simon &amp; Garfunkel zul&auml;sst.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Begleitet werden Madison Violet von Adrian Lawryshyn (Bass, Gesang) und von Jeff Luciani
                        (Schlagzeug, Percussion).
                    </p>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_mv_03.jpg"
                            alt="Madison Violet"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_mv_05.jpg"
                            alt="Madison Violet"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_mv_06.jpg"
                            alt="Madison Violet"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_mv_07.jpg"
                            alt="Madison Violet"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_mv_08.jpg"
                            alt="Madison Violet"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2015_mv_02.png"
                            alt="Madison Violet"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Patrick Surbeck
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>KONZERT-RÜCKBLICK MADISON VIOLET (CAN)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Donnerstag, 8. Oktober 2015
                            </b>
                        </p>
                        <p>
                            Konzert Details: <a href="konzerte.html#madisonviolet">MADISON VIOLET</a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https:www.youtube.com/watch?v=5abqXoJcKL0" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2015_mv_youtube_01.jpg"
                                alt="Madison Violet"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Donnerstag, 8. Oktober 2015</h4>
                                <a
                                    href="https:www.youtube.com/watch?v=5abqXoJcKL0"
                                    target="_blank"
                                    title="Konzert Madison Violet in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 100 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_cpje_01.jpg"
                            alt="CP-JE"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_cp-je_01.jpg"
                            alt="CP-JE"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2014_cp-je_02.jpg"
                            alt="CP-JE"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2014_cp-je_03.png"
                            alt="CP-JE"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Courtney Patton &amp; <br />
                        Jason Eady
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>COURTNEY PATTON &amp; JASON EADY (USA)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Freitag, 4. September 2015
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20.00 Uhr (T&uuml;r&ouml;ffnung 19.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http:www.courtneypatton.com" target="_blank" rel="noreferrer">
                                www.courtneypatton.com
                            </a>
                        </p>
                        <p>
                            <a href="http:www.jasoneady.com" target="_blank" rel="noreferrer">
                                www.jasoneady.com/
                            </a>
                        </p>
                        <p>
                            facebook:{' '}
                            <a
                                href="https:www.facebook.com/pages/Courtney-Patton/13622975750?ref=hl"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Courtney Patton
                            </a>
                        </p>
                        <p>
                            facebook:{' '}
                            <a href="https:www.facebook.com/jasoneadymusic" target="_blank" rel="noreferrer">
                                Jason Eady
                            </a>
                        </p>
                    </div>
                    <h3>Die Chemie stimmte</h3>
                    <p>
                        Nach dem sehr gelungenen ersten Auftritt des texanischen Singer/Songwriter-Ehepaars Jason Eady
                        und Courtney Patton im vergangenen Jahr kommen die beiden zur&uuml;ck nach Laufen. Die beiden
                        &uuml;berzeugten damals mit einem starken Konzert, in welchem sie aufzeigten, wie
                        vielf&auml;ltig die Reaktionen sein k&ouml;nnen, wenn die richtigen Elemente im Spiel sind.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Zwei Gitarren verursachten kein grosses Aufheben, folglich machte sich Jason Eady gleich daran,
                        das Publikum in Versuchung zu f&uuml;hren. Mit Temptation von seinem letzten Album
                        &bdquo;Daylight &amp; Dark&ldquo; gelang das auf Anhieb, weil alles passte: die kluge,
                        metaphorische Adaption eines Bibelverses, seine unaufgeregte Stimme, die f&uuml;r Country-Musik
                        wie gemacht scheint, sowie der sorgf&auml;ltige Background-Gesang von Courtney Patton. Das
                        zweite Lied geh&ouml;rte ihr: Twisted sind die Worte, die man sich an den Kopf wirft, bevor man
                        aus der T&uuml;r st&uuml;rmt und sie einen Moment sp&auml;ter richtig knallen l&auml;sst. Der
                        Refrain &bdquo;Crying eyes are overrated&hellip; - Tr&auml;nen sind
                        &uuml;berbewertet&hellip;&ldquo; l&auml;dt f&ouml;rmlich dazu ein, richtig laut mitgesungen zu
                        werden, wenn man im Auto mit gepackten Sachen davonbraust. Ziemlich befreiend. Im Gegensatz zur
                        wuchtigen Produktion des Liedes auf Platte, die den Refrain schier unwiderstehlich macht,
                        sorgten die reduzierte Instrumentierung und die leiseren Zwischent&ouml;ne im Text f&uuml;r fast
                        and&auml;chtige Stille im Konzertsaal. Der Vorwurf, dass der m&auml;nnliche Protagonist im Song
                        es fertigbrachte, aus einem gl&auml;nzenden Juwel einen stumpfen Kiesel zu machen, verfehlte
                        seine Wirkung nicht bei den Zuh&ouml;rern. Nach diesem Auftakt wollte man nur noch eines: mehr
                        davon! Und die beiden lieferten !
                    </p>
                    <p>&nbsp;</p>
                    <p>Mehr zu h&ouml;ren von den beiden bekommen wir am 4. September 2015.</p>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_cpje_02.jpg"
                            alt="Courtney Patton & Jason Eady"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_cpje_03.jpg"
                            alt="Courtney Patton & Jason Eady"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_cpje_04.jpg"
                            alt="Courtney Patton & Jason Eady"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_cpje_05.jpg"
                            alt="Courtney Patton & Jason Eady"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_cpje_06.jpg"
                            alt="Courtney Patton & Jason Eady"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2014_cp-je_03.png"
                            alt="CP-JE"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Patrick Surbeck
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-RÜCKBLICK <br />
                        COURTNEY PATTON &amp; JASON EADY (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Freitag, 4. September 2015
                            </b>
                        </p>
                        <p>
                            Konzert Details:{' '}
                            <a href="konzerte.html#courtneypattonjasoneady">COURTNEY PATTON &amp; JASON EADY</a>{' '}
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https:www.youtube.com/watch?v=WjsukVSrhtw" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: 100, marginTop: 15 }}
                                src="/images/konzerte/konzert_2015_cpje_youtube_01.jpg"
                                alt="Courtney Patton & Jason Eady"
                            />
                        </a>
                        <a href="https:www.youtube.com/watch?v=1m0hSvwegpE" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: 100, marginTop: 15 }}
                                src="/images/konzerte/konzert_2015_cpje_youtube_02.jpg"
                                alt="Courtney Patton & Jason Eady"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Freitag, 4. September 2015</h4>
                                <a
                                    href="https:www.youtube.com/watch?v=WjsukVSrhtw"
                                    target="_blank"
                                    title="Konzert Courtney Patton & Jason Eady in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 113 min.
                                </a>
                            </li>
                            <li>
                                <h4>Freitag, 4. September 2015, Zusatz-Konzert im Bistro</h4>
                                <a
                                    href="https:www.youtube.com/watch?v=1m0hSvwegpE"
                                    target="_blank"
                                    title="Konzert Courtney Patton & Jason Eady in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 62 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rv_02.jpg"
                            alt="Rhonda Vincent"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rv_01.jpg"
                            alt="Rhonda Vincent"
                        />
                    </p>
                    <div>
                        <img
                            src="/images/konzerte/konzert_2015_rv_02.png"
                            alt="Rhonda Vincent"
                            width="220"
                            style={{ width: 164, marginTop: 8 }}
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Rhonda Vincent
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>RHONDA VINCENT &amp; THE RAGE (USA)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Freitag, 17. Juli &amp; Samstag, 18. Juli 2015
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> jeweils 20.00 Uhr (T&uuml;r&ouml;ffnung
                                19.15 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 55.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http:www.rhondavincent.com" target="_blank" rel="noreferrer">
                                www.rhondavincent.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Rhonda Vincent</strong> mit ihrer Band <strong>The Rage</strong> f&uuml;llte im Juli
                        2011 das alte Schlachthuus gleich zweimal bis auf den letzten Platz. Das Publikum genoss die
                        beiden aussergew&ouml;hnlichen Abende, die durch das witzige Entertainment von{' '}
                        <strong>Rhonda Vincent</strong> und ihrer exzellenten Musiker noch aufgewertet wurden. Die
                        Besucher waren sich einig, man h&auml;tte noch gerne viel l&auml;nger zugeh&ouml;rt.{' '}
                        <strong>Rhonda Vincent &amp; The Rage</strong> boten zwei perfekte Konzerte voller Abwechslung.
                        Auf rasante Bluegrass- und Hillbilly-St&uuml;cke folgten ruhige Balladen, nach virtuosen
                        Traditionals aus der Country-Musik beruhigten weiche Walzertakte.{' '}
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        Und nun kommen <strong>Rhonda Vincent &amp; The Rage</strong> auf ausdr&uuml;cklichen eigenen
                        Wunsch zur&uuml;ck nach Laufen ! Nachdem das Interesse letztmals so gross war und ich ein
                        Zusatzkonzert ansetzen durfte, gibt es im Juli 2015 auf jeden Fall zwei Konzerte. Dabei
                        m&ouml;chte ich besonders hervorheben, dass <strong>Rhonda Vincent &amp; The Rage</strong>, die
                        in ihren Konzerten nie eine Setliste verwenden, damals in rund 4 Stunden nur gerade vier Titel
                        zweimal dargeboten haben ! Es wird auch dieses mal nicht anders sein :{' '}
                        <strong>Rhonda Vincent &amp; The Rage</strong> werden zwei unterschiedliche Konzerte geben !!
                        Also nicht z&ouml;gern und am besten zweimal zugreifen !!
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            When Rhonda Vincent opens her mouth, it&rsquo;s great&hellip; whether she&rsquo;s singing
                            country or bluegrass. God gave Rhonda an unbelievable voice and I am so thankful that we get
                            to enjoy it. I love her like a sister and enjoy her music as her biggest fan.
                            <br />
                            <span className="color3 quelle">&ndash; Dolly Parton</span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rv_03.jpg"
                            alt="Rhonda Vincent"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rv_04.jpg"
                            alt="Rhonda Vincent"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rv_05.jpg"
                            alt="Rhonda Vincent"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_rv_06.jpg"
                            alt="Rhonda Vincent"
                        />
                    </p>
                    <div>
                        <img
                            src="/images/konzerte/konzert_2015_rv_02.png"
                            alt="Rhonda Vincent"
                            width="220"
                            style={{ width: 164, marginTop: 8 }}
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Patrick Surbeck / <br />
                        Katja Infanzon
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-RÜCKBLICK <br />
                        RHONDA VINCENT &amp; THE RAGE (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Freitag, 17. Juli &amp; Samstag, 18. Juli
                                2015
                            </b>
                        </p>
                        <p>
                            Konzert Details:{' '}
                            <a href="konzerte.html#rhondavincentandtherage2015">RHONDA VINCENT &amp; THE RAGE</a>{' '}
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https:www.youtube.com/watch?v=ZnB4MaYesiQ" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15 }}
                                src="/images/konzerte/konzert_2015_rv_08_youtube.jpg"
                                alt="Rhonda Vincent"
                            />
                        </a>
                        <a href="https:www.youtube.com/watch?v=cO_-fPZhwmg" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15 }}
                                src="/images/konzerte/konzert_2015_rv_07_youtube.jpg"
                                alt="Rhonda Vincent"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Freitag, 17. Juli 2015</h4>
                                <a
                                    href="https:www.youtube.com/watch?v=ZnB4MaYesiQ"
                                    target="_blank"
                                    title="Konzert Rhonda Vincent in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt vom Freitag, 17. Juli 2015, von Folkert Kakrow: 127 min.
                                </a>
                            </li>
                            <li>
                                <h4>Samstag, 18. Juli 2015</h4>
                                <a
                                    href="https:www.youtube.com/watch?v=cO_-fPZhwmg"
                                    target="_blank"
                                    title="Konzert Rhonda Vincent in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt vom Samstag, 18. Juli 2015, von Folkert Kakrow: 133 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Medien-Berichte</h3>
                    <div className="frame_2 first_frame">
                        <ul style={{ marginTop: 8 }}>
                            <li>
                                <h4>CountryStyle, Ausgabe 64, September 2015</h4>
                                <a
                                    href="/docs/rhonda-vincent-2015-countrystyle.pdf"
                                    target="_blank"
                                    title="Artikel Wochenblatt Laufen"
                                >
                                    Artikel im CountryStyle Magazin, Text von Thomas Kobler: PDF (62 KB)
                                </a>
                            </li>
                            <li>
                                <h4>Wochenblatt Laufen, 23. Juli 2015</h4>
                                <a
                                    href="/docs/Rhonda_Vincent_wobla_laufen1.pdf"
                                    target="_blank"
                                    title="Artikel Wochenblatt Laufen"
                                >
                                    Artikel im Laufener Wochenblatt von Martin Staub, 23. Juli 2015: PDF (705 KB)
                                </a>
                            </li>
                            <li>
                                <h4>Country Music Magazine, DE, 19. Juli 2015</h4>
                                <a
                                    href="http:www.country.de/2015/07/19/rhonda-vincent-the-rage-in-laufen/"
                                    target="_blank"
                                    title="www.country.de"
                                    rel="noreferrer"
                                >
                                    Bericht von Franz-Karl Opitz auf www.country.de, 19. Juli 2015
                                </a>
                            </li>
                            <li>
                                <h4>Country Music Magazine, DE, 19. Juli 2015</h4>
                                <a
                                    href="http:www.country.de/2015/07/19/rhonda-vincent-die-queen-of-bluegrass-regiert-in-laufen/"
                                    target="_blank"
                                    title="www.country.de"
                                    rel="noreferrer"
                                >
                                    Interview mit Franz-Karl Opitz und Rhonda Vincent auf www.country.de, 19. Juli 2015
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_md_01.jpg"
                            alt="Milk Drive"
                        />
                    </p>
                    <div>
                        <img
                            src="/images/konzerte/konzert_2015_md_02.png"
                            alt="Milk Drive"
                            width="220"
                            style={{ width: 164, marginTop: 8 }}
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Milk Drive
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>MILKDRIVE (USA)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 17. Mai 2015
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19.00 Uhr (T&uuml;r&ouml;ffnung 18.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.-
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http:www.milkdrive.com" target="_blank" rel="noreferrer">
                                www.milkdrive.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <p>
                        <strong>MilkDrive</strong> aus Austin, Texas nennen ihre Musik &bdquo;Jazzgrass&ldquo;.
                        Musikexperten beschreiben ihre Musik auch als &bdquo;Jamgrass&ldquo;, &bdquo;Nu-Folk&ldquo; oder
                        Redneck Gypsy Jazz&ldquo;. Zu ihren musikalischen Vorbildern z&auml;hlen
                        <strong>MilkDrive</strong> die grossartigen Instrumentalisten David Grisman, Mark
                        O&rsquo;Connor, Tony Rice und Sam Bush, die in den sp&auml;ten 70er-Jahren die Newgrass-Musik
                        mitbegr&uuml;ndeten, sowie den franz&ouml;sischen Jazz-Violinisten Jean-Luc Ponty. Wie auch
                        immer : der Sound von <strong>MilkDrive</strong> basiert auf der Bluegrass Musik, er
                        enth&auml;lt aber auch Elemente von Jazz, Swing, Old-Time Musik, Gypsy, Klassik, Folk und Rock
                        &sbquo;n&lsquo; Roll. Diese verschiedenen Stile vermischen die Jungs dank ihrer hervorragenden
                        Musikalit&auml;t und ihrer Virtuosit&auml;t zu einem komplexen Klanggebilde mit zahlreichen Soli
                        und Instrumentals. Improvisation und Innovation erster G&uuml;te ! Daneben &uuml;berzeugt die
                        Band aber auch mit sehr traditionellen T&ouml;nen in bester Folk, Pop oder Singer-Songwriter
                        Manier. Bei diesen Liedern bestechen <strong>MilkDrive</strong> neben dem musikalischen
                        K&ouml;nnen auch mit ausgezeichnetem Lead- und Harmoniegesang.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>MilkDrive</strong> besteht aus den vier herausragenden und brillianten Instrumentalisten{' '}
                        <strong>Brian Beken</strong> (Geige, Mandoline, Gitarre, Lead Vocals),
                        <strong>Dennis Ludiker</strong> (Mandoline, Geige, Harmoniegesang),{' '}
                        <strong>Noah Jeffries</strong> (Gitarre, Mandoline, Geige, Harmoniegesang) und{' '}
                        <strong>Jesse Dalton</strong> (Kontrabass).
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Lasst euch die Musik dieser einzigartigen -Gruppe, die f&uuml;r mich zu den aktuell besten
                        akustischen Bands z&auml;hlt, nicht entgehen. Ich garantiere euch ein ph&auml;nomenales
                        Musikerlebnis !
                    </p>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            A virtuosic blast of bluegrassy string-band music.
                            <br />
                            <span className="color3 quelle">&ndash; Andrew Dansby, Houston Chronicle</span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            A music lover&apos;s delight.
                            <br />
                            <span className="color3 quelle">&ndash; Twangville</span>
                        </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <h3>Videos auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <ul style={{ marginTop: 8 }}>
                            <li>
                                <a
                                    href="http:www.texasmusicscene.com/video/milkdrivebehind-scenes/"
                                    target="_blank"
                                    title="MilkDrive Videos auf Youtube"
                                    rel="noreferrer"
                                >
                                    MilkDrive : Portrait auf Texas Music Scene
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https:www.youtube.com/watch?v=RXHyHbx8BKM"
                                    target="_blank"
                                    title="MilkDrive Videos auf Youtube"
                                    rel="noreferrer"
                                >
                                    MilkDrive : Waves
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https:www.youtube.com/watch?v=11qi9mrnC3Q"
                                    target="_blank"
                                    title="MilkDrive Videos auf Youtube"
                                    rel="noreferrer"
                                >
                                    MilkDrive : SOHO auf Texas Music Scene
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https:www.youtube.com/watch?v=ZdvtbWDYQFs"
                                    target="_blank"
                                    title="MilkDrive Videos auf Youtube"
                                    rel="noreferrer"
                                >
                                    MilkDrive : Gulf Road auf Texas Music Scene
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https:www.youtube.com/watch?v=iZFYYQ-hhas"
                                    target="_blank"
                                    title="MilkDrive Videos auf Youtube"
                                    rel="noreferrer"
                                >
                                    MilkDrive : Chabota
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165 }}
                            src="/images/konzerte/konzert_2015_md_03.jpg"
                            alt="MD"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165, marginTop: 5 }}
                            src="/images/konzerte/konzert_2015_md_04.jpg"
                            alt="MD"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165, marginTop: 5 }}
                            src="/images/konzerte/konzert_2015_md_05.jpg"
                            alt="MD"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165, marginTop: 5 }}
                            src="/images/konzerte/konzert_2015_md_06.jpg"
                            alt="MD"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165, marginTop: 5 }}
                            src="/images/konzerte/konzert_2015_md_07.jpg"
                            alt="MD"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 165, marginTop: 5 }}
                            src="/images/konzerte/konzert_2015_md_08.jpg"
                            alt="MD"
                        />
                    </p>
                    <div>
                        <img
                            src="/images/konzerte/konzert_2015_md_02.png"
                            alt="Milk Drive"
                            width="220"
                            style={{ width: 164, marginTop: 8 }}
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Patrick Surbeck
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>KONZERT-R&Uuml;CKBLICK MILKDRIVE</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Sonntag, 17. Mai 2015
                                <br />
                                <span className="color1">TEXT: </span>Thomas Kobler
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http:www.countrystyle.ch" title="Country Style" target="_blank" rel="noreferrer">
                                www.countrystyle.ch
                            </a>
                            <br />
                            Konzert Details: <a href="konzerte.html#milkdrive">MILKDRIVE</a>
                        </p>
                    </div>
                    <h3>Wie einst bei Hitchcock</h3>
                    <p>
                        <strong>
                            Im einen Augenblick ist die Welt v&ouml;llig in Ordnung und schon im n&auml;chsten ist
                            nichts mehr, wie es war. MilkDrive, die beinahe unbeschreibliche Band aus Austin, Texas,
                            machten aus ihrem Auftritt vom 17. Mai 2015 im Kulturzentraum &bdquo;Altes
                            Schlachthaus&ldquo; in Laufen unter anderem auch ein Vexierspiel.
                        </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Ein Vexierspiel ist eine Aufgabe, wo man Dinge sinnvoll zusammensetzen, entwirren oder
                        erg&auml;nzen muss, aber auch ein kompliziertes R&auml;tsel, wie man es in Alfred Hitchcocks
                        Filmen auf Schritt und Tritt antraf. War dieses Spiel beim grossen Regisseur volle Absicht,
                        scheint es bei Brian Beken (Fiddle, Mandolin, Guitar, Lead vocals), Dennis Ludiker (Mandolin,
                        Fiddle, Harmony vocals), Noah Jeffries (Guitar, Fiddle, Mandolin, Harmony vocals) und Jesse
                        Dalton (Double Bass, Harmony vocals) eher unbeabsichtigtes Mittel zum Zweck zu sein, klassische
                        - auf keltische Volksmusikeinfl&uuml;sse zur&uuml;ckgehende - amerikanische Roots-Musik mit den
                        typischen Saiteninstrumenten ins Heute zu transportieren. Um nicht zu sagen: katapultieren.
                        Nicht ganz &uuml;berraschend heisst das neue Album auch &bdquo;Places You&rsquo;ve Not Been
                        &ndash; Orte, wo man noch nicht gewesen ist&ldquo;.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Um zu musizieren wie MilkDrive, braucht es vor allem eines: jahrelanges &Uuml;ben von
                        Kindesbeinen an. Die Frage wie sich diese verbl&uuml;ffenden Instrumentalisten in den Weiten
                        ihrer Heimat &uuml;berhaupt finden konnten, wurde nach dem Auftritt in lockerer Runde
                        beantwortet: &bdquo;Es gibt da diesen nationalen Geigen-Wettbewerb in Weiser, Idaho, der jedes
                        Jahr stattfindet. Man k&ouml;nnte ihn als eine Art &bdquo;Boot Camp&ldquo; (Ausbildungslager)
                        f&uuml;r Geiger jeden Alters bezeichnen. Dort trafen wir schon als Kinder allj&auml;hrlich
                        zusammen, haben miteinander ge&uuml;bt, gegeneinander im Wettbewerb gewetteifert, wurden
                        &uuml;ber die Zeit Freunde und mittlerweile MilkDrive&ldquo;, l&ouml;ste Leads&auml;nger Brian
                        Beken mindestens dieses R&auml;tsel zu vorger&uuml;ckter Stunde auf.
                    </p>
                    <p>
                        Damit man einen Begriff von der Musik der Band bekommt, muss man sich wohl am einfachsten ein
                        Szenario vorstellen, wo sich der Nachwuchs von bodenst&auml;ndigen Mountain-Musikanten die
                        Instrumente &ndash; in diesem Fall Fiddles, Mandolinen, Gitarren und den Kontrabass - ausleiht
                        um damit heimlich im Keller zu experimentieren, was man aus Altbekanntem Neues herausholen
                        k&ouml;nnte. Dass sie sich zum Ziel gemacht haben, dereinst zu den Top f&uuml;nf progressiven
                        Bands in den USA zu z&auml;hlen, &uuml;berrascht nicht, nachdem man St&uuml;cke wie Random
                        Access, das von ihnen stilistisch als &bdquo;Acoustic-Funk&ldquo; vorgestellt wurde, erlebt hat.
                        Schon heute k&ouml;nnte man sich vorstellen, dass man MilkDrive aufgrund ihrer F&auml;higkeiten
                        ohne weiteres zu den Nerds aus &bdquo;The Big Bang Theory&ldquo; stecken k&ouml;nnte, falls dort
                        mal traditionelle Tunes gebraucht w&uuml;rden.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Ihre Instrumentalst&uuml;cke - obwohl klanglich &uuml;berhaupt nicht verwandt - erinnern fast
                        ein wenig an Pink Floyd, was Progressivit&auml;t und experimentelle Suche nach neuen Klangfolgen
                        angeht. Bei MilkDrive kommen sie jedoch aus traditionellen, akustischen
                        Instrumentenb&auml;uchen. Dass sie Musik-Nerds mit Humor sind, bewies Gitarrist Noah Jeffries in
                        der Einleitung zum von ihm komponierten St&uuml;ck &bdquo;Camp Schnool&ldquo;:
                        &bdquo;Instrumentalst&uuml;cke kann man ja eigentlich nennen, wie es einem grad einf&auml;llt
                        &ndash; der Titel spielt bei denen praktisch keine Rolle.&ldquo; In der Tat: Camp Shnuel&hellip;
                        Camp Schnul, Camp Sch&hellip; - egal.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Nicht zuletzt aus kommerziellen Gr&uuml;nden sind auf ihrem neuen Album weniger
                        Instrumentalst&uuml;cke als auf den Vorg&auml;ngern. Aber die Herausforderung k&uuml;nftig nicht
                        nur &uuml;berwiegend Instrumentalmusik zu komponieren, sondern f&uuml;r leichtere Erkenn- und
                        Haftbarkeit auch mit Texten zu arbeiten, d&uuml;rfte f&uuml;r die sympathischen Mitglieder von
                        MilkDrive keine unl&ouml;sbare Aufgabe sein. Mit den Vier aus Austin hat die akustische
                        &bdquo;Mountain-Roots- Music&ldquo; zum dynamischen Vorw&auml;rtssprung ins Heute abgehoben. In
                        Laufen &ouml;ffnet sich am Sonntagabend ein Fenster mit Aussicht in die Zukunft, und dem
                        Publikum gefiel sie ausserordentlich gut. Aufregend und spannend war es - wie bei Sir Alfred.
                    </p>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https:www.youtube.com/watch?v=wqPeQKxWlaw" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2015_md_youtube.jpg"
                                alt="MilkDrive"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Sonntag, 17. Mai 2015</h4>
                                <a
                                    href="https:www.youtube.com/watch?v=wqPeQKxWlaw"
                                    target="_blank"
                                    title="Konzert MilkDrive in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 130 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2015;
