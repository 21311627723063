import React from 'react';

interface SectionProps {
    title?: string;
    children: React.ReactNode;
}

export const Section = (props: SectionProps) => {
    return (
        <>
            {props.title && <h1>{props.title}</h1>}
            <div className="konzert">{props.children}</div>
            <hr />
        </>
    );
};
