import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { IAuthStore, IProfile, IToken } from './auth-store';

export interface ICredentials {
    email: string;
    password: string;
}

export interface IAuthContext {
    profile: IProfile | null;
    refreshToken: IToken | null;
    token: IToken | null;
    login: (credentials: ICredentials) => Promise<IProfile | null>;
    logout: () => void;
    hasRole: (profile: IProfile | null, role: string) => boolean;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

interface Props {
    children: ReactNode;
    login: (credentials: ICredentials) => Promise<IToken | null>;
    logout?: () => Promise<void>;
    getProfile: () => Promise<IProfile | null>;
    authStore: IAuthStore;
}

export const AuthProvider = (props: Props) => {
    const [profile, setProfile] = useState<IProfile | null>(props.authStore.getProfile());

    const [token, setToken] = useState<IToken | null>(props.authStore.getToken());

    const [refreshToken, setRefreshToken] = useState<IToken | null>(props.authStore.getRefreshToken());

    const refresh = () => {
        const newToken = props.authStore.getToken();
        setToken(newToken);
        const newRefreshToken = props.authStore.getRefreshToken();
        setRefreshToken(newRefreshToken);
        if (!newToken) {
            setProfile(null);
        }
    };

    useEffect(() => {
        refresh();
        const interval = setInterval(() => {
            refresh();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const login = async (credentials: ICredentials) => {
        const result = await props.login(credentials);
        setToken(result);
        if (result?.token) {
            props.authStore.setToken({
                token: result.token,
                validUntil: result.validUntil,
            });
            const profile = (await props.getProfile()) ?? null;
            if (profile) {
                props.authStore.setProfile(profile);
            }
            setProfile(profile);
            return profile;
        }
        props.authStore.clear();
        return null;
    };

    const logout = () => {
        setToken(null);
        setProfile(null);
        setRefreshToken(null);
        props.authStore.clear();
        if (props.logout) {
            props.logout();
        }
    };

    const hasRole = (theProfile: IProfile | undefined | null, role: string) => {
        return (theProfile?.roles?.filter((r) => r === role).length ?? 0) > 0;
    };

    const authContext = { profile, token, refreshToken, login, logout, hasRole } as IAuthContext;

    return <AuthContext.Provider value={authContext}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
