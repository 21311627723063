import React from 'react';
import { NavLink } from 'react-router-dom';

export enum MainPages {
    Welcome,
    Archive,
    Tickets,
    Links,
    Music,
    Other,
    None,
}

export const Header: React.FC = () => {
    return (
        <div id="header_1">
            <div id="logo_link">
                <NavLink to="/">
                    <img alt="nav" src="/images/transparent.gif" style={{ width: 500, height: 200, border: 0 }} />
                </NavLink>
            </div>
            <div id="topnavi_home">
                <NavLink to="/">
                    <img
                        src="/images/transparent.gif"
                        alt="home"
                        title="Home"
                        style={{ width: '30px', height: '30px', border: 0 }}
                    />
                </NavLink>
            </div>
            <div id="topnavi_mail">
                <a href="mailto:martin.meier@swisstexmusic.ch">
                    <img
                        src="/images/transparent.gif"
                        alt="nav"
                        title="E-Mail"
                        style={{ width: '30px', height: '30px', border: 0 }}
                    />
                </a>
            </div>
            <div id="topnavi_facebook">
                <a href="https://www.facebook.com/swisstexmusic" rel="noopener noreferrer" target="_blank">
                    <img
                        src="/images/transparent.gif"
                        alt="facebook"
                        title="Facebook"
                        style={{ width: '30px', height: '30px', border: 0 }}
                    />
                </a>
            </div>
        </div>
    );
};
