import '../App.scss';
import React from 'react';
import { Navigation } from './Navigation';
import { AdminMenu } from './AdminMenu';

export const FullWidthPage = (props: { children: React.ReactNode }) => {
    return (
        <>
            <div className="App">
                <AdminMenu />
                <div id="wrapper_content">
                    <div id="wrapper_grafik">
                        <div id="content_rahmen">
                            <Navigation />
                        </div>
                        <div style={{ width: '100%', clear: 'both' }}> </div>
                        {props.children}
                        <div style={{ width: '100%', clear: 'both' }}> </div>
                    </div>
                </div>
            </div>
        </>
    );
};
