type MixerProps = {
    width?: string;
    align?: 'left' | 'right' | 'none';
};

export const Mixer = (props: MixerProps) => {
    return (
        <div
            style={{
                float: props.align ?? 'none',
                fontSize: '14px',
                margin: 'auto',
                backgroundColor: 'lightgray',
                color: 'black',
                width: props.width ?? '50px',
                height: '20px',
                borderRadius: '5px',
                verticalAlign: 'bottom',
                textAlign: 'center',
            }}
        >
            Mixer
        </div>
    );
};
