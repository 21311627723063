import React from 'react';
import { Seat } from './Seat';
import { IBlockDto, ISeatReservationDto, BlockDtoSeatCountDirection, RestrictionDto } from '../../../clients/services';
import { ISeatDisplayInfo, IBlockDisplayInfo } from '../services/plan-info';
import { BlockChecker } from '../services/block-checker';

interface BlockProps {
    block: IBlockDto;
    blockInfo: IBlockDisplayInfo;
    restrictions?: RestrictionDto;
    reservations: ISeatReservationDto[];
    ownReservations: ISeatReservationDto[];
    onClickSeat: (seatInfo: ISeatDisplayInfo) => void;
    devMode: boolean;
}

export class Block extends React.Component<BlockProps> {
    createSeatHeaders() {
        const headers: JSX.Element[] = [];
        const block = this.props.block;

        const seatStartAt = !block.startSeatNumbersAt ? 1 : block.startSeatNumbersAt;
        if (block.seatCountDirection === BlockDtoSeatCountDirection.LeftToRight) {
            if (!block.showRowNrRight) {
                headers.push(<th key={`seatheader-${block.id}`} className="seat-label"></th>);
            }
            for (let seatNr = seatStartAt; seatNr < block.seatsInRow + seatStartAt; seatNr++) {
                headers.push(
                    <th key={'header-' + seatNr} className="seat-label">
                        S{seatNr}
                    </th>,
                );
            }
            if (block.showRowNrRight) {
                headers.push(<th key={block.seatsInRow + 1} className="seat-label"></th>);
            }
        } else {
            if (!block.showRowNrRight) {
                headers.push(<th key={`seatheader-${block.id}`} className="seat-label"></th>);
            }
            for (let seatNr = block.seatsInRow + seatStartAt - 1; seatNr >= seatStartAt; seatNr--) {
                headers.push(
                    <th key={seatNr} className="seat-label">
                        S{seatNr}
                    </th>,
                );
            }
            if (block.showRowNrRight) {
                headers.push(<th key={block.seatsInRow + 1} className="seat-label"></th>);
            }
        }
        return headers;
    }

    createSeat(rowNr: number, seatNr: number) {
        const row = this.props.blockInfo.rows.find((r) => r.row === rowNr);
        const seat = row?.seatInfos.find((r) => r.seat === seatNr);
        if (seat) {
            return (
                <td key={`seat-${this.props.blockInfo?.blockId}-${row?.row}-${seat?.seat ?? 0}`} className="seats-cell">
                    <Seat onClick={this.props.onClickSeat} seatInfo={seat} devMode={this.props.devMode} />
                </td>
            );
        }
        return (
            <td key={`seat-${this.props.blockInfo?.blockId}-${row?.row}-${seatNr}-empty`} className="seats-cell empty">
                &nbsp;
            </td>
        );
    }

    createRows(blockChecker: BlockChecker) {
        const rows: JSX.Element[] = [];
        const block = this.props.block;
        for (let rowNr = blockChecker.startRowCountAt(); rowNr <= blockChecker.endRowCountAt(); rowNr++) {
            if (blockChecker.isSkipped(rowNr)) {
                rows.push(
                    <tr key={`${block.id}-${rowNr}-corridor1`} className="corridor-row">
                        <td className="corridor-cell" colSpan={blockChecker.getBlockSeatCount() + 1}>
                            &nbsp;
                        </td>
                    </tr>,
                );
            } else {
                rows.push(
                    <tr key={`${block.id}-${rowNr}-seats`} className="seats-row">
                        {this.createRow(blockChecker, rowNr)}
                    </tr>,
                );
            }

            if (blockChecker.hasCorridorAfter(rowNr)) {
                rows.push(
                    <tr key={`${block.id}-${rowNr}-corridor2`} className="corridor-row">
                        <td className="corridor-cell" colSpan={blockChecker.getBlockSeatCount() + 1}>
                            &nbsp;
                        </td>
                    </tr>,
                );
            }
        }
        return rows;
    }

    createRow(blockChecker: BlockChecker, rowNr: number) {
        const block = this.props.block;
        const rowSettings = this.props.block.rowSettings?.find((r) => r.row === rowNr);
        const seats: JSX.Element[] = [];
        if (!block.hideRowNr && !block.showRowNrRight) {
            seats.push(
                <th key={'row-label-' + { rowNr }} className="row-label">
                    R{rowNr}
                </th>,
            );
        }
        if (block.seatCountDirection === BlockDtoSeatCountDirection.LeftToRight) {
            for (
                let seatNr = blockChecker.startSeatCountAt(rowNr);
                seatNr <= blockChecker.endSeatCountAt(rowNr);
                seatNr++
            ) {
                if (rowSettings?.gaps && rowSettings?.gaps?.length > 0) {
                    const gap = rowSettings.gaps.find((g) => g.seatToShift === seatNr);
                    if (gap) {
                        for (let gapNr = 0; gapNr < gap.shiftNrOfGaps; gapNr++) {
                            seats.push(<td key={'row' + { rowNr } + 'gap-before-' + gapNr}></td>);
                        }
                    }
                }
                seats.push(this.createSeat(rowNr, seatNr));
                // if (rowSettings?.gaps && rowSettings?.gaps?.length > 0) {
                //     const gap = rowSettings.gaps.find((g) => g.seatToShift === seatNr + 1);
                //     if (gap) {
                //         for (let gapNr = 0; gapNr < gap.shiftNrOfGaps; gapNr++) {
                //             seats.push(<td key={'row' + { rowNr } + 'gap-after' + gapNr}></td>);
                //         }
                //     }
                // }
            }
        } else {
            for (
                let seatNr = blockChecker.endSeatCountAt(rowNr);
                seatNr >= blockChecker.startSeatCountAt(rowNr);
                seatNr--
            ) {
                if (rowSettings?.gaps && rowSettings?.gaps?.length > 0) {
                    const gap = rowSettings.gaps.find((g) => g.seatToShift - 1 === seatNr);
                    if (gap) {
                        for (let gapNr = 0; gapNr < gap.shiftNrOfGaps; gapNr++) {
                            seats.push(<td key={'row' + { rowNr } + 'gap-before' + gapNr}></td>);
                        }
                    }
                }
                seats.push(this.createSeat(rowNr, seatNr));
                if (blockChecker.startSeatCountAt(rowNr) === seatNr) {
                    if (rowSettings?.gaps && rowSettings?.gaps?.length > 0) {
                        const gap = rowSettings.gaps.find((g) => g.seatToShift === seatNr);
                        if (gap) {
                            for (let gapNr = 0; gapNr < gap.shiftNrOfGaps; gapNr++) {
                                seats.push(<td key={'row' + { rowNr } + 'gap-after' + gapNr}></td>);
                            }
                        }
                    }
                }
            }
        }
        if (!block.hideRowNr && block.showRowNrRight) {
            seats.push(
                <th key={'row-label-' + rowNr} className="row-label">
                    R{rowNr}
                </th>,
            );
        }
        return seats;
    }

    render() {
        const block = this.props.block;
        const blockChecker = new BlockChecker(block);
        return (
            <table className="seats-block" style={{ display: 'inline' }}>
                <thead>
                    {(!block.hideName || this.props.devMode) && (
                        <tr className="seats-block-title-row" key={'seats-block-title-row'}>
                            <th
                                className="seats-block-title"
                                colSpan={block.seatsInRow + 1}
                                style={{ textAlign: 'left' }}
                            >
                                {block.name}
                                {this.props.devMode && <span> BlockId: {block.id}</span>}
                            </th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {!block.hideSeatNr && !block.showSeatNrAtTheEnd && (
                        <tr key={'seats-label-row-header'} className="seats-label-row">
                            {this.createSeatHeaders()}
                        </tr>
                    )}
                    {this.createRows(blockChecker)}
                    {!block.hideSeatNr && block.showSeatNrAtTheEnd && (
                        <tr key={'seats-label-row-footer'} className="seats-label-row">
                            {this.createSeatHeaders()}
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}
