export const Archive2016 = () => {
    return (
        <>
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_kb_orchester_01.jpg"
                            alt="Krueger Brothers Chamber Ensemble"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 165, marginTop: 8 }}
                            src="/images/konzerte/konzert_2013_kb_02.png"
                            alt="Krueger Brothers"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Martin Trostel
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KR&Uuml;GER BROTHERS &amp; The Chamber Ensemble &laquo;THE SYMPHONY OF THE MOUNTAINS&raquo;
                        (USA/CH)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Montag, 31. Oktober 2016
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19.00 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 75.&ndash;
                                <br />
                                <span className="color1">ORT:</span> Landgasthof Riehen, Im Grossen Festsaal
                                <br />
                                Baselstrasse 38, 4125 Riehen, BS
                                <br />
                                <a
                                    href="http://www.landgasthof-riehen.ch/salle/salle_de.html"
                                    title="Landgasthof Riehen"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.landgasthof-riehen.ch
                                </a>
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.krugerbrothers.com"
                                title="Krueger Brothers"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.krugerbrothers.com
                            </a>
                        </p>
                    </div>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 300, border: 0, marginBottom: 10, marginTop: 60 }}
                            src="/images/konzerte/konzert_2016_kb_orchester_01.png"
                            alt="Krueger Brothers Chamber Ensemble"
                        />
                    </p>
                    <h3>Die Musik-Sensation des Jahres!</h3>
                    <p className="Stil1">
                        {' '}
                        Die Kr&uuml;ger Brothers pr&auml;sentieren
                        <br />
                    </p>
                    <p> </p>
                    <p>
                        <strong>
                            &laquo;THE SPIRIT OF THE ROCKIES&raquo; &ndash; Live on Stage <br />
                            mit dem Chamber Ensemble &laquo;The Symphony Of The Mountains&raquo; aus Tennessee, USA
                        </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die Geschichte der reichhaltigen Berg-Kultur im Westen Kanadas ist untrennbar mit der Geschichte
                        der Schweizer Bergf&uuml;hrer verbunden.{' '}
                    </p>
                    <p>
                        <br />
                        <strong>&laquo;THE SPIRIT OF THE ROCKIES&raquo;</strong>
                        <strong> </strong>ist ein Meisterwerk, konzipiert f&uuml;r ein Kammerorchester mit 15 Musikern
                        und den <strong>Kr&uuml;ger Brothers</strong>. Komponiert wurde es von{' '}
                        <strong>Jens Kr&uuml;ger</strong>. Seine Begabung als Komponist, Arrangeur und Interpret,
                        kombiniert mit seiner einzigartigen F&auml;higkeit, die Vielfalt von kulturellen und
                        musikalischen Traditionen feinmaschig zusammenzuf&uuml;hren, zieht sich wie ein roter Faden
                        durch sein bisheriges Schaffen. Die exquisite Komposition von
                        <strong>Jens Kr&uuml;ger</strong> zusammen mit <strong>Uwe Kr&uuml;ger&lsquo;s</strong> Texten
                        nimmt die Zuh&ouml;rerInnen mit auf eine lyrisch-poetische Reise, die am Ende des 19.
                        Jahrhunderts angelegt ist.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Das Werk <strong>&laquo;THE SPIRIT OF THE ROCKIES&raquo;</strong> werden die
                        <strong>Kr&uuml;ger Brothers</strong> zusammen mit dem Chamber Ensemble{' '}
                        <strong>&laquo;The Symphony Of The Mountains&raquo;</strong> in voller L&auml;nge im zweiten Set
                        auff&uuml;hren. Im ersten Konzertteil pr&auml;sentieren die{' '}
                        <strong>Kr&uuml;ger Brothers</strong> Lieder aus ihrem Repertoire, und setzen dabei situativ
                        Musiker des Orchesters ein. Diese Kombination wird den bekannten Liedern eine zus&auml;tzliche
                        Dynamik verleihen.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        {' '}
                        Diese einmalige und geniale musikalische Reise der <strong>Kr&uuml;ger Brothers</strong> mit dem
                        Chamber Ensemble <strong>&laquo;The Symphony Of The Mountains&raquo;</strong> sollte sich
                        niemand entgehen lassen.
                    </p>
                    <p>&nbsp; </p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20161031_kb_orchester_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20161031_kb_orchester_lo.jpg"
                                alt="Krüger Brothers & Chamber Ensemble"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_jlwss_01.jpg"
                            alt="JAMIE LIN WILSON &amp; SUNNY SWEENEY"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 165, marginTop: 8 }}
                            src="/images/konzerte/konzert_2016_jlwss.png"
                            alt="JAMIE LIN WILSON &amp; SUNNY SWEENEY"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Jamie Lin Wilson, <br />
                        Sunny Sweeney
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-R&Uuml;CKBLICK <br />
                        JAMIE LIN WILSON & SUNNY SWEENEY (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Sonntag, 9. Oktober 2016
                            </b>
                        </p>
                        <p>
                            Konzert Details:{' '}
                            <a href="konzerte.html#jamielinwilsonsunnysweeney16">JAMIE LIN WILSON & SUNNY SWEENEY</a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.jamielinwilson.com"
                                title="JAMIE LIN WILSON"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.jamielinwilson.com
                            </a>
                        </p>
                        <p>
                            <a
                                href="http://www.sunnysweeney.com"
                                title="SUNNY SWEENEY"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.sunnysweeney.com
                            </a>
                        </p>
                    </div>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https://www.youtube.com/watch?v=k4664jexafQ" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2016_jlwss_youtube.jpg"
                                alt="Jamie Lin Wilson Sunny Sweeney"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Sonntag, 09. Oktober 2016</h4>
                                <a
                                    href="https://www.youtube.com/watch?v=k4664jexafQ"
                                    target="_blank"
                                    title="Konzert Jamie Lin Wilson Sunny Sweeney in Wahlen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 94 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Konzert-Bericht im Countrystyle Magazin</h3>
                    <div className="frame_2 first_frame">
                        <a href="/docs/CS77_Alts-Schlachthus.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 300, marginTop: 15, marginRight: 10 }}
                                src="/docs/CS77_Alts-Schlachthus.jpg"
                                alt="Jamie Lin Wilson Sunny Sweeney Countrystyle"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Sonntag, 09. Oktober 2016</h4>
                                <p>Text: Thomas Kobler, Fotos: Werner Büchi</p>
                                <a
                                    href="/docs/CS77_Alts-Schlachthus.pdf"
                                    target="_blank"
                                    title="Konzert-Bericht Jamie Lin Wilson Sunny Sweeney Countrystyle"
                                >
                                    Konzert-Bericht im Countrystyle zum downloaden: PDF, 1.4 MB.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_jlwss_01.jpg"
                            alt="JAMIE LIN WILSON & SUNNY SWEENEY"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 165, marginTop: 8 }}
                            src="/images/konzerte/konzert_2016_jlwss.png"
                            alt="JAMIE LIN WILSON & SUNNY SWEENEY"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Jamie Lin Wilson, <br />
                        Sunny Sweeney
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>JAMIE LIN WILSON &amp; SUNNY SWEENEY (USA)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 9. Oktober 2016
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19 Uhr (T&uuml;r&ouml;ffnung 18.15 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.&ndash;
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.jamielinwilson.com"
                                title="JAMIE LIN WILSON"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.jamielinwilson.com
                            </a>
                        </p>
                        <p>
                            <a
                                href="http://www.sunnysweeney.com"
                                title="SUNNY SWEENEY"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.sunnysweeney.com
                            </a>
                        </p>
                    </div>
                    <h3>Jamie Lin Wilson</h3>
                    <p>
                        Zwei Aspekte kommen mir in den Sinn, wenn ich die Stimme von Jamie Lin Wilson h&ouml;re:
                        einerseits dieser honigs&uuml;sse texanische Twang in ihren Folk-Liedern, und andererseits ihre
                        melancholische und bodenst&auml;ndige Poesie.
                        <br />
                        Es ist erstaunlich, dass diese in Folk und Americana-Kreisen &auml;usserst begehrte
                        K&uuml;nstlerin erst im vergangenen Jahr mit &laquo;Holidays And Wedding Rings&raquo; ihr erstes
                        &laquo;richtiges&raquo; Solo-Album ver&ouml;ffentlichte, das von verschiedenen Kritikern zu
                        recht zu den Top-Alben des vergangenen Jahres gekr&ouml;nt wurde.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Nach einigen Jahren als Co-Lead S&auml;ngerin der Gougers gab Jamie Lin Wilson ihre EP
                        &laquo;Dirty Blonde Hair&raquo; heraus und gr&uuml;ndete kurze Zeit sp&auml;ter &laquo;The
                        Trishas&raquo;. Dieses Frauenquartett eroberte nicht nur Texas im Sturm, sondern hinterliess
                        auch auf ausgedehnten Tourneen in den USA einen nachhaltigen Eindruck und gewann viele Fans.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Und nun kommt Jamie Lin Wilson zum ersten mal nach Europa !<br />
                    </p>
                    <h3>Sunny Sweeney</h3>
                    <p>
                        Sunny Sweeney startete ihre Karriere wie viele K&uuml;nstler aus Texas. Sie sang jahrelang ihre
                        selbstgeschriebenen Lieder f&uuml;r Trinkgeld, f&uuml;r Bier und f&uuml;r einen Burger in
                        irgendwelchen Honky Tonks im Lone Star State. Dann ver&ouml;ffentlichte sie ihr erstes Album,
                        das in Texas einschlug wie eine Bombe. Es kamen Tour-neen in den USA und Europa. Und schon war
                        die Musikindustrie aus Nashville zur Stelle und das Leben von Sunny Sweeney nahm einen anderen
                        Lauf :
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Mit &laquo;From A Table Away&raquo; der erste Top Ten-Hit in den Billboard Charts, &uuml;ber 40
                        Auftritte in der Grand Ole Opry (das Mecca der Country Musik), glitzernde Musik Videos,
                        Award-Nominierungen (u.a. f&uuml;r Best New Female Vocalist im 2013 der CMA), Stadien-Tourneen
                        als Opener f&uuml;r die Superstars, auf den Front-Seiten der Glamour Magazine. Sunny Sweeney
                        lebte ihren Traum !{' '}
                    </p>
                    <p>&nbsp; </p>
                    <p>
                        Aber in ihrem privaten Leben zollte sie Tribut und ihre Ehe zerbrach. Doch Sunny Sweeney blieb
                        sich und ihrem texanischen Spirit stets treu, und mit der Ver&ouml;ffentlichung ihres letzten
                        Albums &laquo;Provoked&raquo; schuf sie ihr bislang pers&ouml;nlichstes Album, das alle ihre
                        Erlebnisse &uuml;ber die letzten Jahre perfekt zusammenfasst, erhielt wieder ihre
                        k&uuml;nstlerische Unabh&auml;ngigkeit und fand neues Gl&uuml;ck ! Sunny Sweeney freut sich auf
                        ihre ersten Auftritte in der Schweiz.
                    </p>
                    <p>&nbsp; </p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20161009_jlwss_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20161009_jlwss_lo.jpg"
                                alt="JAMIE LIN WILSON & SUNNY SWEENEY"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_ejb_01.jpg"
                            alt="Eilen Jewell Band"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 165, marginTop: 8 }}
                            src="/images/konzerte/konzert_2016_ejb.png"
                            alt="Eilen Jewell Band Brothers"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Eilen Jewell
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-R&Uuml;CKBLICK
                        <br />
                        EILEN JEWELL BAND (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Dienstag, 13. September 2016
                            </b>
                        </p>
                        <p>
                            Konzert Details: <a href="konzerte.html#eilenjewellband16">EILEN JEWELL BAND</a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.eilenjewell.com"
                                title="Eilen Jewell Band"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.eilenjewell.com
                            </a>
                        </p>
                    </div>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https://www.youtube.com/watch?v=7Nc0uAxPbHI" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2016_ejb_youtube.jpg"
                                alt="EILEN JEWELL BAND"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Dienstag, 13. September 2016</h4>
                                <a
                                    href="https://www.youtube.com/watch?v=7Nc0uAxPbHI"
                                    target="_blank"
                                    title="Konzert HIGH PLAINS CHAMBOREE in Wahlen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 112 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_ejb_01.jpg"
                            alt="Eilen Jewell Band"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 165, marginTop: 8 }}
                            src="/images/konzerte/konzert_2016_ejb.png"
                            alt="Eilen Jewell Band Brothers"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Eilen Jewell
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>EILEN JEWELL BAND (USA)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Dienstag, 13. September 2016
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19.15 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.&ndash;
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.eilenjewell.com"
                                title="Eilen Jewell Band"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.eilenjewell.com
                            </a>
                        </p>
                    </div>
                    <h3>&nbsp; </h3>
                    <p>
                        Die musikalischen Wurzeln von <strong>Eilen Jewell</strong> liegen in den 50er- und 60er-Jahren.
                        Sie sch&ouml;pft aus einem Fundus, zu dem der Rock&rsquo;n&rsquo; Roll und Rockabilly ebenso wie
                        Rhythm&rsquo;n&rsquo; Blues, Bluegrass, Gospel und die klassische swingende und tw&auml;ngende
                        Countrymusik geh&ouml;ren. <strong>Eilen Jewell</strong> hat eine Vorliebe f&uuml;r abgespeckte
                        Musik und gitarrenlastige Sounds. <br />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Eilen Jewell</strong> ist mit einer charakteristischen Stimme ausgestattet, die mal
                        rockt und rollt, mal raunt und verf&uuml;hrt, mal lieblich wispert und dann wieder ganz verloren
                        und einsam klingt. Lyrisch beeinflusst ist <strong>Eilen Jewell</strong> etwa von John Steinbeck
                        und William Faulkner. Musikalisch ist sie inspiriert von Loretta Lynn, Woody Guhrie, Billie
                        Holliday und Mavis Staples, weshalb sie auch zwischen Alternative Country, Roots Musik,
                        Americana sowie jazzigem Swing hin und her wandelt.
                        <br />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die <strong>Band von Eilen Jewell</strong> kann alles: Sie l&auml;sst es flocken und swingen,
                        rockt druckvoll, tupft z&auml;rtlich an einer Ballade herum, swingt oder legt einen wippenden,
                        federnden Rhythmus hin. Kontrabassist <strong>Johnny Sciasca</strong> und Drummer
                        <strong>Jason Beck</strong> sind Meister der Rhythmen. Ein Hammer ist Gitarrist{' '}
                        <strong>Jerry Miller</strong>, graue Locken, Cowboyhut, Sonnenbrille, der immerzu mit seiner
                        Gitarre spricht, als wolle er ihr gut zureden. <br />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Eilen Jewell</strong> und ihre Band werden uns einen grossartigen und stimmungsvollen
                        Abend garantieren. Lasst euch von ihren Melodien verzaubern.{' '}
                        <strong>Eilen Jewell&rsquo;s</strong> musikalische Vielseitigkeit sowie die Geschichten, die sie
                        erz&auml;hlt sind grosses Kino.
                    </p>
                    <p>&nbsp; </p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20160913_ejb_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20160913_ejb_lo.jpg"
                                alt="Eilen Jewell Band"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_hpj_01.jpg"
                            alt="High Plains Jamboree"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_hpj_03.jpg"
                            alt="High Plains Jamboree"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2016_hpj_02.png"
                            alt="High Plains Jamboree"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        High Plains Jamboree
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-RÜCKBLICK <br />
                        HIGH PLAINS JAMBOREE (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Dienstag, 28. Juni 2016
                            </b>
                        </p>
                        <p>
                            Konzert Details: <a href="konzerte.html#highplainsjamboree16">HIGH PLAINS JAMBOREE</a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.highplainsjamboree.com/"
                                title="High Plains Jamboree"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.highplainsjamboree.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https://www.youtube.com/watch?v=XZy5qvEbVLA" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2016_hpj_youtube.jpg"
                                alt="HIGH PLAINS JAMBOREE"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Sonntag, 6. M&auml;rz 2016</h4>
                                <a
                                    href="https://www.youtube.com/watch?v=XZy5qvEbVLA"
                                    target="_blank"
                                    title="Konzert HIGH PLAINS CHAMBOREE in Wahlen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 108 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_hpj_01.jpg"
                            alt="High Plains Jamboree"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_hpj_03.jpg"
                            alt="High Plains Jamboree"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2016_hpj_02.png"
                            alt="High Plains Jamboree"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        High Plains Jamboree
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>HIGH PLAINS JAMBOREE (USA)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Dienstag, 28. Juni 2016
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19.15 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.&ndash;
                                <br />
                                <span className="color1">ORT:</span> Neuhof Sch&uuml;&uuml;re in Wahlen bei Laufen
                                <br />
                                <a
                                    href="http://www.neuhof-schüüre.ch"
                                    title="Neuhof Schüüre Wahlen"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.neuhof-schüüre.ch
                                </a>
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.highplainsjamboree.com/"
                                title="High Plains Jamboree"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.highplainsjamboree.com
                            </a>
                        </p>
                    </div>
                    <h3>&nbsp; </h3>
                    <p>
                        Diese neue Formation aus Austin besteht aus der Geigerin und S&auml;ngerin{' '}
                        <strong>Beth Chrisman</strong>, dem Bassisten <strong>Simon Flory</strong>, dem Liederschreiber,
                        Gitarristen und S&auml;nger <strong>Noel McKay</strong> und der aussergew&ouml;hnlichen
                        <strong>Brennen Leigh</strong> an der Mandoline und Gesang.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die vier Musiker zelebrieren einen eigenen Musikstil, was in Texas &uuml;blich ist. Sie
                        verbinden dabei Elemente von akustischer Musik mit jenen der Troubadour Country K&uuml;nstler
                        wie Ernest Tubb. ?Zum Repertoire der Band geh&ouml;ren auch Lieder, die man vor Jahrzehnten noch
                        aus den mittlerweile verschwundenen Musikautomaten h&ouml;ren konnte, und vor allem selbst
                        geschriebene Lieder.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die treibende und bekannteste Kraft hinter dem Quartett ist <strong>Brennen Leigh</strong>. In
                        Austin gibt es zur Zeit mehrere Musikprojekte, an denen sie beteiligt ist.{' '}
                        <strong>Brennen Leigh</strong> hat in der Vergangenheit diverse brillante und vielf&auml;ltige
                        Alben aufgenommen, die ihre Extraklasse als S&auml;ngerin und Liederschreiberin immer wieder
                        best&auml;tigten. Sie &uuml;berzeugt in vielen Genres, ob in der Gospel-Musik, im Folk, in der
                        traditionellen Country Musik oder der Honky Tonk Musik. Und die Musikkritiker sind jeweils des
                        Lobes voll, sobald ein neues Album von ihr er-scheint. Zu ihren brillantesten Werken
                        geh&ouml;ren &bdquo;The Box&ldquo; oder das brandneue &bdquo;Sings Lefty Frizzell&ldquo;, die
                        beide in jede seri&ouml;se Plattensammlung geh&ouml;ren !
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Im Jahre 2013 ver&ouml;ffentlichte <strong>Brennen Leigh</strong> zudem zusammen mit
                        <strong>Noel McKay</strong> das von den Kritikern begeistert aufgenommene Album &bdquo;Before
                        The World Was Made&ldquo;. Vergleiche wurden mit Gr&ouml;ssen der Country Musik wie George Jones
                        und Melba Montgomery wurden sofort angestellt.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Es ist mir eine besondere Freude, die Europa Premiere f&uuml;r dieses texanische Quartett
                        pr&auml;sentieren zu k&ouml;nnen.
                    </p>
                    <p>&nbsp; </p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20160628_hpj_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20160628_hpj_lo.jpg"
                                alt="High Plains Jamboree"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_abaq_02.jpg"
                            alt="Alison Brown Acoustic Quartet"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2016_abaq.png"
                            alt="Alison Brown Acoustic Quartet"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Alison Brown <br />
                        Acoustic Quartet
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-RÜCKBLICK <br />
                        ALISON BROWN ACOUSTIC QUARTET (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Sonntag, 12. Juni 2016
                            </b>
                        </p>
                        <p>
                            Konzert Details:{' '}
                            <a href="konzerte.html#alisonbrownacousticquartet16">ALISON BROWN ACOUSTIC QUARTET</a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.alisonbrown.com"
                                title="ALISON BROWN ACOUSTIC QUARTET"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.alisonbrown.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https://www.youtube.com/watch?v=GN4w1Ix704c" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2016_abaq_youtube.jpg"
                                alt="ALISON BROWN ACOUSTIC QUARTET"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Sonntag, 12. Juni 2016</h4>
                                <a
                                    href="https://www.youtube.com/watch?v=GN4w1Ix704c"
                                    target="_blank"
                                    title="Konzert ALISON BROWN ACOUSTIC QUARTET (USA) in Wahlen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 122 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_abaq_02.jpg"
                            alt="Alison Brown Acoustic Quartet"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2016_abaq.png"
                            alt="Alison Brown Acoustic Quartet"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Alison Brown <br />
                        Acoustic Quartet
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>ALISON BROWN ACOUSTIC QUARTET (USA)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Sonntag, 12. Juni 2016
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 18.30 Uhr
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 45.&ndash;
                                <br />
                                <span className="color1">ORT:</span> Neuhof Sch&uuml;&uuml;re in Wahlen bei Laufen
                                <br />
                                <a
                                    href="http://www.neuhof-schüüre.ch"
                                    title="Neuhof Schüüre Wahlen"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.neuhof-schüüre.ch
                                </a>
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.alisonbrown.com"
                                title="ALISON BROWN ACOUSTIC QUARTET"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.alisonbrown.com
                            </a>
                        </p>
                    </div>
                    <h3>&nbsp; </h3>
                    <p>
                        Seit ihrer Kindheit spielt Alison Brown Gitarre und Banjo. Weltweit bekannt wurde sie aber vor
                        allem als Virtuosin und durch den charakteristischen weichen Klang ihres Banjos, f&uuml;r
                        welches sie Nylonsaiten benutzt. Sie ist Tr&auml;gerin mehrerer Grammys und ist f&uuml;r weitere
                        nominiert worden. Alison Brown wird wegen ihrer besonderen Spielweise oft mit Bel&agrave; Fleck,
                        einem anderen Wunderkind des Banjos, zusammen genannt. Ihre vielseitige Musik ist eine Mischung
                        aus Jazz und Bluegrass mit Einfl&uuml;ssen anderer Genres.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        1980 ging Alison Brown zur Harvard Uni und studierte Geschichte und Literatur. Nach dem
                        Abschluss in Harvard erlangte sie den Grad eines Master of Business Administration an der
                        University of California in Los Angeles. Danach arbeitete sie w&auml;hrend zweier Jahre f&uuml;r
                        eine Investment Bank. Doch die Liebe zur Musik liess Alison Brown nie los, und 1987 trat sie der
                        Union Station bei, der Band von Alison Krauss. In den fr&uuml;heren 90er Jahren startete sie
                        dann ihre Solokarriere und nahm ihre ersten Platten auf. Dabei baute Alison Brown auch ethnische
                        und jazzige Kl&auml;nge in ihre Musik ein.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Egal ob Bluegrass, Jazz, Newgrass, keltische Musik, Folk oder Country, Alison Brown zeichnet
                        sich durch ihre besondere Gabe f&uuml;r Melodien aus. Mit ihrem aussergew&ouml;hnlichen
                        Banjospiel zeigt Alison Brown eindr&uuml;cklich auf, dass das Banjo hinsichtlich melodischer
                        Flexibilit&auml;t einer Gitarre in Nichts nachsteht.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Alison Brown wird mit ihrem akustischen Quartet auftreten. Die drei &uuml;brigen Musiker sind
                        Garry West (Bass, Vocals), Matt Flinner (Mandoline) und Bryan McDowell (Gitarre, Geige, Vocals).
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Lasst Euch von dieser einmaligen Kombination, zu welcher Alison Brown&rsquo;s &uuml;berragendes
                        Banjospiel hervorragend passt, &uuml;berzeugen.
                    </p>
                    <p>&nbsp; </p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20160612_abaq_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20160612_abaq_lo.jpg"
                                alt="Alison Brown Acoustic Quartet"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_clb_01.jpg"
                            alt="Claire Lynch Band"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2013_clb_01.jpg"
                            alt="Claire Lynch Band"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2013_clb_02.png"
                            alt="Claire Lynch Band"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Claire Lynch Band
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-RÜCKBLICK <br />
                        CLAIRE LYNCH BAND (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Donnerstag, 19. Mai 2016
                            </b>
                        </p>
                        <p>
                            Konzert Details: <a href="konzerte.html#clairelynchband16">CLAIRE LYNCH BAND</a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.clairelynch.com/" title="Claire Lynch" target="_blank" rel="noreferrer">
                                www.clairelynch.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https://www.youtube.com/watch?v=N0eldkbkdJk" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2016_clb_youtube.jpg"
                                alt="CLAIRE LYNCH BAND"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Sonntag, 12. Juni 2016</h4>
                                <a
                                    href="https://www.youtube.com/watch?v=N0eldkbkdJk"
                                    target="_blank"
                                    title="Konzert CLAIRE LYNCH BAND (USA) in Wahlen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 137 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_clb_01.jpg"
                            alt="Claire Lynch Band"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2013_clb_01.jpg"
                            alt="Claire Lynch Band"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 10 }}
                            src="/images/konzerte/konzert_2013_clb_02.png"
                            alt="Claire Lynch Band"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Claire Lynch Band
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>CLAIRE LYNCH BAND (USA)</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Donnerstag, 19. Mai 2016
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19.15 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.&ndash;
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.clairelynch.com/" title="Claire Lynch" target="_blank" rel="noreferrer">
                                www.clairelynch.com
                            </a>
                        </p>
                    </div>
                    <h3>&nbsp; </h3>
                    <p>
                        <strong>Claire Lynch</strong> ist schon seit Jahrzehnten eine der kreativsten K&uuml;nstlerinnen
                        im Bereich der akustischen Musik. Sie ist auch eine der wenigen Frauen, die die Grenzen der
                        Bluegrass Musik gesprengt haben und mit Elementen aus Folk, Swing, Country, Americana, Jazz und
                        Blues umspannen.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die sympathische <strong>Claire Lynch</strong> begeistert mit ihrer ausdrucksstarken,
                        gef&uuml;hlvollen und unverwechselbaren Stimme. <strong>Claire Lynch</strong> (Leadgesang,
                        akustische Gitarre) wird begleitet von <strong>Mark Schatz</strong> (Bass, Clawhammer Banjo),
                        <strong>Jarrod Walker</strong> (akustische Gitarre, Mandoline, Gesang) und{' '}
                        <strong>Bryan McDowell</strong> (Mandoline, Violine, Gitarre, Gesang).
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Die auf allerh&ouml;chstem Niveau spielende <strong>Claire Lynch Band</strong> verbindet
                        Tradition und Innovation und zelebriert neben kraftvollem sowie virtuosem Bluegrass auch auf
                        agile sowie filigrane Weise Swing, Folk, Irish-Folk , Country und Klassik. An musikalischer
                        Kreativit&auml;t ist das, was die Musiker pr&auml;sentieren, kaum zu &uuml;berbieten. Im
                        Mittelpunkt steht aber stets das Singer-Songwriter-Genre mit vielen wundersch&ouml;nen Melodien
                        und <strong>Claire&rsquo;s</strong> bezaubernder Stimme. Musik zum Dahinschmelzen !{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Ein aussergew&ouml;hnliches, beeindruckendes und &auml;usserst abwechslungsreiches
                        Konzertereignis ist garantiert! Als Musikfan muss man einfach die{' '}
                        <strong>Claire Lynch Band</strong> erleben und erlebt haben ! Es d&uuml;rfte einer der letzten
                        Auftritte der Formation sein, die f&uuml;r 2016 ihre Abschiedstournee angek&uuml;ndigt hat.
                    </p>
                    <p>&nbsp; </p>
                    <p>&nbsp; </p>
                    <blockquote>
                        <p>
                            Claire has one of the sweetest, purest and best lead voices in the music business today.
                            <br />
                            <span className="color3 quelle">&ndash; Dolly Parton</span>
                        </p>
                    </blockquote>
                    <p>&nbsp; </p>
                    <blockquote>
                        <p>
                            I&rsquo;ve always thought Claire Lynch has the voice of an angel. I&rsquo;m a fan.
                            <br />
                            <span className="color3 quelle">&ndash; Emmylou Harris</span>
                        </p>
                    </blockquote>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20160519_clb_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20160519_clb_lo.jpg"
                                alt="Clair Lynch Band"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_tvub_01.jpg"
                            alt="Toni Vescoli und Bänd"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_tvub_03.jpg"
                            alt="Toni Vescoli und Bänd feat. Amanda Shaw"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 165, marginTop: 8 }}
                            src="/images/konzerte/konzert_2016_tvub_02.png"
                            alt="Toni Vescoli und Bänd"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Toni Vescoli
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        TONI VESCOLI &amp; B&Auml;ND
                        <br />
                        mit Gaststar AMANDA SHAW (USA)
                    </h2>
                    <div className="frame_1 ausverkauft">
                        <p>
                            <strong>
                                <span className="color1">DATUM:</span> Sonntag, 6. M&auml;rz 2016
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 19.00 Uhr (T&uuml;r&ouml;ffnung 18.15
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.&ndash;
                            </strong>
                            <br />
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.vescoli.ch" target="_blank" rel="noreferrer">
                                www.vescoli.ch
                            </a>
                        </p>
                        <p>
                            <a href="http://www.amandashaw.com" target="_blank" rel="noreferrer">
                                www.amandashaw.com
                            </a>
                        </p>
                    </div>
                    <h3>&nbsp;</h3>
                    <p>
                        Die hochkaratige Band um <strong>Toni Vescoli</strong> mit <strong>Markus Maggi</strong>
                        (Keyboards, Akkordeon, Gesang), <strong>Peter Glanzmann</strong> (Gitarren, Gesang),
                        <strong>Christoph Beck</strong> (Schlagzeug, Gesang) und <strong>Felix M&uuml;ller</strong>
                        (Bass, &shy;Gesang) geht nach sechs Jahren erstmals wieder auf Tour und wird auch in diesem Jahr
                        in Laufen f&uuml;r beste Unterhaltung sorgen.
                    </p>
                    <p>
                        <br />
                        Aber es kommt noch besser !
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Wie schon bei seinem letzten Auftritt im &laquo;Alts Schlachthuus&raquo; in Laufen hat
                        <strong>Toni Vescoli</strong> auch in diesem Jahr die aus New Orleans stammende Fiddlerin
                        <strong>Amanda Shaw</strong> eingeladen. Mit dieser &laquo;Teufelsgeigerin&raquo; kommt der
                        Wirbelwind auf die B&uuml;hne. So kraftvoll wie sie Geige spielt und &uuml;ber die B&uuml;hne
                        fegt, singt sie auch. <strong>Amanda Shaw</strong> wird mit ihrer Geige und ihrer Spielfreude
                        dem ohnehin schon abwechslungsreichen Programm wiederum m&auml;chtig Auftrieb geben.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Toni Vescoli</strong> wird uns einen Querschnitt seines musikalischen Schaffens
                        pr&auml;sentieren. Lieder auf Mundart und Englisch, aber auch auf Franz&ouml;sisch und Spanisch
                        sind angesagt, ebenso nat&uuml;rlich auch viel mehrstimmiger Gesang, Gitarrensound, Fiddle und
                        Akkordeon. Und <strong>Amanda Shaw</strong> wird selbstverst&auml;ndlich eigene Lieder
                        pr&auml;sentieren.{' '}
                    </p>
                    <p>
                        <br />
                        Den Besuchern gefiel der letzte Auftritt in Laufen ausserordentlich. Eine Standing Ovation am
                        Schluss des offiziell letzten St&uuml;ckes forderte die f&uuml;nfk&ouml;pfige Band und den
                        Gaststar <strong>Amanda Shaw</strong> f&uuml;r eine weitere halbe Stunde auf die B&uuml;hne !
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Lasst Euch den Auftritt des sympathischen <strong>Toni Vescoli</strong> mit seiner B&auml;nd und
                        mit seinem Spezialgast <strong>Amanda Shaw</strong> nicht entgehen !{' '}
                    </p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20160306_tvub_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20160306_tvub_lo.jpg"
                                alt="Toni Vescoli und Bänd feat. Amanda Shaw"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_tvub_04.jpg"
                            alt="Toni Vescoli und Bänd"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_tvub_05.jpg"
                            alt="Toni Vescoli und Bänd feat. Amanda Shaw"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_tvub_06.jpg"
                            alt="Toni Vescoli und Bänd feat. Amanda Shaw"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_tvub_07.jpg"
                            alt="Toni Vescoli und Bänd feat. Amanda Shaw"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2016_tvub_08.jpg"
                            alt="Toni Vescoli und Bänd feat. Amanda Shaw"
                        />
                    </p>
                    <div>
                        <img
                            style={{ width: 165, marginTop: 8 }}
                            src="/images/konzerte/konzert_2016_tvub_02.png"
                            alt="Toni Vescoli und Bänd"
                        />
                    </div>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Patrick Surbeck
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-RÜCKBLICK <br />
                        TONI VESCOLI &amp; B&Auml;ND
                        <br />
                        mit Gaststar AMANDA SHAW (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Sonntag, 6. M&auml;rz 2016
                            </b>
                        </p>
                        <p>
                            Konzert Details:{' '}
                            <a href="konzerte.html#tonivescoliundband16">
                                TONI VESCOLI &amp; B&Auml;ND mit Gaststar AMANDA SHAW
                            </a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.vescoli.ch" target="_blank" rel="noreferrer">
                                www.vescoli.ch
                            </a>
                        </p>
                        <p>
                            <a href="http://www.amandashaw.com" target="_blank" rel="noreferrer">
                                www.amandashaw.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https://www.youtube.com/watch?v=703t8SVzf4o" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: 100, marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2016_tvub_01_youtube.jpg"
                                alt="Toni Vescoli und Bänd mit Gaststar Amanda Shaw"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Sonntag, 6. M&auml;rz 2016</h4>
                                <a
                                    href="https://www.youtube.com/watch?v=703t8SVzf4o"
                                    target="_blank"
                                    title="Konzert ROB ICKES & TREY HENSLEY in Laufen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 146 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2016;
