import { DateTimeUtils } from 'cm-utils';
import { IConcertDto, IConcertSeriesDto } from '../../clients/services';
import { Price } from './Price';

export const ConcertData = (props: {
    concert: IConcertDto;
    showConcertTitle?: boolean;
    showReductions?: boolean;
    concertSeries?: IConcertSeriesDto;
}) => {
    return (
        <div style={{ fontWeight: 'bolder' }}>
            {props.showConcertTitle &&
                props.concert.title &&
                props.concertSeries?.title &&
                props.concertSeries.title !== props.concert.title && (
                    <>
                        <span className="color1">Konzert:</span> {props.concert.title}
                        <br />
                    </>
                )}
            {props.showConcertTitle && (
                <>
                    {props.concert.location?.name && (
                        <>
                            <span className="color1">ORT:</span> {props.concert.location.name},{' '}
                            {props.concert.location.city}
                            <br />
                        </>
                    )}
                </>
            )}
            {!props.concert.postponedToUnknownDate ? (
                <>
                    {props.concert.date && (
                        <>
                            {props.concert.originalDate ? (
                                <>
                                    <span className="color2">NEUES DATUM:</span>{' '}
                                    {DateTimeUtils.formatLong(props.concert.date)} (anstatt{' '}
                                    <span className="color1" style={{ textDecoration: 'line-through' }}>
                                        {DateTimeUtils.formatLong(props.concert.originalDate)}
                                    </span>
                                    )
                                </>
                            ) : (
                                <>
                                    <span className="color1">DATUM:</span>{' '}
                                    {DateTimeUtils.formatLong(props.concert.date)}{' '}
                                </>
                            )}
                            <br />
                            {props.concert.start && (
                                <>
                                    <span className="color1">BEGINN:</span> {props.concert.start} Uhr{' '}
                                    {props.concert.doors && <>(T&uuml;r&ouml;ffnung {props.concert.doors} Uhr)</>}
                                    <br />
                                </>
                            )}
                        </>
                    )}
                    <Price concert={props.concert} showReductions />
                </>
            ) : (
                <>
                    <span className="color2">VERSCHOBEN</span>{' '}
                    <span style={{ textDecoration: 'line-through' }}>
                        <>{DateTimeUtils.formatLong(props.concert.originalDate ?? props.concert.date)}</>
                    </span>
                    {props.concert.originalDate && (
                        <> (anstatt {DateTimeUtils.formatLong(props.concert.originalDate)})</>
                    )}
                    <br />
                </>
            )}
        </div>
    );
};
