import React from 'react';
import { IConcertDto } from '../../clients/services';

type Props = {
    concert: IConcertDto;
    showReductions?: boolean;
};

export const Price: React.FC<Props> = (props) => {
    return (
        <>
            {Number(props.concert.price ?? 0) > 0 && (
                <>
                    {props.concert.saleOptions &&
                    props.concert.saleOptions.options &&
                    props.concert.saleOptions.options?.length > 0 ? (
                        <>
                            <span className="color1">TICKETPREISE:</span>
                            <ul style={{ marginLeft: '20px' }}>
                                {props.concert.saleOptions.options.map((o, k) => (
                                    <>
                                        {
                                            <li key={o.id}>
                                                {o.text} CHF {o.price}.&ndash; <>{o.isAdditional && <>(optional)</>}</>
                                            </li>
                                        }
                                    </>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <>
                            <span className="color1">TICKETPREIS:</span> CHF {props.concert.price}.&ndash;
                        </>
                    )}
                    {props.showReductions &&
                        props.concert.saleOptions &&
                        props.concert.saleOptions.amountReductions &&
                        props.concert.saleOptions.amountReductions?.length > 0 && (
                            <ul style={{ marginLeft: '20px' }}>
                                {props.concert.saleOptions.amountReductions
                                    .filter((r) => r.reduction && r.reduction !== 0)
                                    .map((a, k) => (
                                        <>
                                            {props.concert.price && (
                                                <li key={a.numberOfTickets}>
                                                    Preisreduktion von {a.reduction * 100}% Ticket ab{' '}
                                                    {a.numberOfTickets} Tickets
                                                </li>
                                            )}
                                        </>
                                    ))}
                            </ul>
                        )}
                </>
            )}
        </>
    );
};
