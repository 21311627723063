import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { IImageDto } from '../../clients/services';

export interface SliderItem {
    banner?: IImageDto;
    title: string;
}

export const Slider = (props: { items: SliderItem[] }) => {
    const images = props.items
        .filter((i) => !!i.banner && i.banner.url)
        .map((i) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return { original: i.banner!.url!, originalTitle: i.title };
        });

    return (
        <>
            <div
                style={{
                    width: 960,
                    height: 450,
                    marginBottom: 40,
                    borderBottom: '6px solid #ae3721',
                    borderRadius: '6px',
                    borderTop: '3px solid #8eaee1',
                }}
                className="image_wrapper image-border"
            >
                <ImageGallery
                    items={images}
                    showFullscreenButton={false}
                    autoPlay={true}
                    showNav={true}
                    showThumbnails={false}
                    showBullets={false}
                    slideInterval={6000}
                    showPlayButton={false}
                />
            </div>
        </>
    );
};

export default Slider;
