import { Header } from './components/Header';
import { Outlet } from 'react-router-dom';
import './App.scss';
import { Page } from './components/Page';

export const App = () => {
    return (
        <Page>
            <Header></Header>
            <Outlet />
        </Page>
    );
};

export default App;
