export const Archive2017 = () => {
    return (
        <>
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_tboh_01.jpg"
                            alt="The Band Of Heathens"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2017_tboh.png"
                            alt="The Band Of Heathens"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        The Band Of Heathens
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>THE BAND OF HEATHENS (USA)</h2>
                    <div className="frame_1">
                        <div className="frame_2 first_frame">
                            <p>
                                <span className="color1">NEUER ORT:</span>{' '}
                                <b>
                                    im legend&auml;ren{' '}
                                    <a href="http://www.atlan-tis.ch/de/" target="_blank" rel="noreferrer">
                                        &laquo;Atlantis&raquo;
                                    </a>{' '}
                                    in Basel
                                </b>
                                <br />
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <strong>
                                <span className="color1">DATUM:</span> Dienstag, 23. Mai 2017
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20.15 Uhr (T&uuml;r&ouml;ffnung 17.30
                                Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 50.- (nummerierte Sitzplätze) bzw. CHF
                                45.- (Stehplätze)
                            </strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            {' '}
                            <span className="color2">
                                <strong>ALLE SITZPLÄTZE BEREITS AUSVERKAUFT!</strong>
                            </span>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.thebandofheathens.com"
                                title="The Band Of Heathens"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.thebandofheathens.com
                            </a>
                        </p>
                        <p>
                            <a
                                href="http://www.rollingstone.com/country/news/stream-band-of-heathens-country-rock-new-album-duende-w459942"
                                title="The Band Of Heathens"
                                target="_blank"
                                rel="noreferrer"
                            >
                                The Rolling Stone
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>Zum dritten mal in der Schweiz</h3>
                    <p>
                        Es ist mir wiederum eine grosse Freude, <strong>The Band Of Heathens</strong> nunmehr schon zum
                        dritten mal und erneut exklusiv in der Schweiz pr&auml;sentieren zu k&ouml;nnen.
                    </p>
                    <p>
                        <br />
                        <strong>The Band Of Heathens</strong> hat soeben mit &laquo;Duende&raquo; ihr neuestes Album
                        ver&ouml;ffentlicht. &laquo;Duende&raquo; sprudelt voller Leidenschaft, Stimmung, Dynamik und
                        ansteckender Energie. Der Sound weckt Freude und ist souver&auml;n. Viele Arrangements erinnern
                        mich zeitweise an die unvergesslichen Siebziger-Jahre, die Zeiten der klassischen
                        Southern-Pop-Rock-Westcoast Kl&auml;nge. Mit dynamischen, manchmal funkigen Grooves, exzellenter
                        Instrumentierung, nachdenklichen Texten und harmonischem Gesang nimmt{' '}
                        <strong>The Band Of Heathens</strong> uns Zuh&ouml;rer auf eine spannende und h&ouml;renswerte
                        musikalische Reise. Immer mehr entwickelt sich <strong>The Band Of Heathens</strong> zur eigenen
                        Marke: echt, unverf&auml;lscht, unverwechselbar. <br />
                        In k&uuml;rzester Zeit st&uuml;rmte &laquo;Duende&raquo; die Americana-Charts und erreichte
                        bereits in der dritten Woche den ersten Platz.
                    </p>
                    <p>
                        <br />
                        <strong>The Band Of Heathens</strong> besteht aus den beiden Gr&uuml;ndungsmitgliedern
                        <strong>Ed Jurdi</strong> (Gesang, Gitarren, Harmonica, Keyboards) und{' '}
                        <strong>Gordy Quist</strong> (Gesang, Gitarren, Harmonica). Die &uuml;brigen Musiker schlossen
                        sich der Band im Jahre 2011 an. Es sind dies <strong>Trevor Nealon</strong> (Keyboards),{' '}
                        <strong>Richard Millsap</strong> (Schlagzeug) und <strong>Scott Davis</strong> (Bass, Gesang).
                    </p>
                    <p>&nbsp;</p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20170523_tboh_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20170523_tboh_lo.jpg"
                                alt="The Band Of Heathens"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_km_03.jpg"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_km_06.jpg"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_km_07.jpg"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_km_09.jpg"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_km_10.jpg"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_km_11.jpg"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2017_km.png"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Patrick Surbeck
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>
                        KONZERT-R&Uuml;CKBLICK
                        <br />
                        KELLEY MICKWEE mit Colin Brooks &amp; John Chipman (USA)
                    </h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">KONZERT VOM:</span> Freitag, 12. Mai 2017
                            </b>
                        </p>
                        <p>
                            Konzert Details:{' '}
                            <a href="konzerte.html#kelleymickwee17">
                                KELLEY MICKWEE mit Colin Brooks &amp; John Chipman (USA)
                            </a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.kelleymickwee.com"
                                title="Kelley Mickwee"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.kelleymickwee.com
                            </a>
                        </p>
                    </div>
                    <h3>Konzert-Mitschnitt auf Youtube</h3>
                    <div className="frame_2 first_frame">
                        <a href="https://www.youtube.com/watch?v=PR6ITIYvtmE" target="_blank" rel="noreferrer">
                            <img
                                className="image_border"
                                style={{ width: '100%', marginTop: 15, marginRight: 10 }}
                                src="/images/konzerte/konzert_2017_km_youtube.jpg"
                                alt="Jamie Lin Wilson Sunny Sweeney"
                            />
                        </a>
                        <ul style={{ marginTop: 28 }}>
                            <li>
                                <h4>Sonntag, 09. Oktober 2016</h4>
                                <a
                                    href="https://www.youtube.com/watch?v=PR6ITIYvtmE"
                                    target="_blank"
                                    title="Konzert Jamie Lin Wilson Sunny Sweeney in Wahlen"
                                    rel="noreferrer"
                                >
                                    Konzert-Mitschnitt von Folkert Kakrow: 107 min.
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_km_01.jpg"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2017_km_02.jpg"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p>
                        <img
                            style={{ width: 164, marginTop: 8 }}
                            src="/images/konzerte/konzert_2017_km.png"
                            alt="Kelley Mickwee"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Kelley Mickwee
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>KELLEY MICKWEE mit Colin Brooks &amp; John Chipman (USA)</h2>
                    <div className="frame_1">
                        <div className="frame_2 first_frame">
                            <p>
                                <span className="color1">NEUER ORT:</span>{' '}
                                <b>
                                    im{' '}
                                    <a
                                        href="http://www.kath-breitenbach.ch/adressen.html"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Pfarreisaal der katholischen Kirche
                                    </a>
                                    , Bodenackerstrasse 3 in 4226 Breitenbach.
                                </b>{' '}
                            </p>
                        </div>
                        <p>&nbsp;</p>
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Freitag, 12. Mai 2017
                                <br />
                                <span className="color1">KONZERTBEGINN:</span> 20 Uhr (T&uuml;r&ouml;ffnung 19.15 Uhr)
                                <br />
                                <span className="color1">EINTRITT:</span> CHF 40.&ndash; (alles nummerierte Sitzplätze)
                                <br />
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                href="http://www.kelleymickwee.com"
                                title="Kelley Mickwee"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.kelleymickwee.com
                            </a>
                        </p>
                    </div>
                    <p>&nbsp;</p>
                    <h3>
                        KELLEY MICKWEE <br />
                        mit Colin Brooks (ex-Band Of Heathens)
                        <br />
                        und John Chipman (ex-Band Of Heathens)
                    </h3>
                    <p>
                        <strong>Kelley Mickwee</strong> hat schon gut 15 Jahre Musik-Business hinter sich. W&auml;hrend
                        acht Jahren war sie unterwegs in der Folk Duo-Formation Jed &amp; Kelley und anschliessend
                        nochmal f&uuml;nf mit der Frauenband The Trishas, die von mehreren Quellen als neue Hoffnung im
                        Alternative Country-/Americana-Genre angesehen wurde. Ende 2013 beschloss die Band jedoch,
                        f&uuml;r eine unbestimme Zeit eine Pause einzulegen, was <strong>Kelley Mickwee</strong>
                        veranlasste, eine Solokarriere als Singer-Songwriterin anzufangen.
                        <br />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Kelley Mickwee</strong> wurde in Birmingham, Alabama geboren und wuchs in Memphis,
                        Tennessee auf. Dies vermag auch den bluesigen und souligen Unterton in ihrer kraftvollen und
                        ausdrucksstarken Stimme erkl&auml;ren.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Der Sound ihrer Jugendjahre in Memphis, dieser ganz bestimmte Geist, den man nur in Muscle
                        Shoals antrifft, bestimmt auch den Klang ihres Solodebuts. Eine entspannte Southern-Soul Brise
                        weht durch ihre Aufnahmen, wobei sie ihre Alternative-Country Vergangenheit nie ganz ausser Acht
                        l&auml;sst und so die beiden musikalischen Welten miteinander verkn&uuml;pft. Ganz nach dem
                        Motto: Country-meets-Soul.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Es ist mir eine Freude, <strong>Kelley Mickwee</strong> zum erstenmal und exklusiv in der
                        Schweiz pr&auml;sentieren zu k&ouml;nnen. Sie kommt in Begleitung von{' '}
                        <strong>Colin Brooks</strong> (ehemaliger Teil der Band Of Heathens-Front an der Gitarre) sowie
                        <strong>John Chipman</strong> (ehemaliger Schlagzeuger der Band Of Heathens).
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <blockquote>
                        <p>
                            Wem die enorme Qualit&auml;t der Texanerin bislang verborgen geblieben war, darf sich mit
                            ihrem ersten Solo-Werk auf eine wunderbare Entdeckung freuen. Die Sucht w&auml;chst dabei
                            wirklich mit jedem Durchlauf.
                            <br />
                            <span className="color3 quelle">&ndash; Saarbr&uuml;cker Zeitung</span>
                        </p>
                    </blockquote>
                    <p>&nbsp; </p>
                    <blockquote>
                        <p>
                            die Tracks der Amerikanerin haben es allesamt in sich. Nicht nur, dass sie eine verdammt
                            gute S&auml;ngerin ist, sie hat dazu auch noch ein ganz feines H&auml;ndchen f&uuml;rs
                            Songwriting. &quot;You Used To Live Here&quot; ist ein sehr starkes Album, das man nicht
                            unbeachtet im Regal stehen lassen sollte.
                            <br />
                            <span className="color3 quelle">&ndash; Rocktimes.de</span>
                        </p>
                    </blockquote>
                    <p>&nbsp; </p>
                    <h3>Konzert-Flyer herunterladen</h3>
                    <p>
                        <a href="/docs/flyer_20170512_km_lo.pdf" target="_blank">
                            <img
                                className="image_border"
                                style={{ width: 150 }}
                                src="/docs/flyer_20170512_km_lo.jpg"
                                alt="Kelley Mickwee Colin Brooks John Chipman"
                            />
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp; </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2017;
