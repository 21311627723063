import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'reflect-metadata';
import { Settings } from 'luxon';
import { Route, BrowserRouter, Routes, useParams } from 'react-router-dom';
import { Ticket } from './pages/Tickets/views/Ticket';
import { ReservationView } from './pages/Tickets/steps/ReservationView';
import { PlanView } from './pages/Tickets/views/PlanView';
import { Concerts } from './pages/Tickets/Concerts';
import { TicketCheck } from './pages/Tickets/views/TicketCheck';
import { SimpleSeatView } from './pages/Tickets/views/SimpleSeatView';
import { JsonPlanView } from './pages/Tickets/views/JsonPlanView';
import { Tickets } from './pages/Tickets/Tickets';
import Login from './pages/Login';
import Admin from './pages/Admin';
import { AuthControllerClient, CredentialsDto } from './clients/services';
import clientFactory from './services/client-factory';
import Welcome from './pages/Welcome/Welcome';
import Archive from './pages/Archive/Archive';
import { PageRoutes } from './page-routes';
import Links from './pages/Links';
import Other from './pages/Other';
import Playlists from './pages/Playlists';
import Videos from './pages/Videos';
import LegalNotices from './pages/LegalNotices';
import { SnackbarProvider } from 'notistack';
import { AuthStore } from './auth/auth-store';
import { AuthProvider } from './auth/auth-provider';
import { EnvironmentIndicator } from './components/EnvironmentIndicator';

Settings.defaultZone = 'Europe/Zurich';
Settings.defaultLocale = 'de-ch';

const authStore = new AuthStore();

function MySeat() {
    const { concertId, guid } = useParams();
    if (guid) {
        return <SimpleSeatView concertId={Number(concertId)} guid={guid} />;
    }
    return <></>;
}

function MyTicket() {
    const { concertId, guid } = useParams();
    if (guid) {
        return <Ticket concertId={Number(concertId)} guid={guid} confirmEmail />;
    }
    return <></>;
}

function TicketView() {
    const { concertId, guid } = useParams();
    if (guid) {
        return <Ticket concertId={Number(concertId)} guid={guid} />;
    }
    return <></>;
}

export const TicketCheckView = () => {
    const { concertId, guid } = useParams();
    if (guid) {
        return <TicketCheck orderId={Number(concertId)} guid={guid} />;
    }
    return <></>;
};

export const JsonPlanViewRouted = (props: { devMode?: boolean }) => {
    const { plan } = useParams();
    if (plan) {
        return <JsonPlanView planName={plan} devMode={props.devMode ?? false} />;
    }
    return <></>;
};

export const TicketsRouted = () => {
    const { concertId } = useParams();
    return <Tickets concertId={concertId ? parseInt(concertId) : 0} />;
};

export const TicketsEarlyAccessRouted = () => {
    const { concertId, guid } = useParams();
    return <Tickets concertId={concertId ? parseInt(concertId) : 0} earlyAccessGuid={guid} />;
};

export const PlanViewRouted = (props: { devMode?: boolean }) => {
    const { concertId, planId } = useParams();
    return (
        <PlanView
            concertId={concertId ? parseInt(concertId) : undefined}
            planId={planId ? parseInt(planId) : undefined}
            devMode={props.devMode ?? false}
        />
    );
};

export const ReservationViewRouted = (props: { verifyEmail?: boolean }) => {
    const { concertId, guid } = useParams();
    if (concertId && guid) {
        return <ReservationView concertId={parseInt(concertId)} guid={guid} verifyEmail={props.verifyEmail ?? false} />;
    }

    return <></>;
};

const authClient = clientFactory.createSecuredClient(AuthControllerClient);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ width: 300 }}>
            <EnvironmentIndicator />
            <AuthProvider
                authStore={authStore}
                login={async (credentials) => await authClient.login(new CredentialsDto(credentials))}
                getProfile={async () => {
                    return await authClient.getProfile();
                }}
            >
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route path={PageRoutes.Welcome} element={<Welcome />} />
                            <Route path={PageRoutes.Archive} element={<Archive />}>
                                <Route path=":year" element={<Archive />} />
                            </Route>
                            <Route path={PageRoutes.Tickets} element={<Concerts />} />
                            <Route path={PageRoutes.Links} element={<Links />} />
                            <Route path={PageRoutes.Playlists} element={<Playlists />} />
                            <Route path={PageRoutes.Videos} element={<Videos />} />
                            <Route path={PageRoutes.Other} element={<Other />} />
                            <Route path={PageRoutes.LegalNotices} element={<LegalNotices />} />
                            <Route path="reservation">
                                <Route path=":concertId" element={<TicketsRouted />} />
                                <Route path=":concertId/:guid" element={<TicketsEarlyAccessRouted />} />
                                <Route path="my/:concertId">
                                    <Route path=":guid" element={<ReservationViewRouted verifyEmail />} />
                                </Route>
                                <Route path="view/:concertId">
                                    <Route path=":guid" element={<ReservationViewRouted />} />
                                </Route>
                            </Route>
                        </Route>

                        <Route path={PageRoutes.Plan} element={<App />}>
                            <Route path=":plan" element={<JsonPlanViewRouted />} />
                            <Route path=":plan/dev" element={<JsonPlanViewRouted devMode />} />
                            <Route path="concert/:concertId" element={<PlanViewRouted />} />
                            <Route path="concert/:concertId/dev" element={<PlanViewRouted devMode />} />
                            <Route path="id/:planId" element={<PlanViewRouted />} />
                            <Route path="id/:planId/dev" element={<PlanViewRouted devMode />} />
                        </Route>

                        <Route path="my-seat/:concertId">
                            <Route path=":guid" element={<MySeat />} />
                        </Route>
                        <Route path="my-ticket/:concertId">
                            <Route path=":guid" element={<MyTicket />} />
                        </Route>
                        <Route path="ticket/:concertId">
                            <Route path=":guid" element={<TicketView />} />
                        </Route>
                        <Route path="ticket-check/:concertId">
                            <Route path=":guid" element={<TicketCheckView />} />
                        </Route>
                        <Route path={PageRoutes.Login} element={<Login />} />
                        <Route path={PageRoutes.Admin + '/*'} element={<Admin />} />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </SnackbarProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
