import React, { useState, useEffect } from 'react';
import { IPlanDto, SeatReservationDto, IConcertDto, PlanDto } from '../../../clients/services';
import { ISeatDisplayInfo, IBlockDisplayInfo } from '../services/plan-info';
import { PlanFactory } from '../services/plan-factory';
import { RestrictionChecker } from '../services/restriction-checker';
import { Plan } from '../plan';
import { MainContent } from '../../../components/MainContent';
import { RootDiv } from '../styles';

type PlanProps = {
    planName: string;
    devMode: boolean;
};

export const JsonPlanView: React.FC<PlanProps> = (props) => {
    const [state, setState] = useState<
        | {
              concert: IConcertDto | null;
              plan: IPlanDto;
              blocks: {
                  blocks: IBlockDisplayInfo[];
                  possibilities: ISeatDisplayInfo[][];
              };
              reservations: SeatReservationDto[];
          }
        | undefined
    >(undefined);

    const loadPlan = async () => {
        try {
            const response = await window.fetch('/plans/' + props.planName + '.json');
            const plan = new PlanDto();
            plan.init(await response.json());
            const newPlanFactory = new PlanFactory(new RestrictionChecker(plan.restrictions), [], plan);
            const blocks = newPlanFactory.getPlanForOwnReservations(1, []);
            setState({
                concert: null,
                blocks,
                plan: plan,
                reservations: [],
            });
        } catch (e) {
            alert(e);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            await loadPlan();
        };
        loadData();
    }, []);

    return (
        <MainContent>
            {
                <RootDiv>
                    {state && (
                        <>
                            <h1>Planansicht</h1>
                            <h2 style={{ marginTop: '20px' }}>{state.plan.name}</h2>

                            <hr />
                            <Plan
                                plan={state.plan}
                                blockInfos={state.blocks.blocks}
                                nrOfDesiredSeats={2}
                                ownReservations={[]}
                                reservations={state.reservations}
                                onClickSeat={() => {
                                    /* do nothing */
                                }}
                                devMode={props.devMode}
                            />
                        </>
                    )}
                    <hr />
                </RootDiv>
            }
        </MainContent>
    );
};
