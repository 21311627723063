import { useParams } from 'react-router-dom';
import { MainContent, MainNarrow } from '../../components';
import Navigation from './Navigation';
import DynamicArchive from './DynamicArchive';
import Archive2008 from './Archive2008';
import Archive2009 from './Archive2009';
import Archive2011 from './Archive2011';
import Archive2012 from './Archive2012';
import Archive2013 from './Archive2013';
import Archive2015 from './Archive2015';
import Archive2016 from './Archive2016';
import Archive2017 from './Archive2017';
import Archive2018 from './Archive2018';
import Archive2019 from './Archive2019';
import Archive2010 from './Archive2010';
import Archive2014 from './Archive2014';
import Archive2006 from './Archive2006';
import Archive2007 from './Archive2007';

export const Archive = () => {
    const params = useParams();

    const ArchiveForYear = ({ year }: { year: number }) => {
        switch (year) {
            case 2006:
                return <Archive2006 />;
            case 2007:
                return <Archive2007 />;
            case 2008:
                return <Archive2008 />;
            case 2009:
                return <Archive2009 />;
            case 2010:
                return <Archive2010 />;
            case 2011:
                return <Archive2011 />;
            case 2012:
                return <Archive2012 />;
            case 2013:
                return <Archive2013 />;
            case 2014:
                return <Archive2014 />;
            case 2015:
                return <Archive2015 />;
            case 2016:
                return <Archive2016 />;
            case 2017:
                return <Archive2017 />;
            case 2018:
                return <Archive2018 />;
            case 2019:
                return <Archive2019 />;
            default:
                return <DynamicArchive year={Number(params.year)}></DynamicArchive>;
        }
    };

    return (
        <MainContent>
            <MainNarrow>
                {!params.year ? (
                    <>
                        <h1>BISHERIGE KONZERTE</h1>
                        <p>
                            In den vergangenen Jahren durfte ich meinem Publikum eine sorgf&auml;ltig auserlesene Serie
                            von hochkar&auml;tigen K&uuml;nstler/innen vorstellen. Einige dieser Top-Acts standen
                            bereits mehrere Male auf der B&uuml;hne in Laufen. Dies ist nicht zuletzt auch Ihnen zu
                            verdanken, liebe Konzertbesucher/innen. Dank Ihrer tollen Unterst&uuml;tzung und immer
                            wiederkehrenden Konzertbesuche, w&auml;ren solche Anl&auml;sse gar nicht realisierbar.
                            Vielen Dank!
                        </p>
                        <p>
                            {' '}
                            <br />
                            Sehen Sie folgend einen R&uuml;ckblick auf die musikalischen Leckerbissen, sehr
                            unterhaltsamen und top besetzten Konzerte der vergangenen Jahre.
                        </p>
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src="/images/rechte_spalte_text_02_kuenstler.png"
                                alt="Artists"
                                style={{ marginTop: 40 }}
                            ></img>
                        </div>
                    </>
                ) : (
                    <>
                        <h1>KONZERT&Uuml;BERSICHT {params.year}</h1>
                        {<ArchiveForYear year={Number(params.year)} />}
                    </>
                )}
            </MainNarrow>
            <Navigation />
        </MainContent>
    );
};

export default Archive;
