export const Archive2008 = () => {
    return (
        <>
            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2008_pr_01.jpg"
                            alt="PAULINE REESE"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Pauline Reese, Gray Hawn
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>PAULINE REESE</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Mittwoch, 18. Juni 2008
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.paulinereese.com" target="_blank" rel="noreferrer">
                                www.paulinereese.com
                            </a>
                        </p>
                    </div>
                    <h3>Pauline Reese Named Entertainer of the Year 2010 at Texas Music Awards</h3>
                    <p>&nbsp;</p>
                    <blockquote>
                        <p>
                            The first time I met this lady I was amazed by her talent and gracious personality. She has
                            self-confidence, but isn&rsquo;t self-absorbed and has an openheartedness that shines
                            through in her music. When my family met her, they admired her talent, style and good nature
                            right away!{' '}
                            <span className="color3 quelle">
                                <br />
                                &ndash; Michael Martin Murphey
                            </span>
                        </p>
                    </blockquote>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2009_mb_01.jpg"
                            alt="MIKE BLAKELY"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Annie Blakely
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>MIKE BLAKELY Y LOS YAHOOS</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Samstag, 14. Juni 2008
                                <br />
                                <span className="color1">KONZERTORT:</span> Little Nashville, Liesberg
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.mikeblakely.com" target="_blank" rel="noreferrer">
                                www.mikeblakely.com
                            </a>
                        </p>
                    </div>
                    <h3>Performing everywhere from Texas to Switzerland</h3>
                    <p>
                        As a performing singer/songwriter, Mike &ndash; often accompanied by band members &ndash; has
                        enjoyed his greatest successes in Texas and Switzerland, but his talents have taken him to
                        numerous other places, as well. He&apos;s performed coast-to-coast and border-to-border in the
                        U.S., and has entertained audiences in Australia, Austria, Holland, Germany, Italy, and Mexico.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        In Marble Falls, Texas, Mike met <strong>Larry Nye</strong>, renowned guitar player and owner of
                        Lazy L Recording Studio in Kingsland, Texas; and bassist <strong>Donnie Price</strong>, of
                        Buchanan Dam, Texas. The three musicians formed the band that would come to be known as
                        <strong>Mike Blakely Y Los Yahoos</strong> and began performing everywhere from Texas to
                        Switzerland. Other configurations built around this core band have included Mike Blakely &amp;
                        The Whiskey Traders, and The Swing Riders, featuring Doc Blakely and Mike Blakely.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>

            <hr />

            <div className="konzert">
                <div className="image_wrapper">
                    <p>
                        <img
                            className="image_border"
                            style={{ width: 166 }}
                            src="/images/konzerte/konzert_2008_dr_01.jpg"
                            alt="DON RIGSBY"
                        />
                    </p>
                    <p className="legendentext">
                        Foto&copy;:
                        <br />
                        Don Rigsby
                    </p>
                </div>
                <div className="text_image_left">
                    <h2>DON RIGSBY</h2>
                    <div className="frame_1">
                        <p>
                            <b>
                                <span className="color1">DATUM:</span> Freitag, 2. Mai 2008
                            </b>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a href="http://www.donrigsby.com" target="_blank" rel="noreferrer">
                                www.donrigsby.com
                            </a>
                        </p>
                    </div>
                    <h3>Eines der bedeutendsten Aush&auml;ngeschilder der Bluegrass-Musik</h3>
                    <p>
                        Don Rigsby gilt als eines der bedeutendsten Aush&auml;ngeschilder der Bluegrass-Musik, die sehr
                        stark auch von schottischen und irischen Einfl&uuml;ssen gepr&auml;gt ist. Don Rigsby, 1968
                        geboren, wuchs in einer Familie auf, in der das Musikmachen zur Tradition und zum Alltag
                        geh&ouml;rte. Don singt mit einer wunderbaren, ausdrucksstarken Tenorstimme, spielt Gitarre,
                        Mandoline, Fiddle, und Dulcimer und wurde musikalisch von Ralph Stanley und Keith Whitley
                        beeinflusst. Rigsby sammelte Erfahrungen in diversen namhaften Bands der Bluegrass-Szene. Seit
                        Ende der 90er-Jahre spielte er zahlreiche Alben mit Top Musikern ein und erhielt 2 IBMA-Awards.
                        2001 wurde Rigsby zum Direktor des Morehead State University&apos;s Kentucky Center for
                        Traditional Music.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Inzwischen hat Don Rigsby seine eigene Band &quot;<strong>Midnight Call</strong>&quot;, mit der
                        er 2006 das fantastische Debut-Album &quot;Hillbilly Heartache&quot; abgeliefert hat. Die
                        Besetzung der Gruppe neben Rigsby selbst: <strong>Jesse Wells</strong>, Fiddle;
                        <strong>Clawhammer</strong>, Banjo; <strong>Robert Maynard</strong>, Bass;{' '}
                        <strong>Dale Vanderpool</strong>, Banjo und <strong>Clyde Marshall</strong>, Gitarre. Die
                        Pr&auml;zision, die Musikalit&auml;t und die Virtuosit&auml;t von Don Rigsby und seiner Band
                        sind herausragend und ein Genuss f&uuml;r alle Konzertbesucher.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Im Rahmen ihrer kurzen Europa-Tournee treten Don Rigsby &amp; Midnight Call zur Europa Premiere
                        und ihrem einzigen Konzert in der Schweiz am Freitag, 2. Mai, um 20 Uhr, im Kulturzentrum
                        &quot;Alts Schlachthuus&quot; in Laufen auf.
                    </p>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </>
    );
};

export default Archive2008;
