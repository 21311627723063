export const correctPic = (pic: string) => {
    if (pic.startsWith('http')) {
        return pic;
    }

    if (pic.startsWith('.')) {
        return pic;
    }

    if (!pic.startsWith('/')) {
        return '/' + pic;
    }

    return pic;
};
