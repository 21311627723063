import React, { useState } from 'react';
import { ContactTracingForm } from '../forms/ContactTracingForm';
import {
    ContactTracingInfoDto,
    IContactTracingDataDto,
    ContactTracingDataDto,
    IContactTracingInfoDto,
} from '../../../clients/services';
import { AddressModel } from '../models/address.model';
import { LinearProgress } from '@mui/material';
import { actionContainerStyle } from '../styles';

interface Props {
    onContinue: (tracingData: IContactTracingDataDto) => Promise<void>;
    onBack: (tracingData: IContactTracingDataDto) => void;
    address: AddressModel;
    tracingData: IContactTracingDataDto;
    numberOfContacts: number;
    namesOnly?: boolean;
}

export const EnterContractTracingData: React.FC<Props> = (props) => {
    const createData = () => {
        if (props.tracingData?.contacts?.length !== props.numberOfContacts) {
            const newTracingData =
                !props.tracingData?.contacts || props.tracingData?.contacts.length === 0
                    ? new ContactTracingDataDto({
                          contacts: [
                              new ContactTracingInfoDto({
                                  firstName: props.address.firstName,
                                  lastName: props.address.lastName,
                                  street: props.address.street,
                                  zip: props.address.zip,
                                  city: props.address.city,
                                  country: props.address.country,
                                  tel: props.address.tel,
                              }),
                          ],
                      })
                    : props.tracingData;
            for (let i = newTracingData.contacts.length; i < props.numberOfContacts; i++) {
                if (props.namesOnly) {
                    newTracingData.contacts.push(
                        new ContactTracingInfoDto({
                            firstName: '',
                            lastName: '',
                        } as IContactTracingInfoDto),
                    );
                } else {
                    newTracingData.contacts.push(
                        new ContactTracingInfoDto({
                            firstName: '',
                            lastName: '',
                            street: '',
                            zip: '',
                            city: '',
                            country: 'Schweiz',
                            tel: '',
                        }),
                    );
                }
            }
            return newTracingData;
        }
        return props.tracingData;
    };
    const [tracingData, setTracingData] = useState<IContactTracingDataDto>(createData());
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onContinue = async (newTracingData: IContactTracingDataDto) => {
        setIsSubmitting(true);
        setTracingData(newTracingData);
        await props.onContinue(newTracingData);
        setIsSubmitting(false);
    };

    const onBack = (tracingDataToSave: IContactTracingDataDto) => {
        setTracingData(tracingDataToSave);
        props.onBack(tracingDataToSave);
    };

    return (
        <div style={actionContainerStyle}>
            {isSubmitting && <LinearProgress></LinearProgress>}
            <ContactTracingForm
                onContinue={onContinue}
                back={onBack}
                tracingData={tracingData}
                disabled={isSubmitting}
                namesOnly={props.namesOnly}
            ></ContactTracingForm>
        </div>
    );
};
